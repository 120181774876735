import { Flex, Progress, Text } from "@chakra-ui/react";
import IconBox from "components/Icons/IconBox";
import { numberWithCommas } from "helpers";
import React from "react";

const ChartStatistics = ({ title, value, amount, max, icon }) => {
  return (
    <Flex direction='column' flex="1 0 0" title={`${numberWithCommas(amount)} of ${numberWithCommas(max)}`}>
      <Flex alignItems='center'>
        <IconBox borderRadius="6px" h={"24px"} w={"24px"} bg={'#D8BFC4'} mr="4px">
          {icon}
        </IconBox>
        <Text fontSize='10px' color='rgba(61, 37, 65, 0.6)' fontWeight='500' className="two-line-ellipsis">
          {title}
        </Text>
      </Flex>
      <Text fontSize='14px' color={'#3D2541'} fontWeight='600' mt="8px" mb="7px">
        {value}
      </Text>
      <Progress
        colorScheme="red"
        borderRadius='4px'
        h='4px'
        w="80px"
        value={(amount / max) * 100}
      />
    </Flex>
  );
};

export default ChartStatistics;
