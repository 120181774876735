import { createSlice } from "@reduxjs/toolkit";

const switchedUser = JSON.parse(localStorage.getItem("switched_user"));
const initialState = {
  email: null,
  id: null,
  originalId: null,
  avatar: null,
  name: "",
  roleId: "",
  canEditOrder: false,
  ordersIsUpdating: false,
  customersIsUpdating: false,
  paymentsIsUpdating: false,
  shipmentsIsUpdating: false,
  authorized: false,
  ...switchedUser,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action) {
      state.email = action.payload.email;
      state.id = action.payload.id;
      state.originalId = action.payload.originalId;
      state.avatar = action.payload.avatar;
      state.name = action.payload.name;
      state.roleId = action.payload.roleId;
      state.canEditOrder = action.payload.canEditOrder;
    },
    removeUser(state) {
      state.email = null;
      state.id = null;
      state.originalId = null;
      state.avatar = null;
      state.name = "";
      state.roleId = "";
      state.canEditOrder = false;
    },
    setUserLoggined(state) {
      state.authorized = true;
    },
    setOrdersUpdating(state, action) {
      state.ordersIsUpdating = action.payload;
    },
    setCustomersUpdating(state, action) {
      state.customersIsUpdating = action.payload;
    },
    setPaymentsUpdating(state, action) {
      state.paymentsIsUpdating = action.payload;
    },
    setShipmentsUpdating(state, action) {
      state.shipmentsIsUpdating = action.payload;
    },
  },
});

export const {
  setUser,
  removeUser,
  setUserLoggined,
  setOrdersUpdating,
  setCustomersUpdating,
  setPaymentsUpdating,
  setShipmentsUpdating,
} = userSlice.actions;

export default userSlice.reducer;
