import { z } from "zod";
import validator from "validator";

function emptyStringToUndefined(value) {
  return value?.trim() === "" || value === null ? undefined : value;
}

function checkPhoneNumber(value) {
  return /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/.test(
      value
    )
}

const ProductSchema = z.object({
  id: z.coerce.number(),
  price: z.coerce.number(),
  productCode: z.string(),
  productName: z.string(),
});

const ContactInformationSchema = z.object({
  companyName: z.string(),
  fullName: z.string(),
  phone: z
    .string()
    .refine(
      (phone) => (phone ? checkPhoneNumber(phone) : true),
      "Invalid Phone Format"
    ),
  mobilePhone: z
    .string()
    .optional()
    .transform(emptyStringToUndefined)
    .refine(
      (mobilePhone) => (mobilePhone ? checkPhoneNumber(mobilePhone) : true),
      "Invalid Mobile Phone Format"
    ),
  email: z
    .string()
    .optional()
    .transform(emptyStringToUndefined)
    .refine(
      (email) => (email ? validator.isEmail(email) : true),
      "Invalid Email Format"
    ),
});

const PostalAddressSchema = z.object({
  addressLine1: z.string(),
  addressLine2: z
    .string()
    .optional()
    .nullish()
    .transform(emptyStringToUndefined),
  addressLine3: z
    .string()
    .optional()
    .nullish()
    .transform(emptyStringToUndefined),
  cityName: z.string(),
  countryCode: z.string().length(2),
  postalCode: z.string(),
  countryName: z
    .string()
    .optional()
    .nullish()
    .transform(emptyStringToUndefined),
  provinceName: z
    .string()
    .optional()
    .nullish()
    .transform(emptyStringToUndefined),
  provinceCode: z
    .string()
    .optional()
    .nullish()
    .transform(emptyStringToUndefined),
  countyName: z.string().optional().nullish().transform(emptyStringToUndefined),
});

const ReceiverDetailsSchema = z.object({
  contactInformation: ContactInformationSchema,
  postalAddress: PostalAddressSchema,
  typeCode: z.string(),
});

const ContentSchema = z.object({
  description: z.string(),
  incoterm: z.string(),
});

const ChosenPackagesSchema = z.object({
  id: z.coerce.number().int(),
  value: z.string(),
});

const LineItemsSchema = z.object({
  number: z.coerce.number().int(),
  description: z.string(),
  price: z.coerce.number(),
  quantity: z.object({
    value: z.coerce.number(),
    unitOfMeasurement: z.string(),
  }),
  commodityCodes: z.array(
    z.object({
      typeCode: z.enum(["outbound", "inbound"]),
      value: z.string(),
    })
  ),
  customerReferences: z.array(
    z.object({
      typeCode: z.string(),
      value: z.string(),
    })
  ),
  manufacturerCountry: z.string(),
  weight: z.object({
    grossValue: z.coerce.number(),
    netValue: z.coerce.number(),
  }),
});

const ShipmentPrepareSchema = z.object({
  product: ProductSchema,
  receiverDetails: ReceiverDetailsSchema,
  content: ContentSchema,
  chosenPackages: z.array(ChosenPackagesSchema),
  declaredValue: z.coerce.number(),
  declaredValueCurrency: z.string().length(3),
  destinationType: z.string(),
  lineItems: z.array(LineItemsSchema).optional(),
  packagesToChooseFrom: z.array(ChosenPackagesSchema),
  products: z.array(ProductSchema),
});

export const ShipmentFormSchema = ShipmentPrepareSchema.extend({
  plannedShippingTime: z.string().optional(),
  orderId: z.string().optional(),
  invoice: z
    .object({
      date: z.string(),
      number: z.string(),
    })
    .optional(),
  customerReferences: z
    .array(
      z.object({
        value: z.string(),
      })
    )
    .min(1)
    .max(3)
    .default([{ value: "" }]),
});

export const ShipmentSubmitFormSchema = ShipmentFormSchema.extend({
  plannedShippingDate: z.string().optional(),
  customerReferences: z
    .array(
      z.object({
        value: z.string().min(1),
      })
    )
    .min(1)
    .max(3)
    .default([{ value: "" }]),
});

export const ShipmentSubmitSchema = ShipmentSubmitFormSchema.omit({
  products: true,
  packagesToChooseFrom: true,
});
