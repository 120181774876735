import { memo, Fragment } from "react";
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Textarea,
} from "@chakra-ui/react";

const ProductList = memo(
  ({ lineItems, errors, register, removeProductFromList, calcTotalPrice }) => {
    if (!lineItems) {
      return null;
    }
    return lineItems.map((lineItem, lineItemIndex) => (
      <Flex
        key={lineItemIndex + lineItem.description}
        flexDirection="column"
        gap={3}
        mt={3}
      >
        <Flex flexDirection="row" justifyContent="space-between">
          <h2 className="shipment-subtitle" style={{ fontSize: "16px" }}>
            Product №{lineItemIndex + 1}
          </h2>
          <button
            className="custom-link"
            onClick={removeProductFromList(lineItemIndex)}
          >
            Delete product
          </button>
        </Flex>
        <Grid templateColumns="repeat(2, 1fr)" gap={3}>
          <GridItem rowSpan={2}>
            <FormControl
              isInvalid={errors?.lineItems?.[lineItemIndex]?.description}
            >
              <FormLabel fontSize={"15px"}>Description</FormLabel>
              <Textarea
                {...register(`lineItems[${lineItemIndex}].description`)}
                borderColor={"#3D2541"}
                rows={6}
                resize="none"
                placeholder="Description"
              />
              {errors?.lineItems?.[lineItemIndex]?.description && (
                <FormErrorMessage>
                  {errors?.lineItems?.[lineItemIndex]?.description?.message}
                </FormErrorMessage>
              )}
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={errors?.lineItems?.[lineItemIndex]?.price}>
              <FormLabel fontSize={"15px"}>Price</FormLabel>
              <Input
                {...register(`lineItems[${lineItemIndex}].price`, {
                  onChange: calcTotalPrice,
                })}
                type="number"
                borderColor={"#3D2541"}
                height="44px"
                placeholder="Price"
              />
              {errors?.lineItems?.[lineItemIndex]?.price && (
                <FormErrorMessage>
                  {errors?.lineItems?.[lineItemIndex]?.price?.message}
                </FormErrorMessage>
              )}
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl
              isInvalid={errors?.lineItems?.[lineItemIndex]?.quantity?.value}
            >
              <FormLabel fontSize={"15px"}>
                Quantity ({lineItem?.quantity?.unitOfMeasurement})
              </FormLabel>
              <Input
                {...register(`lineItems[${lineItemIndex}].quantity.value`)}
                type="text"
                borderColor={"#3D2541"}
                height="44px"
                placeholder="Quantity"
              />
              {errors?.lineItems?.[lineItemIndex]?.quantity?.value && (
                <FormErrorMessage>
                  {errors?.lineItems?.[lineItemIndex]?.quantity?.value?.message}
                </FormErrorMessage>
              )}
            </FormControl>
          </GridItem>
          {lineItem?.commodityCodes?.map((_, commodityIndex) => (
            <Fragment key={commodityIndex}>
              <GridItem>
                <FormControl
                  isInvalid={
                    errors?.lineItems?.[lineItemIndex]?.commodityCodes?.[
                      commodityIndex
                    ]?.typeCode
                  }
                >
                  <FormLabel fontSize={"15px"}>Commodity TypeCode</FormLabel>
                  <Input
                    {...register(
                      `lineItems[${lineItemIndex}].commodityCodes[${commodityIndex}].typeCode`
                    )}
                    type="text"
                    disabled
                    borderColor={"#3D2541"}
                    height="44px"
                    placeholder="Commodity TypeCode"
                  />
                  {errors?.lineItems?.[lineItemIndex]?.commodityCodes?.[
                    commodityIndex
                  ]?.typeCode && (
                    <FormErrorMessage>
                      {
                        errors?.lineItems?.[lineItemIndex]?.commodityCodes?.[
                          commodityIndex
                        ]?.typeCode?.message
                      }
                    </FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl
                  isInvalid={
                    errors?.lineItems?.[lineItemIndex]?.commodityCodes?.[
                      commodityIndex
                    ]?.value
                  }
                >
                  <FormLabel fontSize={"15px"}>Commodity Code</FormLabel>
                  <Input
                    {...register(
                      `lineItems[${lineItemIndex}].commodityCodes[${commodityIndex}].value`
                    )}
                    type="text"
                    borderColor={"#3D2541"}
                    height="44px"
                    placeholder="Commodity Code"
                  />
                  {errors?.lineItems?.[lineItemIndex]?.commodityCodes?.[
                    commodityIndex
                  ]?.value && (
                    <FormErrorMessage>
                      {
                        errors?.lineItems?.[lineItemIndex]?.commodityCodes?.[
                          commodityIndex
                        ]?.value?.message
                      }
                    </FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>
            </Fragment>
          ))}
          {lineItem?.customerReferences?.map((_, customerReferenceIndex) => (
            <Fragment key={customerReferenceIndex}>
              <GridItem>
                <FormControl
                  isInvalid={
                    errors?.lineItems?.[lineItemIndex]?.customerReferences?.[
                      customerReferenceIndex
                    ]?.typeCode
                  }
                >
                  <FormLabel fontSize={"15px"}>
                    Shipment References TypeCode
                  </FormLabel>
                  <Input
                    {...register(
                      `lineItems[${lineItemIndex}].customerReferences[${customerReferenceIndex}].typeCode`
                    )}
                    type="text"
                    disabled
                    borderColor={"#3D2541"}
                    height="44px"
                    placeholder="Shipment References TypeCode"
                  />
                  {errors?.lineItems?.[lineItemIndex]?.customerReferences?.[
                    customerReferenceIndex
                  ]?.typeCode && (
                    <FormErrorMessage>
                      {
                        errors?.lineItems?.[lineItemIndex]
                          ?.customerReferences?.[customerReferenceIndex]
                          ?.typeCode?.message
                      }
                    </FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl
                  isInvalid={
                    errors?.lineItems?.[lineItemIndex]?.customerReferences?.[
                      customerReferenceIndex
                    ]?.value
                  }
                >
                  <FormLabel fontSize={"15px"}>Shipment References</FormLabel>
                  <Input
                    {...register(
                      `lineItems[${lineItemIndex}].customerReferences[${customerReferenceIndex}].value`
                    )}
                    type="text"
                    borderColor={"#3D2541"}
                    height="44px"
                    placeholder="Shipment References"
                  />
                  {errors?.lineItems?.[lineItemIndex]?.customerReferences?.[
                    customerReferenceIndex
                  ]?.value && (
                    <FormErrorMessage>
                      {
                        errors?.lineItems?.[lineItemIndex]
                          ?.customerReferences?.[customerReferenceIndex]?.value
                          ?.message
                      }
                    </FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>
            </Fragment>
          ))}
          <GridItem>
            <FormControl
              isInvalid={errors?.lineItems?.[lineItemIndex]?.weight?.grossValue}
            >
              <FormLabel fontSize={"15px"}>Weight Gross</FormLabel>
              <Input
                {...register(`lineItems[${lineItemIndex}].weight.grossValue`)}
                type="text"
                borderColor={"#3D2541"}
                height="44px"
                placeholder="Weight Gross"
              />
              {errors?.lineItems?.[lineItemIndex]?.weight?.grossValue && (
                <FormErrorMessage>
                  {
                    errors?.lineItems?.[lineItemIndex]?.weight?.grossValue
                      ?.message
                  }
                </FormErrorMessage>
              )}
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl
              isInvalid={errors?.lineItems?.[lineItemIndex]?.weight?.netValue}
            >
              <FormLabel fontSize={"15px"}>Weight Net</FormLabel>
              <Input
                {...register(`lineItems[${lineItemIndex}].weight.netValue`)}
                type="text"
                borderColor={"#3D2541"}
                height="44px"
                placeholder="Weight Net"
              />
              {errors?.lineItems?.[lineItemIndex]?.weight?.netValue && (
                <FormErrorMessage>
                  {
                    errors?.lineItems?.[lineItemIndex]?.weight?.netValue
                      ?.message
                  }
                </FormErrorMessage>
              )}
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl
              isInvalid={
                errors?.lineItems?.[lineItemIndex]?.manufacturerCountry
              }
            >
              <FormLabel fontSize={"15px"}>Manufacturer Country</FormLabel>
              <Input
                {...register(`lineItems[${lineItemIndex}].manufacturerCountry`)}
                type="text"
                borderColor={"#3D2541"}
                height="44px"
                placeholder="Manufacturer Country"
              />
              {errors?.lineItems?.[lineItemIndex]?.manufacturerCountry && (
                <FormErrorMessage>
                  {
                    errors?.lineItems?.[lineItemIndex]?.manufacturerCountry
                      ?.message
                  }
                </FormErrorMessage>
              )}
            </FormControl>
          </GridItem>
        </Grid>
      </Flex>
    ));
  }
);

export default ProductList;
