import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import cn from "classnames";
import PropTypes from "prop-types";
import { Alert, AlertIcon, AlertTitle, Text, Link } from "@chakra-ui/react";

const Info = ({ isOrderError, user, order, shipmentData }) => {
  const { t } = useTranslation();

  const packageStatusClassNames = cn(
    ["paid_shopify", "paid_stripe", "delivered"].includes(order?.status)
      ? "order-view-package-status-ready"
      : "order-view-package-status-waiting"
  );

  if (isOrderError) {
    return (
      <Alert status="error">
        <AlertIcon />
        <AlertTitle>{t("portal_error_message")}</AlertTitle>
      </Alert>
    );
  }

  const customerName = useMemo(
    () =>
      (user?.firstName &&
        user?.lastName &&
        `${user?.firstName} ${user?.lastName}`) ||
      user?.name ||
      "-",
    [user]
  );
  const orderCreatedDate = useMemo(
    () => moment(order.created).format("DD/MM/YYYY"),
    [order]
  );
  const orderStatus = useMemo(() => {
    switch (order.status) {
      case "paid_shopify":
      case "paid_stripe":
        return "Paid";
      case "payment_needed":
        return "Payment needed";
      case "delivery_in_process":
        return "Delivery In-Process";
      case "confirmed":
        return "Confirmed";
      case "delivered":
        return "Delivered";
      case "delivery_needed":
        return "Delivery needed";
    }
  }, [order]);
  const shipmentStatus = useMemo(
    () => (shipmentData ? "Created" : "Not Created"),
    [shipmentData]
  );
  const shipmentStatusColor = useMemo(
    () => (shipmentData ? "#219653" : "#cf0227"),
    [shipmentData]
  );

  return (
    <>
      <Text fontSize={15} color="#3D2541" mb="20px">
        <Text as="b">{t("portal_orders_view_customer")}</Text>&nbsp;
        {customerName}
      </Text>
      <Text fontSize={15} color="#3D2541" mb="20px">
        <Text as="b">{t("portal_orders_view_date")}</Text>&nbsp;
        {orderCreatedDate}
      </Text>
      <Text fontSize={15} color="#3D2541" mb="20px">
        <Text as="b">{t("portal_orders_view_email")}</Text>&nbsp;
        {user?.email || "-"}
      </Text>
      <Text fontSize={15} color="#3D2541" mb="20px">
        <Text as="b">{t("portal_orders_view_status")}</Text>&nbsp;
        <Text as="span" className={packageStatusClassNames}>
          {orderStatus}
        </Text>
      </Text>
      <Text fontSize={15} color="#3D2541" mb="20px">
        <Text as="b">{t("portal_orders_dhl_status", "DHL Status: ")}</Text>
        &nbsp;
        <Text as="span" fontWeight="extrabold" color={shipmentStatusColor}>
          {shipmentStatus}
        </Text>
      </Text>
      {shipmentData && (
        <Text fontSize={15} color="#3D2541" mb="20px" display="flex">
          <Text as="b" display="flex" mr={8}>
            {t("portal_orders_prepared_documents", "Prepared Documents: ")}
          </Text>
          <Text as="span" flexDirection="row">
            {shipmentData?.documents.map((doc, id) => (
              <Link
                key={id}
                href={`${process.env.REACT_APP_CENTRAL_API_URL}/${doc}`}
                target="_blank"
                className="order-view-document-link"
                color="red.500"
                textDecor="underline"
                fontWeight="extrabold"
                mr={16}
              >
                {doc.split("/").pop()}
              </Link>
            ))}
          </Text>
        </Text>
      )}
    </>
  );
};

Info.propTypes = {
  isOrderError: PropTypes.bool,
  user: PropTypes.object,
  order: PropTypes.object,
  shipmentData: PropTypes.object,
};

export default memo(Info);
