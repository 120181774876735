import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { store } from "store";
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import StaticLayout from "layouts/Static.js";
import { ChakraProvider } from "@chakra-ui/react";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { doc, getDoc } from "firebase/firestore";
import { db } from "./firebase";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {},
    lng: "en",
    fallbackLng: "en",
    detection: {
      order: ['localStorage'],
      caches: ['localStorage']
    },
    interpolation: {
      escapeValue: false
    }
  });

const App = () => {
  const portalEnRef = doc(db, 'Meta', 'portal_txt')
  const portalRuRef = doc(db, 'Meta', 'portal_txt_ru')

  getDoc(portalEnRef).then(doc => {
    if(doc.exists()) {
      i18n.addResourceBundle('en', 'translation', doc.data());
    }
  })
  getDoc(portalRuRef).then(doc => {
    if(doc.exists()) {
      i18n.addResourceBundle('ru', 'translation', doc.data());
    }
  })

  return (
    <BrowserRouter>
        <Switch>
            <Provider store={store}>
                <ChakraProvider>
                    <Route path={`/auth`} component={AuthLayout} />
                    <Route path={`/admin`} component={AdminLayout} />
                    <Route exact path={`/`} component={StaticLayout} />
                    <Route path={`/terms`} component={StaticLayout} />
                </ChakraProvider>
            </Provider>
        </Switch>
    </BrowserRouter>
  )
}

export default App