import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
};

const paymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    setPayments(state, action) {
      state.list = action.payload;
    },
    updatePayment(state, action) {
      state.list = state.list.map((payment) => {
        if (payment.id === action.payload.id) {
          return {
            ...payment,
            ...action.payload,
          };
        }
        return payment;
      });
    },
  },
});

export const { setPayments, updatePayment } = paymentsSlice.actions;

export default paymentsSlice.reducer;
