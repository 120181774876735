import { CloseIcon as ChCloseIcon, EditIcon as ChEditIcon, createIcon } from "@chakra-ui/icons"

export const EditIconSimple = ChEditIcon

export const CloseIconSimple = ChCloseIcon

export const AdobexdLogo = createIcon({
  displayName: "AdobexdLogo",
  viewBox: "0 0 24 24",
  path: (
    <g clipPath="url(#clip0)">
      <path
        d="M4.042 0h12.916A3.539 3.539 0 0120.5 3.542v12.416a3.539 3.539 0 01-3.542 3.542H4.042A3.539 3.539 0 01.5 15.958V3.542A3.539 3.539 0 014.042 0z"
        fill="#470137"
      />
      <path
        d="M11.017 5.124l-2.5 4.125 2.666 4.375a.143.143 0 01.017.1c-.008.034-.042.009-.092.017H9.2c-.133 0-.225-.008-.283-.092-.175-.35-.359-.691-.534-1.041a33.608 33.608 0 00-.566-1.05c-.2-.359-.4-.717-.6-1.084H7.2c-.175.359-.367.717-.558 1.075-.192.359-.384.717-.567 1.067-.192.35-.383.708-.575 1.05-.033.083-.1.092-.192.092H3.475c-.033 0-.058.016-.058-.025a.116.116 0 01.016-.092l2.592-4.25L3.5 5.116c-.025-.033-.033-.067-.017-.083.017-.025.05-.034.084-.034h1.891c.042 0 .084.009.117.017a.236.236 0 01.083.075c.159.358.342.717.534 1.075.2.358.391.708.6 1.058.2.35.383.7.558 1.059h.017c.175-.367.358-.725.541-1.075.184-.35.375-.7.567-1.05.192-.35.375-.709.558-1.05.009-.034.025-.067.05-.084a.159.159 0 01.109-.016h1.758a.077.077 0 01.091.058c.009.008-.008.042-.024.058zM14.866 13.917a4.144 4.144 0 01-1.791-.375 2.906 2.906 0 01-1.259-1.134c-.308-.508-.458-1.141-.458-1.9a3.423 3.423 0 011.783-3.05c.584-.325 1.284-.483 2.109-.483.041 0 .1 0 .175.008.075.009.158.009.258.017V4.367c0-.059.025-.092.083-.092h1.692c.042-.008.075.025.083.058v7.95c0 .15.009.317.017.5.017.175.025.342.033.484 0 .058-.025.108-.083.133a6.365 6.365 0 01-1.358.4 7.35 7.35 0 01-1.284.117zm.817-1.667V8.583c-.075-.016-.15-.033-.225-.041-.092-.009-.183-.017-.275-.017-.325 0-.65.067-.942.217-.283.141-.525.35-.708.616-.183.267-.275.625-.275 1.059-.008.291.042.583.142.858.083.225.208.425.375.592.158.15.35.266.566.333.225.075.459.108.692.108.125 0 .242-.008.35-.016a.73.73 0 00.3-.042z"
        fill="#FF61F6"
      />
    </g>
  ),
});

export const AtlassianLogo = createIcon({
  displayName: "AtlassianLogo",
  viewBox: "0 0 24 24",
  path: (
    <g>
      <path
        d="M6.427 9.239a.57.57 0 00-.798-.108.591.591 0 00-.167.209l-4.9 9.803a.586.586 0 00.524.847h6.827a.562.562 0 00.523-.324c1.474-3.043.58-7.67-2.01-10.427z"
        fill="#2684FF"
      />
      <path
        d="M10.028.318a12.932 12.932 0 00-.755 12.765l3.292 6.583a.586.586 0 00.523.324h6.826a.583.583 0 00.586-.585c0-.091-.02-.18-.06-.262L11.024.315a.552.552 0 00-.997 0v.003z"
        fill="#2684FF"
      />
    </g>
  ),
});

export const CartIcon = createIcon({
  displayName: "CartIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M7.984 19.937a1.406 1.406 0 100-2.812 1.406 1.406 0 000 2.812zM17.828 19.937a1.406 1.406 0 100-2.812 1.406 1.406 0 000 2.812zM20.324 5.558a1.051 1.051 0 00-.815-.386H6.134l-.27-1.528a.703.703 0 00-.692-.581H2.359a.703.703 0 000 1.406h2.223L6.59 15.841a.703.703 0 00.692.581h11.25a.703.703 0 100-1.406H7.871l-.248-1.406h10.62a1.057 1.057 0 001.035-.848l1.266-6.328a1.055 1.055 0 00-.22-.876z"
    />
  ),
});

export const ClockIcon = createIcon({
  displayName: "ClockIcon",
  viewBox: "0 0 24 24",
  path: (
    <g>
      <g>
        <rect fill="none" height="24" width="24" />
      </g>
      <g>
        <g>
          <g>
            <path
              fill="currentColor"
              d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16.2,16.2L11,13V7h1.5v5.2l4.5,2.7L16.2,16.2z"
            />
          </g>
        </g>
      </g>
    </g>
  ),
});
export const CreativeTimLogo = createIcon({
  displayName: "CreativeTimLogo",
  viewBox: "0 0 100 100",
  path: (
    <g
      xmlns="http://www.w3.org/2000/svg"
      width="50px"
      height="50px"
      version="1.1"
    >
      <g
        id="logo-creative-tim-black"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="icon"
          transform="translate(15.000000, 19.000000)"
          fill="currentColor"
          fillRule="nonzero"
        >
          <path
            d="M66.4346744,1.49018376e-13 L3.56968951,1.49018376e-13 C1.59820443,1.49018376e-13 -2.98036753e-13,1.59820443 -2.98036753e-13,3.56968951 L-2.98036753e-13,33.5035241 C-2.98036753e-13,35.4750091 1.59820443,37.0732136 3.56968951,37.0732136 L16.4677598,37.0732136 L16.4677598,66.4346744 C16.4666001,67.3810129 16.8417189,68.288989 17.5104719,68.9585615 C18.1792249,69.628134 19.0867408,70.0043639 20.03308,70.0043639 L49.9669146,70.0043639 C51.9383997,70.0043639 53.5366041,68.4061595 53.5366041,66.4346744 L53.5366041,37.0863214 L66.4346744,37.0863214 C67.3810136,37.0863221 68.2885296,36.7100915 68.9572826,36.0405189 C69.6260355,35.3709464 70.0011544,34.4629704 70,33.5166319 L70,3.56968951 C70.0011544,2.62335099 69.6260355,1.71537495 68.9572826,1.04580242 C68.2885296,0.37622988 67.3810136,1.49018376e-13 66.4346744,1.49018376e-13 Z M4.13769396,4.14206323 L32.9311503,4.14206323 L32.9311503,32.9355196 L4.13769396,32.9355196 L4.13769396,4.14206323 Z M49.3989102,65.86667 L20.6054538,65.86667 L20.6054538,37.0863214 L49.3989102,37.0863214 L49.3989102,65.86667 Z M65.8623007,32.9355196 L37.0688443,32.9355196 L37.0688443,4.14206323 L65.8623007,4.14206323 L65.8623007,32.9355196 Z"
            id="Shape"
          />
          <path
            d="M23.6988934,23.3056595 C24.5142794,24.0708897 25.7898859,24.0506562 26.5805917,23.2599503 C27.3712976,22.4692445 27.3915311,21.193638 26.6263009,20.378252 L20.015603,13.767554 C19.2050095,12.9645312 17.8987889,12.9645312 17.0881954,13.767554 L10.4600204,20.378252 C9.90907005,20.8953125 9.68342943,21.6713831 9.87125954,22.4032422 C10.0590897,23.1351013 10.6305787,23.7065903 11.3624377,23.8944204 C12.0942968,24.0822505 12.8703675,23.8566099 13.387428,23.3056595 L18.5344222,18.1586654 L23.6988934,23.3056595 Z"
            id="Path"
          />
          <path
            d="M46.3185783,23.3056595 L51.4655725,18.1586654 L56.6125667,23.3056595 C57.4279526,24.0708897 58.7035592,24.0506562 59.494265,23.2599503 C60.2849709,22.4692445 60.3052044,21.193638 59.5399742,20.378252 L52.9292763,13.767554 C52.1186828,12.9645312 50.8124622,12.9645312 50.0018687,13.767554 L43.3911708,20.378252 C42.6259406,21.193638 42.6461741,22.4692445 43.43688,23.2599503 C44.2275858,24.0506562 45.5031924,24.0708897 46.3185783,23.3056595 L46.3185783,23.3056595 Z"
            id="Path"
          />
          <path
            d="M29.8508185,46.6987044 C29.0342976,45.9346441 27.7586832,45.9567597 26.9691385,46.7486649 C26.1795937,47.54057 26.1612859,48.8162446 26.9277802,49.6304812 L33.5384782,56.2368099 C33.9262423,56.6258256 34.452915,56.8444779 35.002182,56.8444779 C35.5514489,56.8444779 36.0781217,56.6258256 36.4658857,56.2368099 L43.0765837,49.6304812 C43.6002952,49.1075502 43.8052442,48.3449453 43.6142284,47.6299323 C43.4232126,46.9149194 42.8652519,46.3561259 42.1505246,46.1640439 C41.4357974,45.971962 40.6728876,46.1757734 40.1491761,46.6987044 L35.002182,51.8500678 L29.8508185,46.6987044 Z"
            id="Path"
          />
        </g>
      </g>
    </g>
  ),

  //   <chakra.svg
  //   height="50px"
  //   width="50px"
  //   viewBox="0 0 100 100"
  //   fill="none"
  //   xmlns="http://www.w3.org/2000/svg"
  //   color={fill}
  //   {...props}
  // >
  // </chakra.svg>
});

export const CreditIcon = createIcon({
  displayName: "CreditIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M1.406 17.023a2.461 2.461 0 002.461 2.46h14.766a2.461 2.461 0 002.46-2.46v-6.768H1.407v6.768zm2.9-3.34a1.318 1.318 0 011.319-1.318h2.11a1.318 1.318 0 011.318 1.318v.879a1.318 1.318 0 01-1.319 1.318H5.625a1.318 1.318 0 01-1.318-1.318v-.879zM18.633 4.014H3.867a2.46 2.46 0 00-2.46 2.461v1.143h19.687V6.475a2.46 2.46 0 00-2.461-2.46z"
    />
  ),
});

export const DashboardLogo = createIcon({
  displayName: "DashboardLogo",
  viewBox: "0 0 1000 257",
  path: (
    <g width="998" height="257" viewBox="0 0 998 257" fill="none">
      <g clipPath="url(#clip0)">
        <path
          d="M388.5 115.302C406.112 115.302 413.966 126.726 416.584 136.96L441.336 127.916C436.576 109.352 419.44 89.836 388.262 89.836C354.942 89.836 329 115.54 329 151.24C329 186.464 354.942 212.882 388.976 212.882C419.44 212.882 436.814 193.128 442.288 175.04L418.012 166.234C415.394 175.04 407.064 187.654 388.976 187.654C371.602 187.654 356.608 174.564 356.608 151.24C356.608 127.916 371.602 115.302 388.5 115.302Z"
          fill="currentColor"
        />
        <path
          d="M484.894 141.244C485.37 126.488 493.7 115.064 508.932 115.064C526.306 115.064 532.732 126.488 532.732 140.768V209.312H560.34V136.008C560.34 110.542 546.536 90.074 517.976 90.074C505.838 90.074 492.748 94.358 484.894 104.592V37H457.286V209.312H484.894V141.244Z"
          fill="currentColor"
        />
        <path
          d="M577.29 177.896C577.29 196.222 592.284 212.882 616.56 212.882C635.362 212.882 646.786 203.362 652.26 194.556C652.26 203.838 653.212 208.598 653.45 209.312H679.154C678.916 208.122 677.726 201.22 677.726 190.748V133.152C677.726 110.066 664.16 89.836 628.46 89.836C599.9 89.836 581.812 107.686 579.67 127.678L604.898 133.39C606.088 121.728 614.18 112.446 628.698 112.446C643.93 112.446 650.594 120.3 650.594 130.058C650.594 134.104 648.69 137.436 642.026 138.388L612.276 142.91C592.522 145.766 577.29 157.19 577.29 177.896ZM621.796 191.224C610.848 191.224 604.898 184.084 604.898 176.23C604.898 166.71 611.8 161.95 620.368 160.522L650.594 156V161.236C650.594 183.846 637.266 191.224 621.796 191.224Z"
          fill="currentColor"
        />
        <path
          d="M810.108 93.406H773.456L728.95 140.292V37H701.58V209.312H728.95V176.944L743.23 161.95L777.264 209.312H811.06L762.508 142.434L810.108 93.406Z"
          fill="currentColor"
        />
        <path
          d="M889.349 92.692C888.159 92.454 885.303 91.978 881.971 91.978C866.739 91.978 853.887 99.356 848.413 111.97V93.406H821.519V209.312H849.127V154.096C849.127 132.438 858.885 120.062 880.305 120.062C883.161 120.062 886.255 120.3 889.349 120.776V92.692Z"
          fill="currentColor"
        />
        <path
          d="M895.968 177.896C895.968 196.222 910.962 212.882 935.238 212.882C954.04 212.882 965.464 203.362 970.938 194.556C970.938 203.838 971.89 208.598 972.128 209.312H997.832C997.594 208.122 996.404 201.22 996.404 190.748V133.152C996.404 110.066 982.838 89.836 947.138 89.836C918.578 89.836 900.49 107.686 898.348 127.678L923.576 133.39C924.766 121.728 932.858 112.446 947.376 112.446C962.608 112.446 969.272 120.3 969.272 130.058C969.272 134.104 967.368 137.436 960.704 138.388L930.954 142.91C911.2 145.766 895.968 157.19 895.968 177.896ZM940.474 191.224C929.526 191.224 923.576 184.084 923.576 176.23C923.576 166.71 930.478 161.95 939.046 160.522L969.272 156V161.236C969.272 183.846 955.944 191.224 940.474 191.224Z"
          fill="currentColor"
        />
        <rect width="257" height="257" rx="128.5" fill="#4FD1C5" />
        <path
          d="M69.5584 133.985L157.15 46.9959C158.787 45.3708 161.42 47.3484 160.315 49.3729L127.714 109.125C126.987 110.457 127.951 112.083 129.47 112.083H185.809C187.624 112.083 188.501 114.306 187.174 115.545L88.4456 207.687C86.6753 209.339 84.0405 207.011 85.4617 205.051L132.197 140.578C133.156 139.256 132.211 137.404 130.578 137.404H70.9677C69.1826 137.404 68.2917 135.243 69.5584 133.985Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="128.5"
          y1="0"
          x2="128.5"
          y2="257"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7BCBD4" />
          <stop offset="1" stopColor="#29C6B7" />
        </linearGradient>
        <clipPath id="clip0">
          <rect width="997.832" height="257" fill="white" />
        </clipPath>
      </defs>
    </g>
  ),
});

export const DashboardLogoWhite = createIcon({
  displayName: "DashboardLogo",
  viewBox: "0 0 163.5 42",
  path: (
    <g fill="none">
      <path
        d="M63.452 19.292c2.877 0 4.16 1.911 4.587 3.623l4.043-1.513c-.777-3.106-3.576-6.371-8.668-6.371-5.443 0-9.68 4.3-9.68 10.274 0 5.893 4.237 10.313 9.796 10.313 4.976 0 7.813-3.305 8.707-6.331l-3.965-1.473c-.427 1.473-1.788 3.583-4.742 3.583-2.837 0-5.286-2.19-5.286-6.092 0-3.903 2.449-6.013 5.208-6.013zM79.196 23.632c.078-2.469 1.438-4.38 3.926-4.38 2.838 0 3.887 1.911 3.887 4.3v11.47h4.51V22.755c0-4.26-2.255-7.685-6.92-7.685-1.982 0-4.12.716-5.403 2.429V6.19h-4.509v28.831h4.51V23.632zM94.287 29.765c0 3.066 2.449 5.853 6.414 5.853 3.071 0 4.936-1.592 5.83-3.066 0 1.553.156 2.35.195 2.47h4.198c-.039-.2-.233-1.355-.233-3.107v-9.637c0-3.862-2.216-7.247-8.047-7.247-4.664 0-7.619 2.986-7.968 6.332l4.12.955c.194-1.951 1.516-3.504 3.887-3.504 2.488 0 3.576 1.314 3.576 2.947 0 .677-.311 1.234-1.399 1.393l-4.859.757c-3.226.478-5.714 2.39-5.714 5.854zm7.269 2.23c-1.788 0-2.76-1.195-2.76-2.509 0-1.593 1.127-2.39 2.527-2.628l4.936-.757v.876c0 3.783-2.176 5.018-4.703 5.018zM132.312 15.628h-5.986l-7.269 7.845V6.191h-4.47v28.83h4.47v-5.416l2.332-2.508 5.559 7.924h5.52l-7.93-11.19 7.774-8.203zM145.254 15.509a6.3 6.3 0 00-1.205-.12c-2.487 0-4.587 1.235-5.481 3.345v-3.106h-4.392v19.393h4.509v-9.238c0-3.624 1.594-5.695 5.092-5.695.467 0 .972.04 1.477.12v-4.7zM146.335 29.765c0 3.066 2.449 5.853 6.414 5.853 3.071 0 4.937-1.592 5.831-3.066 0 1.553.156 2.35.194 2.47h4.199c-.039-.2-.234-1.355-.234-3.107v-9.637c0-3.862-2.215-7.247-8.046-7.247-4.665 0-7.619 2.986-7.969 6.332l4.121.955c.194-1.951 1.516-3.504 3.887-3.504 2.488 0 3.576 1.314 3.576 2.947 0 .677-.311 1.234-1.399 1.393l-4.859.757c-3.227.478-5.715 2.39-5.715 5.854zm7.269 2.23c-1.788 0-2.759-1.195-2.759-2.509 0-1.593 1.127-2.39 2.526-2.628l4.937-.757v.876c0 3.783-2.177 5.018-4.704 5.018zM41.975 21.5C41.975 9.626 32.578 0 20.987 0 9.398 0 0 9.626 0 21.5S9.396 43 20.988 43c11.59 0 20.987-9.626 20.987-21.5z"
        fill="#fff"
      />
      <path
        d="M11.36 22.418L25.668 7.863c.267-.272.697.06.517.398l-5.325 9.997c-.119.223.039.495.287.495h9.202c.296 0 .44.372.223.58L14.446 34.748c-.29.277-.72-.113-.488-.44l7.633-10.788c.157-.221.003-.531-.264-.531H11.59c-.292 0-.437-.362-.23-.572z"
        fill="#3BCBBE"
      />
    </g>
  ),
});

export const DocumentIcon = createIcon({
  displayName: "DocumentIcon",
  viewBox: "0 0 18 14",
  path: (
    <path d="M2.55127 13.303H15.4411C17.0758 13.303 17.9271 12.4593 17.9271 10.8471V6.62835C17.9271 5.7846 17.8066 5.42299 17.4148 4.91071L14.9438 1.61858C14.0624 0.450893 13.6029 0.126953 12.2469 0.126953H5.753C4.38944 0.126953 3.9299 0.450893 3.05601 1.61858L0.577497 4.91071C0.19329 5.42299 0.0727539 5.7846 0.0727539 6.62835V10.8471C0.0727539 12.4593 0.924037 13.303 2.55127 13.303ZM8.99993 8.66992C7.85484 8.66992 7.13916 7.75837 7.13916 6.81668V6.75642C7.13916 6.40988 6.92822 6.0784 6.49881 6.0784H1.96366C1.68492 6.0784 1.63972 5.8524 1.76779 5.6716L4.51751 1.98019C4.83391 1.54325 5.23319 1.37751 5.74547 1.37751H12.2544C12.7591 1.37751 13.1584 1.54325 13.4824 1.98019L16.2245 5.6716C16.3526 5.8524 16.3074 6.0784 16.0287 6.0784H11.4935C11.0641 6.0784 10.8607 6.40988 10.8607 6.75642V6.81668C10.8607 7.75837 10.145 8.66992 8.99993 8.66992ZM12.1565 2.5H5.85093C5.64 2.5 5.48179 2.66574 5.48179 2.86914C5.48179 3.08008 5.64 3.23828 5.85093 3.23828H12.1565C12.3599 3.23828 12.5181 3.08008 12.5181 2.86914C12.5181 2.66574 12.3599 2.5 12.1565 2.5ZM13.1358 4.17997H4.86405C4.64558 4.17997 4.47984 4.3683 4.47984 4.57171C4.47984 4.78264 4.64558 4.95591 4.86405 4.95591H13.1358C13.3543 4.95591 13.52 4.78264 13.52 4.57171C13.52 4.3683 13.3543 4.17997 13.1358 4.17997Z" fill="currentColor"/>
  ),
});

export const GlobeIcon = createIcon({
  displayName: "GlobeIcon",
  viewBox: "0 0 24 24",
  path: (
    <g>
      <path
        stroke="currentColor"
        fill="transparent"
        d="M11.25 2.109a9.14 9.14 0 100 18.281 9.14 9.14 0 000-18.281z"
        strokeWidth=".75"
        strokeMiterlimit="10"
      />
      <path
        stroke="currentColor"
        fill="transparent"
        d="M11.25 2.109C8.698 2.109 6.3 6.2 6.3 11.249c0 5.048 2.4 9.14 4.951 9.14 2.552 0 4.951-4.092 4.951-9.14 0-5.048-2.399-9.14-4.95-9.14z"
        strokeWidth=".75"
        strokeMiterlimit="10"
      />
      <path
        stroke="currentColor"
        fill="transparent"
        d="M5.156 5.156C6.836 6.349 8.952 7.06 11.25 7.06c2.298 0 4.414-.711 6.094-1.904"
      />
      <path
        stroke="currentColor"
        fill="transparent"
        d="M5.156 5.156C6.836 6.349 8.952 7.06 11.25 7.06c2.298 0 4.414-.711 6.094-1.904M17.344 17.344c-1.68-1.193-3.796-1.904-6.094-1.904-2.298 0-4.413.711-6.094 1.904"
        strokeWidth=".75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        stroke="currentColor"
        fill="transparent"
        d="M11.25 2.109v18.28M20.39 11.249H2.11"
        strokeWidth=".75"
        strokeMiterlimit="10"
      />
    </g>
  ),
});

export const HelpIcon = createIcon({
  displayName: "HelpIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M11.25 0C5.04 0 0 5.04 0 11.25S5.04 22.5 11.25 22.5 22.5 17.46 22.5 11.25 17.46 0 11.25 0zm-.352 17.813a1.172 1.172 0 110-2.344 1.172 1.172 0 010 2.344zm1.96-5.977c-.95.637-1.08 1.222-1.08 1.758a.82.82 0 11-1.641 0c0-1.284.59-2.305 1.806-3.121 1.13-.758 1.768-1.239 1.768-2.295 0-.718-.41-1.264-1.26-1.668-.199-.095-.644-.187-1.191-.18-.687.008-1.22.172-1.63.503-.775.623-.84 1.302-.84 1.312a.821.821 0 11-1.642-.08c.007-.142.106-1.425 1.452-2.507.698-.562 1.585-.854 2.636-.866.745-.01 1.444.117 1.918.34 1.418.672 2.198 1.79 2.198 3.146 0 1.982-1.325 2.872-2.494 3.658z"
    />
  ),
});

export const HomeIcon = createIcon({
  displayName: "HomeIcon",
  viewBox: "0 0 24 24",
  path: (
    <g>
      <path
        fill="currentColor"
        d="M11.494 4.951a.351.351 0 00-.486 0l-8.09 7.729a.352.352 0 00-.109.254v7.254a1.406 1.406 0 001.405 1.406h4.223a.703.703 0 00.704-.703v-5.976a.351.351 0 01.351-.352h3.516a.351.351 0 01.351.352v5.976a.703.703 0 00.704.703h4.22a1.407 1.407 0 001.407-1.406v-7.254a.35.35 0 00-.108-.254L11.494 4.95z"
      />
      <path
        fill="currentColor"
        d="M21.574 11.23l-3.287-3.144V3.314a.703.703 0 00-.703-.703h-2.11a.703.703 0 00-.703.703V4.72l-2.545-2.434c-.239-.24-.593-.378-.976-.378-.38 0-.734.138-.972.379L.93 11.23a.717.717 0 00-.058.983.703.703 0 001.018.046l9.119-8.713a.352.352 0 01.486 0l9.12 8.713a.703.703 0 00.992-.019c.27-.28.248-.74-.033-1.01z"
      />
    </g>
  ),
});

export const InvisionLogo = createIcon({
  displayName: "InvisionLogo",
  viewBox: "0 0 24 24",
  path: (
    <g clipPath="url(#clip0)">
      <path
        d="M18.687 0H2.313A1.813 1.813 0 00.5 1.811v16.374C.5 19.188 1.312 20 2.313 20h16.374a1.813 1.813 0 001.813-1.813V1.812A1.813 1.813 0 0018.687 0z"
        fill="#DC395F"
      />
      <path
        d="M7.184 6.293c.665 0 1.222-.522 1.222-1.204 0-.683-.557-1.204-1.222-1.204-.665 0-1.222.521-1.222 1.204 0 .682.557 1.204 1.222 1.204zM4.65 12.739a4.134 4.134 0 00-.108.905c0 1.06.575 1.764 1.797 1.764 1.013 0 1.834-.602 2.426-1.573l-.361 1.449h2.012l1.15-4.612c.287-1.168.844-1.774 1.689-1.774.665 0 1.078.413 1.078 1.096 0 .197-.018.413-.09.646l-.593 2.12c-.09.306-.126.611-.126.899 0 1.006.593 1.742 1.833 1.742 1.06 0 1.904-.682 2.371-2.317l-.79-.305c-.395 1.095-.737 1.293-1.006 1.293-.27 0-.414-.18-.414-.538 0-.162.037-.342.09-.558l.575-2.065c.144-.485.198-.915.198-1.31 0-1.546-.934-2.352-2.066-2.352-1.06 0-2.138.956-2.677 1.962l.395-1.806H8.962L8.53 8.996h1.438l-.885 3.544c-.695 1.545-1.972 1.57-2.132 1.534-.264-.06-.432-.159-.432-.5 0-.198.036-.482.126-.823l1.348-5.346H4.579l-.431 1.591h1.419L4.65 12.74"
        fill="#fff"
      />
    </g>
  ),
});

export const JiraLogo = createIcon({
  displayName: "JiraLogo",
  viewBox: "0 0 24 24",
  path: (
    <g clipPath="url(#clip0)">
      <path
        d="M20.26 10.42l-8.863-8.93-.86-.865-6.67 6.722L.814 10.42a.827.827 0 000 1.162l6.096 6.14 3.627 3.654 6.67-6.722.105-.104 2.947-2.964a.824.824 0 000-1.166zm-9.722 3.649L7.493 11l3.045-3.068L13.582 11l-3.044 3.068z"
        fill="#2684FF"
      />
      <path
        d="M10.537 7.932a5.184 5.184 0 01-1.502-3.637A5.185 5.185 0 0110.515.65L3.852 7.36l3.626 3.654 3.06-3.082z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M13.59 10.992l-3.053 3.076a5.186 5.186 0 011.502 3.653c0 1.37-.54 2.683-1.502 3.652l6.682-6.728-3.63-3.653z"
        fill="url(#paint1_linear)"
      />
    </g>
  ),
});

export const MastercardIcon = createIcon({
  displayName: "MastercardIcon",
  viewBox: "0 0 24 24",
  path: (
    <svg
      width="24"
      height="20"
      viewBox="0 0 21 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6.63158" cy="7.49999" r="6.63158" fill="#EB001B" />
      <circle cx="14.3686" cy="7.49999" r="6.63158" fill="#F79E1B" />
    </svg>
  ),
});

export const PayPalIcon = createIcon({
  displayName: "PayPalIcon",
  viewBox: "0 0 24 24",
  path: (
    <g>
      <path
        fill="currentColor"
        d="M16.179 2.532C15.142 1.357 13.265.854 10.863.854H3.895a.997.997 0 00-.986.834L.007 19.945a.595.595 0 00.591.686H4.9l1.08-6.8-.033.215a.993.993 0 01.983-.834h2.044c4.017 0 7.16-1.619 8.08-6.3.027-.14.07-.406.07-.406.262-1.732-.001-2.907-.945-3.974z"
      />
      <path
        fill="currentColor"
        d="M18.213 7.393c-.998 4.61-4.184 7.048-9.24 7.048H7.142L5.773 23.1h2.973a.87.87 0 00.862-.731l.035-.184.684-4.297.044-.237a.87.87 0 01.86-.731h.544c3.514 0 6.264-1.416 7.068-5.51.322-1.644.166-3.021-.63-4.017z"
      />
    </g>
  ),
});

export const PersonIcon = createIcon({
  displayName: "PersonIcon",
  viewBox: "0 0 22 14",
  path: (
    <path d="M14.9401 6.63588C16.5899 6.63588 17.9836 5.17439 17.9836 3.28348C17.9836 1.43778 16.5824 0.0214844 14.9401 0.0214844C13.3054 0.0214844 11.8966 1.46038 11.9041 3.29855C11.9041 5.17439 13.2903 6.63588 14.9401 6.63588ZM6.23141 6.80915C7.67031 6.80915 8.8832 5.52846 8.8832 3.88616C8.8832 2.274 7.65524 1.04604 6.23141 1.04604C4.80758 1.04604 3.57962 2.2966 3.58716 3.90123C3.58716 5.52846 4.80005 6.80915 6.23141 6.80915ZM2.08046 13.6496H7.73057C6.92449 12.4969 7.79084 10.2218 9.49341 8.88086C8.66473 8.34598 7.58744 7.95424 6.23141 7.95424C2.86394 7.95424 0.686768 10.4328 0.686768 12.4743C0.686768 13.2201 1.06344 13.6496 2.08046 13.6496ZM10.2468 13.642H19.6335C20.8765 13.642 21.3059 13.2653 21.3059 12.5723C21.3059 10.6362 18.8425 7.96931 14.9401 7.96931C11.0302 7.96931 8.57432 10.6362 8.57432 12.5723C8.57432 13.2653 9.00373 13.642 10.2468 13.642Z" fill="currentColor"/>
  ),
});

export const ProfileIcon = createIcon({
  displayName: "ProfileIcon",
  viewBox: "0 0 24 24",
  path: (
    <g>
      <path d="M0 0h24v24H0V0z" fill="transparent" />
      <path
        fill="currentColor"
        d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v1c0 .55.45 1 1 1h14c.55 0 1-.45 1-1v-1c0-2.66-5.33-4-8-4z"
      />
    </g>
  ),
});

export const RocketIcon = createIcon({
  displayName: "RocketIcon",
  viewBox: "0 0 24 24",
  path: (
    <g>
      <path
        fill="currentColor"
        d="M20.99 2.182a.209.209 0 00-.156-.16c-2.574-.63-8.521 1.613-11.743 4.833a13.93 13.93 0 00-1.566 1.85c-.994-.087-1.987-.014-2.834.355-2.39 1.052-3.085 3.796-3.279 4.976a.424.424 0 00.464.492l3.837-.423c.002.29.02.578.052.866.02.2.108.386.25.527l1.486 1.482a.86.86 0 00.528.25c.286.033.573.05.86.053l-.42 3.832a.424.424 0 00.492.464c1.178-.19 3.927-.885 4.972-3.274.37-.847.445-1.836.36-2.824a13.96 13.96 0 001.855-1.567c3.232-3.216 5.462-9.03 4.842-11.732zm-8.067 7.896a2.11 2.11 0 112.983-2.984 2.11 2.11 0 01-2.983 2.984z"
      />
      <path
        fill="currentColor"
        d="M7.4 18.054c-.24.241-.627.335-1.092.416-1.044.178-1.967-.725-1.779-1.78.072-.401.283-.962.415-1.094a.192.192 0 00-.16-.328 2.636 2.636 0 00-1.544.753c-1.033 1.034-1.13 4.87-1.13 4.87s3.838-.097 4.872-1.13c.417-.417.682-.961.752-1.546.017-.184-.207-.293-.334-.16z"
      />
    </g>
  ),
});

export const SettingsIcon = createIcon({
  displayName: "SettingsIcon",
  viewBox: "0 0 24 24",
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <g>
      <path d="M0,0h24v24H0V0z" fill="none" />
      <path
        fill="currentColor"
        d="M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94c0-0.32-0.02-0.64-0.07-0.94l2.03-1.58c0.18-0.14,0.23-0.41,0.12-0.61 l-1.92-3.32c-0.12-0.22-0.37-0.29-0.59-0.22l-2.39,0.96c-0.5-0.38-1.03-0.7-1.62-0.94L14.4,2.81c-0.04-0.24-0.24-0.41-0.48-0.41 h-3.84c-0.24,0-0.43,0.17-0.47,0.41L9.25,5.35C8.66,5.59,8.12,5.92,7.63,6.29L5.24,5.33c-0.22-0.08-0.47,0-0.59,0.22L2.74,8.87 C2.62,9.08,2.66,9.34,2.86,9.48l2.03,1.58C4.84,11.36,4.8,11.69,4.8,12s0.02,0.64,0.07,0.94l-2.03,1.58 c-0.18,0.14-0.23,0.41-0.12,0.61l1.92,3.32c0.12,0.22,0.37,0.29,0.59,0.22l2.39-0.96c0.5,0.38,1.03,0.7,1.62,0.94l0.36,2.54 c0.05,0.24,0.24,0.41,0.48,0.41h3.84c0.24,0,0.44-0.17,0.47-0.41l0.36-2.54c0.59-0.24,1.13-0.56,1.62-0.94l2.39,0.96 c0.22,0.08,0.47,0,0.59-0.22l1.92-3.32c0.12-0.22,0.07-0.47-0.12-0.61L19.14,12.94z M12,15.6c-1.98,0-3.6-1.62-3.6-3.6 s1.62-3.6,3.6-3.6s3.6,1.62,3.6,3.6S13.98,15.6,12,15.6z"
      />
    </g>
  ),
});

export const SlackLogo = createIcon({
  displayName: "SlackLogo",
  viewBox: "0 0 24 24",
  path: (
    <g clipPath="url(#clip0)" fillRule="evenodd" clipRule="evenodd">
      <path
        d="M7.832.5c-1.105 0-2 .919-1.999 2.05 0 1.13.895 2.048 2 2.05h2V2.55c.001-1.13-.894-2.048-2-2.05zm0 5.467H2.5c-1.106 0-2.001.918-2 2.05-.002 1.13.894 2.048 2 2.05h5.332c1.106-.001 2.002-.919 2-2.05.002-1.132-.894-2.05-2-2.05z"
        fill="#36C5F0"
      />
      <path
        d="M20.5 8.016c0-1.13-.895-2.048-2-2.05-1.106.002-2.002.92-2 2.05v2.05h2c1.105 0 2-.918 2-2.05zm-5.334 0V2.55c.001-1.13-.893-2.048-2-2.05-1.105 0-2 .919-2 2.05v5.466c-.001 1.131.894 2.049 2 2.05 1.106 0 2.001-.918 2-2.05z"
        fill="#2EB67D"
      />
      <path
        d="M13.166 21c1.106 0 2.001-.919 2-2.05.001-1.13-.894-2.048-2-2.05h-2v2.05c-.001 1.13.894 2.048 2 2.05zm0-5.467h5.333c1.106-.001 2.002-.92 2-2.05.002-1.131-.893-2.049-1.999-2.05h-5.333c-1.106 0-2.001.918-2 2.05-.001 1.13.893 2.049 1.999 2.05z"
        fill="#ECB22E"
      />
      <path
        d="M.5 13.483c-.001 1.13.895 2.049 2 2.05 1.106-.001 2.001-.92 2-2.05v-2.05h-2c-1.105.001-2.001.919-2 2.05zm5.333 0v5.467c-.001 1.13.894 2.048 2 2.05 1.105 0 2-.919 2-2.05v-5.465c.002-1.131-.894-2.05-2-2.05-1.106 0-2 .917-2 2.048z"
        fill="#E01E5A"
      />
    </g>
  ),
});

export const SpotifyLogo = createIcon({
  displayName: "SpotifyLogo",
  viewBox: "0 0 24 24",
  path: (
    <g clipPath="url(#clip0)">
      <path
        d="M10.5 0C4.977 0 .5 4.477.5 10s4.477 10 10 10 10-4.477 10-10c0-5.522-4.477-10-10-10zm4.586 14.422a.623.623 0 01-.857.208c-2.348-1.435-5.304-1.76-8.785-.964a.623.623 0 11-.277-1.216c3.808-.87 7.076-.495 9.712 1.115.294.181.387.564.207.857zm1.223-2.722a.78.78 0 01-1.072.257c-2.687-1.652-6.786-2.13-9.965-1.166a.78.78 0 01-.973-.519.781.781 0 01.52-.972c3.632-1.102 8.147-.569 11.233 1.329a.78.78 0 01.258 1.072zm.106-2.835C13.19 6.95 7.875 6.775 4.797 7.708a.935.935 0 11-.543-1.79c3.533-1.072 9.404-.865 13.115 1.338a.935.935 0 01-.954 1.609z"
        fill="#2EBD59"
      />
    </g>
  ),
});

export const SupportIcon = createIcon({
  // Doesn't display the full icon without w and h being specified
  displayName: "BuildIcon",
  viewBox: "0 0 13 18",
  path: (
    <path d="M3.88728 0.96375L10.2531 2.08624C10.9386 2.20677 11.5112 2.39511 11.9707 2.65878V2.16157C11.9406 0.526808 10.7879 -0.256674 8.91964 0.0747993L3.88728 0.96375ZM1.56696 15.5862L9.88393 17.0553C11.4735 17.334 12.4528 16.43 12.4528 14.7048V5.60438C12.4528 4.02234 11.6769 3.4046 10.0723 3.11833L2.00391 1.6945C0.813616 1.49109 0 2.16911 0 3.35186V13.7707C0 14.7877 0.534877 15.4054 1.56696 15.5862Z" fill="currentColor"/>
  ),
});

export const StatsIcon = createIcon({
  displayName: "StatsIcon",
  viewBox: "0 0 17 17",
  path: (
    <path fillRule="evenodd" clipRule="evenodd" d="M13.1896 16.0073H2.81777C0.967805 16.0073 0 15.0481 0 13.2152V2.79207C0 0.950676 0.967805 0 2.81777 0H13.1896C15.0481 0 16.0073 0.95924 16.0073 2.79207V13.2152C16.0073 15.0481 15.0481 16.0073 13.1896 16.0073ZM5.13199 12.6251C4.9272 12.898 4.55858 12.9354 4.30113 12.7963C4.03197 12.6518 3.9325 12.3415 4.1607 12.0365L10.8895 2.92436C11.106 2.63008 11.4454 2.58192 11.697 2.71034C11.9661 2.85481 12.0831 3.18655 11.8608 3.48618L5.13199 12.6251ZM6.64158 5.23584C6.64158 6.79288 5.82242 7.76135 4.48251 7.76135C3.14845 7.76135 2.32344 6.79823 2.32344 5.23584C2.32344 3.67345 3.1543 2.72639 4.48251 2.72639C5.81657 2.72639 6.64158 3.6681 6.64158 5.23584ZM3.48196 5.23584C3.48196 6.28992 3.84474 6.87849 4.48251 6.87849C5.12029 6.87849 5.48306 6.28992 5.48306 5.23584C5.48306 4.19247 5.11443 3.60925 4.48251 3.60925C3.85059 3.60925 3.48196 4.19782 3.48196 5.23584ZM13.6922 10.2761C13.6922 11.8332 12.8731 12.8017 11.5331 12.8017C10.1932 12.8017 9.36822 11.8385 9.36822 10.2761C9.36822 8.71376 10.1991 7.7667 11.5331 7.7667C12.8614 7.7667 13.6922 8.70841 13.6922 10.2761ZM10.5326 10.2761C10.5326 11.3302 10.8954 11.9188 11.5331 11.9188C12.1651 11.9188 12.5278 11.3302 12.5278 10.2761C12.5278 9.23277 12.1651 8.64955 11.5331 8.64955C10.9012 8.64955 10.5326 9.23812 10.5326 10.2761Z" fill="currentColor"/>
  ),
});

export const WalletIcon = createIcon({
  displayName: "WalletIcon",
  viewBox: "0 0 24 24",
  path: (
    <g>
      <path
        fill="currentColor"
        d="M4.447 4.818h14.062c.164 0 .328.01.491.031a2.9 2.9 0 00-3.406-2.441L4.03 4.382h-.013a2.9 2.9 0 00-1.805 1.149 3.848 3.848 0 012.236-.713zM18.51 5.875H4.446a2.816 2.816 0 00-2.813 2.812v8.438a2.816 2.816 0 002.813 2.812h14.062a2.815 2.815 0 002.813-2.812V8.687a2.815 2.815 0 00-2.813-2.812zm-2.088 8.437a1.406 1.406 0 110-2.811 1.406 1.406 0 010 2.811z"
      />
      <path
        fill="currentColor"
        d="M1.656 11.651V7.28c0-.952.528-2.549 2.358-2.895 1.553-.291 3.091-.291 3.091-.291s1.011.703.176.703-.813 1.077 0 1.077 0 1.032 0 1.032L4.007 10.62l-2.35 1.032z"
      />
    </g>
  ),
});

export const VisaIcon = createIcon({
  displayName: "VisaIcon",
  viewBox: "0 0 24 24",
  path: (
    <svg
    width="26"
    height="20"
    viewBox="0 0 30 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6708 2.96349C12.6568 4.15055 13.653 4.81298 14.4034 5.20684C15.1744 5.61099 15.4333 5.87013 15.4304 6.23147C15.4245 6.78459 14.8153 7.02862 14.2452 7.03812C13.2505 7.05475 12.6723 6.74889 12.2125 6.51753L11.8542 8.32341C12.3155 8.55247 13.1696 8.75217 14.0554 8.76087C16.1345 8.76087 17.4947 7.65543 17.5021 5.94145C17.5102 3.76625 14.7087 3.64579 14.7278 2.67348C14.7344 2.37871 14.9956 2.06408 15.568 1.98406C15.8512 1.94368 16.6332 1.91274 17.5198 2.35252L17.8677 0.605286C17.391 0.418253 16.7782 0.239136 16.0153 0.239136C14.0583 0.239136 12.6819 1.35962 12.6708 2.96349ZM21.2115 0.389687C20.8319 0.389687 20.5118 0.628245 20.3691 0.99433L17.3991 8.63249H19.4767L19.8901 7.40183H22.429L22.6689 8.63249H24.5L22.9021 0.389687H21.2115ZM21.5021 2.61641L22.1016 5.7116H20.4596L21.5021 2.61641ZM10.1518 0.389687L8.51418 8.63249H10.4939L12.1308 0.389687H10.1518ZM7.22303 0.389687L5.16233 6.00003L4.32878 1.22966C4.23097 0.697187 3.84472 0.389687 3.41579 0.389687H0.0471011L0 0.629037C0.691574 0.790671 1.47729 1.0514 1.95326 1.33033C2.24457 1.50067 2.32775 1.64964 2.42336 2.05458L4.00214 8.63249H6.0945L9.3021 0.389687H7.22303Z"
        fill="#4318FF"
        />
    </svg>
  ),
});

export const BackArrow = createIcon({
  displayName: "BackArrow",
  viewBox: "0 0 24 24",
  path: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.7">
        <path d="M16 19L9 12L16 5" stroke="#3D2541" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
    </svg>
  )
})

export const Check = createIcon({
  displayName: "Ceck",
  viewBox: "0 0 13 10",
  path: (
    <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 5.5L4 8.5L12 1" stroke="#92001A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
})

export const PersonAdd = createIcon({
  displayName: "PersonAdd",
  viewBox: "0 0 32 32",
  path: (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 15.5145C18.1998 15.5145 20.048 13.5658 20.048 11.0446C20.048 8.58371 18.1797 6.69531 16 6.69531C13.8103 6.69531 11.942 8.61384 11.942 11.0647C11.952 13.5658 13.8002 15.5145 16 15.5145ZM15.99 17.2924C14.3527 17.2924 12.9062 17.6641 11.7109 18.2366C13.1473 19.452 14.0714 21.26 14.0714 23.269C14.0714 23.8114 14.0011 24.3538 13.8605 24.856H22.2578C23.9051 24.856 24.4777 24.3538 24.4777 23.4297C24.4777 20.8482 21.2031 17.2924 15.99 17.2924ZM7.46205 28.452C10.2746 28.452 12.6451 26.1016 12.6451 23.269C12.6451 20.4364 10.3047 18.096 7.46205 18.096C4.62946 18.096 2.28906 20.4364 2.28906 23.269C2.28906 26.1116 4.62946 28.452 7.46205 28.452ZM4.1875 23.269C4.17746 22.8571 4.45871 22.5859 4.87054 22.5859H6.77902V20.6875C6.77902 20.2757 7.05022 19.9944 7.46205 19.9944C7.88393 19.9944 8.15513 20.2757 8.15513 20.6875V22.5859H10.0536C10.4654 22.5859 10.7467 22.8571 10.7467 23.269C10.7467 23.6908 10.4654 23.9621 10.0536 23.9621H8.15513V25.8705C8.15513 26.2824 7.88393 26.5536 7.46205 26.5536C7.05022 26.5536 6.77902 26.2824 6.77902 25.8705V23.9621H4.87054C4.46875 23.9621 4.1875 23.6908 4.1875 23.269Z" fill="#92001A"/>
    </svg>
  )
})

export const Dots = createIcon({
  displayName: "Dots",
  viewBox: "0 0 24 24",
  path: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#3D2541" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="#3D2541" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="#3D2541" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
})

export const Doc = createIcon({
  displayName: "Doc",
  viewBox: "0 0 20 20",
  path: (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.6">
    <path d="M6.65402 16.5583H13.3463C14.6897 16.5583 15.3929 15.8489 15.3929 14.4929V5.02581C15.3929 3.6635 14.696 2.9541 13.3463 2.9541H11.8145C11.5696 2.9541 11.4189 3.13616 11.4189 3.37472V3.48145C11.4189 4.30385 10.8602 4.79353 10.0001 4.79353C9.14634 4.79353 8.58133 4.30385 8.58133 3.48145V3.37472C8.58133 3.13616 8.43066 2.9541 8.18583 2.9541H6.65402C5.31055 2.9541 4.60742 3.6635 4.60742 5.02581V14.4929C4.60742 15.8489 5.31055 16.5583 6.65402 16.5583ZM7.69615 7.77553C7.45759 7.77553 7.28809 7.59975 7.28809 7.36747C7.28809 7.14146 7.45759 6.97196 7.69615 6.97196H12.3104C12.5364 6.97196 12.7122 7.14146 12.7122 7.36747C12.7122 7.59975 12.5364 7.77553 12.3104 7.77553H7.69615ZM7.69615 9.99791C7.45759 9.99791 7.28809 9.82213 7.28809 9.6024C7.28809 9.37012 7.45759 9.18806 7.69615 9.18806H9.93736C10.1634 9.18806 10.3391 9.37012 10.3391 9.6024C10.3391 9.82213 10.1634 9.99791 9.93736 9.99791H7.69615Z" fill="#3D2541"/>
    </g>
    </svg>
  )
})

export const CloseCircle = createIcon({
  displayName: "CloseCircle",
  viewBox: "0 0 20 20",
  path: (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.6">
    <path d="M9.99373 16.2444C13.5533 16.2444 16.4914 13.3064 16.4914 9.75307C16.4914 6.19978 13.547 3.26172 9.98745 3.26172C6.43416 3.26172 3.50238 6.19978 3.50238 9.75307C3.50238 13.3064 6.44044 16.2444 9.99373 16.2444ZM7.85297 12.4902C7.52024 12.4902 7.25657 12.2266 7.25657 11.8876C7.25657 11.7369 7.31934 11.5862 7.43862 11.4732L9.14621 9.75935L7.43862 8.05176C7.31934 7.93876 7.25657 7.78809 7.25657 7.63742C7.25657 7.29841 7.52024 7.04102 7.85297 7.04102C8.02875 7.04102 8.16686 7.09752 8.27986 7.21052L9.99373 8.91811L11.7139 7.20424C11.8394 7.08496 11.9713 7.02846 12.1408 7.02846C12.4735 7.02846 12.7372 7.29213 12.7372 7.62486C12.7372 7.78181 12.6744 7.91992 12.5551 8.04548L10.8475 9.75935L12.5551 11.4669C12.6681 11.5862 12.7309 11.7306 12.7309 11.8876C12.7309 12.2266 12.4672 12.4902 12.1282 12.4902C11.9587 12.4902 11.8143 12.4275 11.695 12.3145L9.99373 10.6131L8.29242 12.3145C8.17942 12.4337 8.02875 12.4902 7.85297 12.4902Z" fill="#3D2541"/>
    </g>
    </svg>
  )
})

export const EditCircle = createIcon({
  displayName: "EditCircle",
  viewBox: "0 0 20 20",
  path: (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.6">
    <path d="M9.99379 16.2566C13.5534 16.2566 16.4914 13.3185 16.4914 9.75893C16.4914 6.20564 13.5471 3.26758 9.98751 3.26758C6.43422 3.26758 3.50244 6.20564 3.50244 9.75893C3.50244 13.3185 6.4405 16.2566 9.99379 16.2566ZM12.5426 8.01995L11.7014 7.17871L12.1722 6.70787C12.4045 6.48186 12.6682 6.46303 12.8816 6.67648L13.0386 6.83343C13.252 7.04687 13.2457 7.31682 13.0135 7.54911L12.5426 8.01995ZM7.98486 12.5651L7.08712 12.9042C6.90506 12.9669 6.70417 12.7912 6.79206 12.5903L7.1499 11.7239L11.3059 7.56794L12.1471 8.40918L7.98486 12.5651Z" fill="#3D2541"/>
    </g>
    </svg>
  )
})

export const ShipmentIcon = createIcon({
  displayName: "ShipmentIcon",
  viewBox: "0 0 16 17",
  path: (
    <path d="M12.3543 5.75837L15.1492 4.18387C15.0287 4.06334 14.8855 3.9654 14.6972 3.86747L9.41622 0.899275C8.94915 0.635603 8.46701 0.5 7.99993 0.5C7.52532 0.5 7.04318 0.635603 6.5761 0.899275L5.12967 1.71289L12.3543 5.75837ZM7.9924 8.19168L11.1941 6.39872L4.00718 2.3457L1.29513 3.86747C1.11433 3.9654 0.963658 4.06334 0.843122 4.18387L7.9924 8.19168ZM8.54987 16.9682C8.62521 16.9531 8.69301 16.9155 8.76835 16.8778L14.5993 13.6083C15.33 13.1939 15.7067 12.7645 15.7067 11.6872V5.66043C15.7067 5.4721 15.6916 5.31389 15.6539 5.16323L8.54987 9.17104V16.9682ZM7.44245 16.9682V9.17104L0.338379 5.16323C0.300711 5.31389 0.285645 5.4721 0.285645 5.66043V11.6872C0.285645 12.7645 0.662319 13.1939 1.39307 13.6083L7.22398 16.8778C7.29178 16.9155 7.36712 16.9531 7.44245 16.9682Z" fill="currentColor"/>
  )
})

export const EditIcon = createIcon({
  displayName: "EditIcon",
  viewBox: "0 0 24 24",
  path: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 4V10H7" stroke="#3D2541" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M23 20V14H17" stroke="#3D2541" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M20.49 9.00008C19.9828 7.56686 19.1209 6.28548 17.9845 5.27549C16.8482 4.26551 15.4745 3.55984 13.9917 3.22433C12.5089 2.88883 10.9652 2.93442 9.50481 3.35685C8.04437 3.77928 6.71475 4.56479 5.64 5.64008L1 10.0001M23 14.0001L18.36 18.3601C17.2853 19.4354 15.9556 20.2209 14.4952 20.6433C13.0348 21.0657 11.4911 21.1113 10.0083 20.7758C8.52547 20.4403 7.1518 19.7346 6.01547 18.7247C4.87913 17.7147 4.01717 16.4333 3.51 15.0001" stroke="#3D2541" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)
})

export const PlusCircle = createIcon({
  displayName: "PlusCircle",
  viewBox: "0 0 14 14",
  path: (
    <path d="M6.99379 13.2444C10.5534 13.2444 13.4914 10.3064 13.4914 6.75307C13.4914 3.19978 10.5471 0.261719 6.98751 0.261719C3.43422 0.261719 0.502441 3.19978 0.502441 6.75307C0.502441 10.3064 3.4405 13.2444 6.99379 13.2444ZM3.95529 6.75935C3.95529 6.38895 4.21896 6.13156 4.58308 6.13156H6.37228V4.34235C6.37228 3.97824 6.62339 3.72084 6.98124 3.72084C7.35163 3.72084 7.60903 3.97824 7.60903 4.34235V6.13156H9.4045C9.76235 6.13156 10.026 6.38895 10.026 6.75935C10.026 7.11719 9.76235 7.37458 9.4045 7.37458H7.60903V9.16378C7.60903 9.52162 7.35163 9.7853 6.98124 9.7853C6.62339 9.7853 6.37228 9.52162 6.37228 9.16378V7.37458H4.58308C4.21896 7.37458 3.95529 7.11719 3.95529 6.75935Z" fill="currentColor"/>
  )
})

export const ActivePatients = createIcon({
  displayName: "PlusCircle",
  viewBox: "0 0 14 14",
  path: (
    <path d="M3.38389 1.03753H10.7121C10.6725 0.529018 10.3731 0.257812 9.82502 0.257812H4.26531C3.7229 0.257812 3.41779 0.529018 3.38389 1.03753ZM2.18042 2.68171H11.8252C11.7461 2.14495 11.4692 1.83419 10.8646 1.83419H3.13529C2.53073 1.83419 2.25952 2.14495 2.18042 2.68171ZM2.73413 13.3209H11.2545C12.4749 13.3209 13.119 12.6881 13.119 11.4789V5.45592C13.119 4.24679 12.4749 3.61398 11.2545 3.61398H2.73413C1.50806 3.61398 0.875244 4.24114 0.875244 5.45592V11.4789C0.875244 12.6881 1.50806 13.3209 2.73413 13.3209ZM6.99431 9.37709C6.0112 9.37144 5.24843 8.55218 5.24278 7.46736C5.24278 6.43903 6.01685 5.58587 6.99431 5.58587C7.97743 5.58587 8.74585 6.43903 8.74585 7.46736C8.74585 8.55218 7.98308 9.38839 6.99431 9.37709ZM3.68335 12.2247C4.12971 11.0439 5.43488 10.219 6.99431 10.219C8.5594 10.219 9.85892 11.0439 10.3109 12.2247H3.68335Z" fill="currentColor"/>
  )
})

export const Banknote = createIcon({
  displayName: "PlusCircle",
  viewBox: "0 0 16 10",
  path: (
    <path d="M0.547607 9.00956C0.547607 9.29771 0.739711 9.49547 1.03352 9.49547H14.9667C15.2605 9.49547 15.4526 9.29771 15.4526 9.00956V0.551339C15.4526 0.257533 15.2605 0.0654297 14.9667 0.0654297H1.03352C0.739711 0.0654297 0.547607 0.257533 0.547607 0.551339V9.00956ZM1.73978 7.99254V1.56836C1.73978 1.35931 1.84713 1.2576 2.04489 1.2576H13.961C14.1531 1.2576 14.2661 1.35931 14.2661 1.56836V7.99254C14.2661 8.19594 14.1531 8.30329 13.961 8.30329H2.04489C1.84713 8.30329 1.73978 8.19594 1.73978 7.99254ZM2.36129 7.55183C2.36129 7.63658 2.40649 7.67613 2.4856 7.67613H6.69493C6.14687 7.08852 5.82481 6.06585 5.82481 4.77197C5.82481 3.4894 6.14687 2.47238 6.68928 1.87912H2.4856C2.40649 1.87912 2.36129 1.92432 2.36129 2.00342V7.55183ZM9.29398 7.67613H13.5203C13.5937 7.67613 13.6389 7.63658 13.6389 7.55183V2.00342C13.6389 1.92432 13.5937 1.87912 13.5203 1.87912H9.29963C9.85899 2.47238 10.1867 3.4894 10.1867 4.77197C10.1867 6.06585 9.85334 7.08852 9.29398 7.67613ZM6.53673 4.77197C6.53673 6.46136 7.12434 7.55183 7.9775 7.55183C8.86457 7.55183 9.46913 6.46136 9.46913 4.77197C9.46913 3.08824 8.86457 2.00342 7.9775 2.00342C7.12434 2.00342 6.53673 3.08824 6.53673 4.77197Z" fill="currentColor"/>
  )
})

export const ArrowNext = createIcon({
  displayName: "ArrowNext",
  viewBox: "0 0 16 16",
  path: (
    <path d="M9.3595 3.39062L8.64 4.11013L12.0315 7.50012H2V8.50012H12.0315L8.6405 11.8906L9.3595 12.6096L13.6095 8.35962L13.953 8.00012L13.6095 7.64062L9.3595 3.39062Z" fill="#3D2541"/>
  )
})

export const TrashIcon = createIcon({
  displayName: "TrashIcon",
  viewBox: "0 0 16 18",
  path: (
    <path d="M4.43687 17.2674H11.5711C12.6032 17.2674 13.2058 16.7175 13.251 15.6929L13.7257 4.80706H14.7653C15.1495 4.80706 15.4433 4.50572 15.4433 4.12905C15.4433 3.75237 15.142 3.4661 14.7653 3.4661H11.4957V2.33608C11.4957 1.13072 10.7198 0.415039 9.42404 0.415039H6.56131C5.26556 0.415039 4.48961 1.13072 4.48961 2.33608V3.4661H1.23514C0.858468 3.4661 0.557129 3.75991 0.557129 4.12905C0.557129 4.51325 0.858468 4.80706 1.23514 4.80706H2.2823L2.76444 15.6929C2.80211 16.7175 3.40479 17.2674 4.43687 17.2674ZM6.05657 2.40388C6.05657 2.06487 6.29011 1.8464 6.66678 1.8464H9.3261C9.70278 1.8464 9.93631 2.06487 9.93631 2.40388V3.4661H6.05657V2.40388ZM5.58196 14.9547C5.28062 14.9547 5.06969 14.7588 5.06215 14.4574L4.83615 6.45689C4.82861 6.15555 5.03955 5.95215 5.35596 5.95215C5.6573 5.95215 5.86823 6.14802 5.87577 6.44936L6.10177 14.4499C6.11684 14.7513 5.9059 14.9547 5.58196 14.9547ZM8.00021 14.9547C7.6838 14.9547 7.47287 14.7588 7.47287 14.4574V6.44936C7.47287 6.15555 7.6838 5.95215 8.00021 5.95215C8.31662 5.95215 8.53509 6.15555 8.53509 6.44936V14.4574C8.53509 14.7588 8.31662 14.9547 8.00021 14.9547ZM10.4185 14.9622C10.0945 14.9622 9.88358 14.7513 9.89865 14.4574L10.1247 6.44936C10.1322 6.14802 10.3431 5.95215 10.6445 5.95215C10.9609 5.95215 11.1718 6.15555 11.1643 6.45689L10.9383 14.4574C10.9307 14.7588 10.7198 14.9622 10.4185 14.9622Z" fill="#92001A"/>
  )
})

export const Headphones = createIcon({
  displayName: "Headphones",
  viewBox: "0 0 18 15",
  path: (
    <path d="M1.57196 12.013H2.24997C2.36298 12.013 2.43831 11.9377 2.43831 11.8247V7.45529C2.43831 3.89948 5.14283 1.59424 8.99997 1.59424C12.8571 1.59424 15.5616 3.89948 15.5616 7.45529V11.8247C15.5616 11.9377 15.637 12.013 15.75 12.013H16.428C16.7595 12.013 17.0081 11.8021 17.0081 11.4857V7.10875C17.0081 2.96533 13.7159 0.17041 9.27118 0.17041H8.72877C4.28401 0.17041 0.991882 2.96533 0.991882 7.10875V11.4857C0.991882 11.8021 1.24049 12.013 1.57196 12.013ZM4.02788 14.921H4.92436C5.82838 14.921 6.36325 14.4238 6.36325 13.5649V10.1221C6.36325 9.26332 5.82838 8.76611 4.92436 8.76611H4.02788C3.52313 8.76611 3.23686 9.04485 3.23686 9.53453V14.1526C3.23686 14.6422 3.52313 14.921 4.02788 14.921ZM13.0831 14.921H13.9796C14.4843 14.921 14.7782 14.6422 14.7782 14.1526V9.53453C14.7782 9.03732 14.4843 8.76611 13.9796 8.76611H13.0831C12.1791 8.76611 11.6442 9.26332 11.6442 10.1221V13.5649C11.6442 14.4238 12.1791 14.921 13.0831 14.921Z" fill="currentColor"/>
  )
})

export const CrossCircle = createIcon({
  displayName: "CrossCircle",
  viewBox: "0 0 14 14",
  path: (
    <path d="M6.99379 13.2444C10.5534 13.2444 13.4914 10.3064 13.4914 6.75307C13.4914 3.19978 10.5471 0.261719 6.98751 0.261719C3.43422 0.261719 0.502441 3.19978 0.502441 6.75307C0.502441 10.3064 3.4405 13.2444 6.99379 13.2444ZM4.85303 9.49023C4.5203 9.49023 4.25663 9.22656 4.25663 8.88756C4.25663 8.73689 4.31941 8.58622 4.43869 8.47321L6.14627 6.75935L4.43869 5.05176C4.31941 4.93876 4.25663 4.78809 4.25663 4.63742C4.25663 4.29841 4.5203 4.04102 4.85303 4.04102C5.02881 4.04102 5.16692 4.09752 5.27992 4.21052L6.99379 5.91811L8.71394 4.20424C8.83949 4.08496 8.97133 4.02846 9.14083 4.02846C9.47356 4.02846 9.73723 4.29213 9.73723 4.62486C9.73723 4.78181 9.67445 4.91992 9.55517 5.04548L7.84759 6.75935L9.55517 8.46694C9.66818 8.58622 9.73096 8.73061 9.73096 8.88756C9.73096 9.22656 9.46728 9.49023 9.12828 9.49023C8.95877 9.49023 8.81438 9.42745 8.6951 9.31445L6.99379 7.61314L5.29248 9.31445C5.17948 9.43373 5.02881 9.49023 4.85303 9.49023Z" fill="currentColor"/>
  )
})

export const SendProd = createIcon({
  displayName: "SendProd",
  viewBox: "0 0 14 14",
  path: (
    <>
      <path fill="currentColor" fillRule="evenodd" d="M2.742 2.755A2.25 2.25 0 0 1 4.424 2h7.152a2.25 2.25 0 0 1 1.682.755l1.174 1.32c.366.412.568.944.568 1.495v.68a2.25 2.25 0 0 1-2.25 2.25h-9.5A2.25 2.25 0 0 1 1 6.25v-.68c0-.55.202-1.083.568-1.495zm1.682.745a.75.75 0 0 0-.561.252L2.753 5h2.212a1 1 0 0 1 .832.445l.406.61a1 1 0 0 0 .832.445h1.93a1 1 0 0 0 .832-.445l.406-.61A1 1 0 0 1 11.035 5h2.211l-1.109-1.248a.75.75 0 0 0-.56-.252z" clipRule="evenodd"/><path fill="currentColor" d="M1 10.75a.75.75 0 0 1 .75-.75h3.215a1 1 0 0 1 .832.445l.406.61a1 1 0 0 0 .832.445h1.93a1 1 0 0 0 .832-.445l.406-.61a1 1 0 0 1 .832-.445h3.215a.75.75 0 0 1 .75.75v1A2.25 2.25 0 0 1 12.75 14h-9.5A2.25 2.25 0 0 1 1 11.75z"/>
    </>
  )
})

export const CreateShipmentIcon = createIcon({
  displayName: "ShipmentIcon",
  viewBox: "0 0 16 16",
  path: (
    <><path fill="currentColor" d="M8.372 1.349a.75.75 0 0 0-.744 0l-4.81 2.748L8 7.131l5.182-3.034zM14 5.357L8.75 8.43v6.005l4.872-2.784A.75.75 0 0 0 14 11zm-6.75 9.078V8.43L2 5.357V11c0 .27.144.518.378.651z"/>
    </>
  )
})

export const NotificationIcon = createIcon({
  displayName: "NotificationIcon",
  viewBox: "0 0 21 21",
  path: (
    <>
      <path fill="currentColor" d="M 12 2 C 11.172 2 10.5 2.672 10.5 3.5 L 10.5 4.1953125 C 7.9131836 4.862095 6 7.2048001 6 10 L 6 15 L 18 15 L 18 10 C 18 7.2048001 16.086816 4.862095 13.5 4.1953125 L 13.5 3.5 C 13.5 2.672 12.828 2 12 2 z M 4 17 L 4 19 L 10.269531 19 A 2 2 0 0 0 10 20 A 2 2 0 0 0 12 22 A 2 2 0 0 0 14 20 A 2 2 0 0 0 13.728516 19 L 20 19 L 20 17 L 4 17 z"/>
    </>
  )
})