import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/userSlice";
import assessmentReducer from "./slices/assessmentSlice";
import notificationSlice from "./slices/notificationSlice";
import ordersSlice from "./slices/ordersSlice";
import customersSlice from "./slices/customersSlice";
import paymentsSlice from "./slices/paymentsSlice";
import discountSlice from "./slices/discountsSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    assessment: assessmentReducer,
    notification: notificationSlice,
    orders: ordersSlice,
    customers: customersSlice,
    payments: paymentsSlice,
    discounts: discountSlice,
  },
  devTools: process.env.REACT_APP_ENV !== "production",
});
