import { memo, useCallback, useState } from "react";
import { Flex, useToast, Spinner } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import centralApi from "centralApi";
import { BackArrow } from "components/Icons/Icons";
import { ShipmentSubmitSchema } from "../CreateShipment/ShipmentScheme";

const ShipmentPreviewHeader = ({ data }) => {
  const [submited, setSubmited] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { orderId = "" } = useParams();
  const { t } = useTranslation();

  const createToast = useToast();

  const goBack = useCallback(() => {
    history.replace(`/admin/orders/shipment/${orderId}`, { data });
  }, [history]);

  const submitShipment = useCallback(async () => {
    try {
      const readeToSend = confirm(
        "Do you really want to send the data and create a shipment?"
      );
      if (readeToSend) {
        setIsLoading(true);
        const {
          plannedShippingTime,
          plannedShippingDate,
          ...other
        } = ShipmentSubmitSchema.parse(data);
        const body = {
          ...other,
          plannedShippingDateAndTime: moment(
            `${plannedShippingDate} ${plannedShippingTime}`,
            "yyyy-MM-DD HH:mm"
          ).format("yyyy-MM-DDTHH:mm"),
        };


        await centralApi.post(`shipment/${orderId}/submit`, body);
        setSubmited(true);
        createToast({
          title: "Shipment created successfully",
          position: "top-right",
          isClosable: true,
          status: "success",
        });
      }
    } catch (err) {
      console.log("err", err);
      createToast({
        title: err?.response?.data?.message || err?.message,
        position: "top-right",
        isClosable: true,
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }, [data, orderId, setIsLoading, setSubmited, createToast]);

  const removeShipment = useCallback(async () => {
    try {
      setIsLoading(true);
      await centralApi.get(`shipment/${orderId}/cancel`);
      createToast({
        title: "Shipment has been deleted",
        position: "top-right",
        isClosable: true,
        status: "success",
      });
      setSubmited(false);
    } catch (err) {
      createToast({
        title: err?.response?.data?.message || err?.message,
        position: "top-right",
        isClosable: true,
        status: "error",
      });
      console.log("err", err);
    } finally {
      setIsLoading(false);
    }
  }, [orderId, setIsLoading]);

  return (
    <>
      {!submited && (
        <div onClick={goBack} className="back-link">
          <BackArrow /> {t("portal_orders_view_back")}
        </div>
      )}
      <Flex justifyContent="space-between">
        <div>
          <h2 className="title">
            {t("shipment_preview_top_title", "Shipment Preview")}
          </h2>
          <p className="descr">
            {t(
              "shipment_preview_top_description",
              "Please read the specific information related to the shipment."
            )}
          </p>
        </div>
        {isLoading && <Spinner color="red.700" size="xl" />}
        {!isLoading && submited && (
          <button className="custom-link" onClick={removeShipment}>
            {t("remove")}
          </button>
        )}
        {!isLoading && !submited && (
          <button className="custom-link" onClick={submitShipment}>
            {t("submit")}
          </button>
        )}
      </Flex>
    </>
  );
};

export default memo(ShipmentPreviewHeader);
