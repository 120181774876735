/*eslint-disable*/
import { Flex, Link, List, ListItem, Text } from '@chakra-ui/react'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { useTranslation } from 'react-i18next'
import version from '../../version'

export default function Footer(props) {
	const { t } = useTranslation()
	const auth = getAuth()
	const user = auth.currentUser

	const onClicker = () => {
		if (user) {
			user.getIdToken().then(idToken => {
				fetch('https://dev-api.libi.com/', {
					method: 'GET', // or 'POST'
					headers: {
						'Content-Type': 'application/json',
						// using the idToken you've just fetched
						Authorization: `Bearer ${idToken}`,
					},
				})
					.then(response => response.text())
					.then(data => {
						alert(data)
						console.log(data)
					})
					.catch(error => {
						console.error('Error:', error)
					})
			})
		}
	}

	return (
		<div className='footer'>
			<hr style={{ marginBottom: '20px' }} />
			<Flex
				flexDirection={{
					base: 'column',
					xl: 'row',
				}}
				alignItems={{
					base: 'center',
					xl: 'start',
				}}
				justifyContent='space-between'
				px='30px'
				pb='20px'
			>
				<Text
					color='gray.400'
					textAlign={{
						base: 'center',
						xl: 'start',
					}}
					mb={{ base: '20px', xl: '0px' }}
				>
					&copy; {1900 + new Date().getYear()},{' '}
					<Text as='span'>
						{t('portal_footer_copyright_title', 'Libi & Daughters Alchemy Ltd')}
					</Text>{' '}
					<Link
						color='rgb(139, 16, 38)'
						href='https://libi.com'
						target='_blank'
					>
						{t('portal_footer_copyright_link', 'Libi.com')}
					</Link>
				</Text>

				<List
					display='flex'
					flexDirection={{
						base: 'column',
						md: 'row',
					}}
					textAlign={{
						base: 'center',
						md: 'start',
					}}
				>
					<ListItem
						me={{
							base: '20px',
							md: '44px',
						}}
					>
						<Link color='gray.400' href='/terms'>
							{t('portal_footer_terms_services_title', 'Terms of Service')}
						</Link>
					</ListItem>
					<ListItem
						me={{
							base: '20px',
							md: '44px',
						}}
					>
						<Link
							color='gray.400'
							href='https://libi.com/policies/privacy-policy'
						>
							{t('portal_footer_privacy_policy_title', 'Privacy Policy')}
						</Link>
					</ListItem>
					<ListItem>
						{onAuthStateChanged(getAuth(), () => {
							getAuth().currentUser
						}) ? (
							<>
								<Link color='gray.400' href='/auth/signout'>
									{t('portal_footer_signout_title', 'Sign out')}
								</Link>
							</>
						) : (
							<></>
						)}
					</ListItem>

					<ListItem
						me={{
							base: '20px',
							md: '44px',
						}}
					>
						{/* <Link
                            color="gray.400"
                            href="https://libi.com/policies/terms-of-service"
                        >
                            Terms & Conditions
                        </Link> */}
					</ListItem>
					<ListItem
						me={{
							base: '20px',
							md: '44px',
						}}
					>
						v.{version} ~{' '}
						<Link
							href={
								process.env.REACT_APP_ENV == 'production'
									? 'https://portal.libi.com'
									: 'https://staging-portal.libi.com'
							}
						>
							{process.env.REACT_APP_ENV}
						</Link>
					</ListItem>
					{/* <ListItem>
                            <Button onClick={onClicker}>...</Button>
                        </ListItem> */}
				</List>
			</Flex>
		</div>
	)
}
