import { Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { MdDelete, MdEdit } from 'react-icons/md'
import { frequency, time } from 'variables/product'

function collectFirstLetters(text) {
  return text.split(' ').map(word => isNaN(word) ? word[0].toUpperCase() : word).join('');
}

const getTotalQty = (products) => {
  return products?.reduce((acc, product) => acc + parseInt(product?.qty), 0)
}

const ProductsTable = ({ products, canEdit, onEdit, onDelete }) => {
  const { t } = useTranslation();

  const checkFrequency = (inputFrequency) => {
    return frequency[inputFrequency] ? frequency[inputFrequency] : inputFrequency
  }
  
  return (
    products && Object.keys(products)?.length > 0
      ? (
        <div className="products-table-wrapper">
          <table className="products-table">
            <thead>
              <tr>
                {canEdit && <th></th>}
                <th>{t('portal_products_table_title_1')}</th>
                <th>{t('portal_products_table_title_2')}</th>
                <th>{t('portal_products_table_title_3')}</th>
                <th>{t('portal_products_table_title_4')}</th>
                <th>{t('portal_products_table_title_5')}</th>
                <th>{t('portal_products_table_title_6')}</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(products)
                .sort((a, b) => parseInt((a[1]?.ref ? a[1].ref : a[1]?.product)?.replace('p', '')) - parseInt((b[1]?.ref ? b[1].ref : b[1]?.product)?.replace('p', '')))
                .map(([key, product]) =>
                  <tr key={product?.id}>
                    {canEdit &&
                      <td>
                        <Flex gap={"5px"} margin={"0 -30px"} justifyContent={"center"}>
                          <MdEdit
                            fontSize={"22px"}
                            color={"rgba(61, 37, 65, 0.5)"}
                            cursor={"pointer"}
                            onClick={() => onEdit({ ...product, key })}
                          />
                          <MdDelete
                            fontSize={"22px"}
                            color={"rgba(61, 37, 65, 0.5)"}
                            cursor={"pointer"}
                            onClick={() => onDelete(key)}
                          />
                        </Flex>
                      </td>
                    }
                    <td>
                      {product?.ref ?
                        product?.ref?.replace('p', 'N°') :
                        product?.product?.replace('p', 'N°')
                      }
                    </td>
                    <td>{product?.title}</td>
                    <td className='products-table-title'>
                      {product?.time}
                      <span>{time[product?.time]}</span>
                    </td>
                    <td>{product?.qty}</td>
                    <td className='products-table-title'>
                      {frequency[product?.frequency] ? product?.frequency : collectFirstLetters(product?.frequency)}
                      <span>{checkFrequency(product?.frequency)}</span>
                    </td>
                    <td>
                      {product?.area?.length ?
                        product?.area?.map((item, index) =>
                          <React.Fragment key={item?.val}>
                            {item?.val}{product?.area?.length - 1 !== index && ', '}
                          </React.Fragment>
                        )
                        : '-'
                      }
                    </td>
                  </tr>
                )}
            </tbody>
          </table>
          <Text textAlign='end' p={4}>
            <b>Total:</b>&nbsp;{getTotalQty(Object.values(products))}
          </Text>
        </div>
      )
      : null
  )
}

export default ProductsTable