import { Flex } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import useScrollToTop from "hooks/useScrollToTop";
import ShipmentPreviewHeader from "./ShipmentPreviewHeader";
import ShipmentPreviewProductsTable from "./ShipmentPreviewProductsTable";
import TextRow from "./ShipmentPreviewTextInfoRow";

const formatAddress = (address) => {
  let parts = [];
  const keys = [
    "addressLine1",
    "addressLine2",
    "addressLine3",
    "cityName",
    "countyName",
    "provinceName",
    "postalCode",
    "countryName",
  ];
  keys.forEach((key) => {
    if (address[key] !== null && address[key] !== undefined) {
      parts.push(address[key]);
    }
  });

  return parts.join("\n ");
};

const formatChosenPackages = (chosenPackages, join = ". ") => {
  if (chosenPackages.lenght === 1) {
    return chosenPackages[0].value;
  } else {
    return chosenPackages.map((pack) => pack.value).join(join);
  }
};

const checkValueOnEmpty = (value) => {
  return value === null || value === undefined ? "-" : value;
};

const ShipmentPreview = () => {
  useScrollToTop();
  const history = useHistory();
  const { t } = useTranslation();
  const { data } = history.location.state;

  return (
    <Flex flexDirection="column">
      <ShipmentPreviewHeader data={data} />
      <hr className="hr" />
      <h2 className="shipment-preview-subtitle">
        {t("shipment.preview.shipment_detail", "Shipment Details")}
      </h2>
      <TextRow
        title={t("shipment.preview.shipment_detail_orderId", "Order ID:")}
        value={checkValueOnEmpty(data.orderId)}
      />
      <TextRow
        title={t(
          "shipment.preview.shipment_detail_product_name",
          "Type of shipment:"
        )}
        value={checkValueOnEmpty(data.product.productName)}
      />
      <TextRow
        title={t(
          "shipment.preview.shipment_detail_planned_shipping_date_and_time",
          "Planned Shipping Date:"
        )}
        value={moment(data.plannedShippingDate, "yyyy-MM-DD").format(
          "DD.MM.YYYY "
        )}
      />
      <TextRow
        title={t(
          "shipment.preview.shipment_detail_planned_shipping_date_and_time",
          "Planned Shipping Time:"
        )}
        value={data.plannedShippingTime}
      />
      {data.destinationType !== "eu" && (
        <>
          <TextRow
            title={t(
              "shipment.preview.shipment_detail_invoice_date",
              "Invoice Date:"
            )}
            value={moment(data.invoice.date, "yyyy-MM-DD").format("DD.MM.YYYY")}
          />
          <TextRow
            title={t(
              "shipment.preview.shipment_detail_invoice_number",
              "Invoice Number:"
            )}
            value={checkValueOnEmpty(data.invoice.number)}
          />
        </>
      )}
      <TextRow
        title={t(
          "shipment.preview.shipment_detail_product_name",
          "Type of shipment:"
        )}
        value={checkValueOnEmpty(data.product.productName)}
      />
      {data.customerReferences &&
        data.customerReferences.length &&
        data.customerReferences.map((customerReference, id) => (
          <TextRow
            key={id}
            title={`${t(
              "shipment.preview.shipment_detail_shipment_references",
              "Shipment References"
            )} ${id + 1}:`}
            value={customerReference.value}
          />
        ))}

      <hr className="hr" />
      <h2 className="shipment-preview-subtitle">
        {t("shipment.preview.receiver_details", "Receiver Details")}
      </h2>
      <TextRow
        title={t(
          "shipment.preview.receiver_details_business_contact",
          "Business Contact:"
        )}
        value={checkValueOnEmpty(data.receiverDetails.typeCode)}
      />
      <TextRow
        title={t(
          "shipment.preview.receiver_details_postal_address",
          "Postal Address:"
        )}
        value={formatAddress(data.receiverDetails.postalAddress)}
      />

      <hr className="hr" />
      <h2 className="shipment-preview-subtitle">
        {t(
          "shipment.preview.receiver_details_contact_information",
          "Receiver Details - Contact Information"
        )}
      </h2>
      <TextRow
        title={t(
          "shipment.preview.receiver_details_contact_information_faullname",
          "Full Name:"
        )}
        value={checkValueOnEmpty(
          data.receiverDetails.contactInformation.fullName
        )}
      />
      <TextRow
        title={t(
          "shipment.preview.receiver_details_contact_information_company_name",
          "Company Name:"
        )}
        value={checkValueOnEmpty(
          data.receiverDetails.contactInformation.companyName
        )}
      />
      <TextRow
        title={t(
          "shipment.preview.receiver_details_contact_information_phone",
          "Contact Phone:"
        )}
        value={checkValueOnEmpty(data.receiverDetails.contactInformation.phone)}
      />
      <TextRow
        title={t(
          "shipment.preview.receiver_details_contact_information_email",
          "Email:"
        )}
        value={checkValueOnEmpty(data.receiverDetails.contactInformation.email)}
      />
      <TextRow
        title={t(
          "shipment.preview.receiver_details_contact_information_mobile_phone",
          "Mobile Phone:"
        )}
        value={checkValueOnEmpty(
          data.receiverDetails.contactInformation.mobilePhone
        )}
      />

      <hr className="hr" />
      <h2 className="shipment-preview-subtitle">
        {t(
          "shipment.preview.receiver_details_postal_address",
          "Receiver Details - Postal Address"
        )}
      </h2>
      <TextRow
        title={t(
          "shipment.preview.receiver_details_postal_address_country_code",
          "Country Code:"
        )}
        value={checkValueOnEmpty(
          data.receiverDetails.postalAddress.countryCode
        )}
      />
      <TextRow
        title={t(
          "shipment.preview.receiver_details_postal_address_country_name",
          "Country Name:"
        )}
        value={checkValueOnEmpty(
          data.receiverDetails.postalAddress.countryName
        )}
      />
      <TextRow
        title={t(
          "shipment.preview.receiver_details_postal_address_province_code",
          "Province Code:"
        )}
        value={checkValueOnEmpty(
          data.receiverDetails.postalAddress.provinceCode
        )}
      />
      <TextRow
        title={t(
          "shipment.preview.receiver_details_postal_address_province_name",
          "Province Name:"
        )}
        value={checkValueOnEmpty(
          data.receiverDetails.postalAddress.provinceName
        )}
      />
      <TextRow
        title={t(
          "shipment.preview.receiver_details_postal_address_city_name",
          "City:"
        )}
        value={checkValueOnEmpty(data.receiverDetails.postalAddress.cityName)}
      />
      <TextRow
        title={t(
          "shipment.preview.receiver_details_postal_address_address_line_1",
          "Address Line 1:"
        )}
        value={checkValueOnEmpty(
          data.receiverDetails.postalAddress.addressLine1
        )}
      />
      <TextRow
        title={t(
          "shipment.preview.receiver_details_postal_address_address_line_2",
          "Address Line 2:"
        )}
        value={checkValueOnEmpty(
          data.receiverDetails.postalAddress.addressLine2
        )}
      />
      <TextRow
        title={t(
          "shipment.preview.receiver_details_postal_address_address_line_3",
          "Address Line 3:"
        )}
        value={checkValueOnEmpty(
          data.receiverDetails.postalAddress.addressLine3
        )}
      />
      <TextRow
        title={t(
          "shipment.preview.receiver_details_postal_address_postal_code",
          "Postal Code:"
        )}
        value={checkValueOnEmpty(data.receiverDetails.postalAddress.postalCode)}
      />
      <TextRow
        title={t(
          "shipment.preview.receiver_details_postal_address_county_name",
          "County:"
        )}
        value={checkValueOnEmpty(data.receiverDetails.postalAddress.countyName)}
      />

      <hr className="hr" />
      <h2 className="shipment-preview-subtitle">
        {t(
          "shipment.preview.summarize",
          "Summarize the contents of your shipment (in detail)"
        )}
      </h2>
      <TextRow
        title={t("shipment.preview.summarize_description", "Description:")}
        value={checkValueOnEmpty(data.content.description)}
      />
      <TextRow
        title={t("shipment.preview.summarize_incoterm", "Incoterm:")}
        value={checkValueOnEmpty(data.content.incoterm)}
      />

      <hr className="hr" />
      <h2 className="shipment-preview-subtitle">
        {t("shipment.preview.commonf_fields", "Common Fields")}
      </h2>
      <TextRow
        title={t(
          "shipment.preview.commonf_fields_type_of_shipment",
          "Type of shipment:"
        )}
        value={`${data.product.productName} - ${data.product.price}`}
      />
      <TextRow
        title={t(
          "shipment.preview.commonf_fields_selected_packaging",
          "Selected packaging:"
        )}
        value={formatChosenPackages(data.chosenPackages)}
      />
      <TextRow
        title={t("shipment.preview.commonf_fields_total_price", "Total Price:")}
        value={checkValueOnEmpty(data.declaredValue.toString())}
      />
      <TextRow
        title={t("shipment.preview.commonf_fields_currency", "Currency:")}
        value={checkValueOnEmpty(data.declaredValueCurrency)}
      />
      <TextRow
        title={t(
          "shipment.preview.commonf_destination_type",
          "Destination Type:"
        )}
        value={checkValueOnEmpty(data.destinationType)}
      />

      <ShipmentPreviewProductsTable products={data.lineItems} />
    </Flex>
  );
};

export default ShipmentPreview;
