import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { Flex, Spinner, Text, Textarea, useDisclosure } from "@chakra-ui/react";

import centralApi from "centralApi";
import api from "api";
import { db } from "../../../../firebase";
import OrdersViewHeader from "./OrdersViewHeader";
import OrdersViewInfo from "./OrdersViewInfo";
import AssessmentForm from "../AssessmentForm/AssessmentForm";
import ProductModal from "../ProductModal";
import ProductsTable from "../ProductsTable";

const getTotalQty = (products) => {
  return products?.reduce(
    (acc, product) => acc + parseInt(product?.quantity),
    0
  );
};

const useSend = ({ order, commentBlock, history }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    setIsError(false);
    setError(false);

    try {
      await api.post("updateTreatment", {
        id: order?.treatmentId,
        isTreatment: true,
        version: 3,
        additional_comment_аlternate_block: commentBlock,
      });
      history.push("/admin/orders");
    } catch (err) {
      setError(err);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  });

  return {
    sendError: error,
    isSendError: isError,
    isSendLoading: isLoading,
    onSend: fetchData,
  };
};

const useGetShipment = ({ orderId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState(false);
  const [responseData, setResponseData] = useState();

  const getCreatedShipment = async () => {
    setIsLoading(true);
    setIsError(false);
    setError(false);

    try {
      const { data } = await centralApi.get(`shipment/${orderId}`);
      if (data?.exists !== false) {
        setResponseData(data);
      } else {
        setResponseData(null);
      }
    } catch (err) {
      setError(err);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCreatedShipment();

    return () => {
      setIsLoading(false);
    };
  }, [orderId]);

  return {
    shipmentError: error,
    isShipmentError: isError,
    isShipmentLoading: isLoading,
    shipmentData: responseData,
    setShipmentData: setResponseData,
  };
};

const useGetOrder = ({ orderId, searchQuery }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState(false);
  const [order, setOrder] = useState({});
  const [allProducts, setAllProducts] = useState({});
  const [products, setProducts] = useState({});
  const [commentBlock, setCommentBlock] = useState("");
  const [user, setUser] = useState({});
  const [assessment, setAssessment] = useState({});

  const fetchOrder = async () => {
    try {
      setIsLoading(true);

      const { data: orderData } = await api.get("orderByID", {
        params: { orderId },
      });
      console.log(orderData);

      if (orderData) {
        if (
          searchQuery.get("type") === "qc" ||
          searchQuery.get("type") === "ds" ||
          searchQuery.get("type") === "se" ||
          searchQuery.get("type") === "st" ||
          searchQuery.get("type") === "ip"
        ) {
          let productsData = [];
          const productsRef = collection(db, "Products");
          const productsSnap = await getDocs(productsRef);
          if (!productsSnap.empty) {
            productsSnap.forEach((doc) => {
              productsData.push({ id: doc.id, ...doc.data() });
            });
          }
          setAllProducts(productsData);
        } else if (searchQuery.get("type") === "rb" && orderData?.treatmentId) {
          const { data: treatmentsByUserId } = await api.get(
            "getTreatmentsByUserId",
            {
              params: { userId: orderData.user_id },
            }
          );
          const treatment = treatmentsByUserId?.find(
            (i) => i?.id === orderData?.treatmentId
          );

          if (treatment) {
            setProducts(treatment?.products);
            setCommentBlock(treatment?.additional_comment_аlternate_block);
          }
        }

        setOrder(orderData);

        const { data: userByID } = await centralApi.get("userByID", {
          params: { userId: orderData.user_id },
        });

        if (userByID) {
          setUser(userByID);

          if (orderData?.assessmentId && searchQuery.get("type") === "rb") {
            const afRef = doc(db, "AssessmentForms", orderData.assessmentId);
            const afDoc = await getDoc(afRef);

            if (afDoc.exists()) {
              setAssessment({ id: afDoc.id, ...afDoc.data() });

              // const {data: treatmentsByUserId} = await api.get('getTreatmentsByUserId', { params: { userId: userByID.id } })
              // const treatmentsByAf = treatmentsByUserId?.filter(i => i?.assesment_form_id === afDoc.id)

              // const treatmentsRef = collection(db, 'Users', userByID.userID, 'Treatments')
              // const treatmentsQuery = query(
              //   treatmentsRef,
              //   where('assesment_form_id', '==', afDoc.id),
              // )
              // const treatmentDocs = await getDocs(treatmentsQuery)

              // if (!!treatmentsByAf?.length) {
              //   setProducts(treatmentsByAf?.products)
              // }
            }
          }
        }
      }
    } catch (err) {
      setError(err);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOrder();

    return () => {
      setIsLoading(false);
    };
  }, []);

  return {
    orderError: error,
    isOrderError: isError,
    isOrderLoading: isLoading,
    order,
    allProducts,
    products,
    commentBlock,
    user,
    assessment,
    setProducts,
    setCommentBlock,
  };
};

const OrdersView = () => {
  const { t } = useTranslation();
  const { orderId } = useParams();
  const { search } = useLocation();
  const history = useHistory();

  const expert = useSelector((state) => state.user);

  const searchQuery = new URLSearchParams(search);

  const [productsIsVisible, setProductsIsVisible] = useState(
    searchQuery.get("tab") === "products"
  );
  const [productToEdit, setProductToEdit] = useState(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const onEdit = (product) => {
    setProductToEdit(product);
    onOpen();
  };

  const onDelete = async (ref) => {
    if (confirm("Do you really want to delete this product?")) {
      const filteredProducts = Object.fromEntries(
        Object.entries(products).filter(([key, value]) => key !== ref)
      );

      setProducts(filteredProducts);

      await api.post("updateTreatment", {
        id: order.treatmentId,
        products: filteredProducts,
      });

      if (order?.packageId) {
        const packageProducts = Object.values(filteredProducts).map((i) => ({
          area: i?.area || [],
          frequency: i?.frequency || "",
          qty: i?.qty || 1,
          time: i?.time || "",
          title: i?.title || "",
          value: i?.product || "",
        }));
        await centralApi.post("updatePackage", {
          id: order.packageId,
          products: packageProducts,
        });
      }
    }
  };

  const {
    isOrderError,
    isOrderLoading,
    order,
    allProducts,
    products,
    commentBlock,
    user,
    assessment,
    setProducts,
    setCommentBlock,
  } = useGetOrder({ orderId, searchQuery });
  const { isShipmentLoading, shipmentData, setShipmentData } = useGetShipment({
    orderId,
  });
  const { onSend, isSendLoading } = useSend({ order, commentBlock, history });

  if (isOrderLoading || isShipmentLoading) {
    return (
      <Flex justifyContent="center">
        <Spinner color="red.700" size="xl" />
      </Flex>
    );
  }

  return (
    <Flex flexDirection="column">
      <OrdersViewHeader
        orderId={orderId}
        expertId={expert.id}
        shipmentData={shipmentData}
        setShipmentData={setShipmentData}
      />
      <hr className="hr" />
      <OrdersViewInfo
        user={user}
        order={order}
        shipmentData={shipmentData}
        isOrderError={isOrderError}
      />
      {!isOrderError ? (
        <>
          <hr className="hr" />
          {(searchQuery.get("type") === "qc" ||
            searchQuery.get("type") === "se" ||
            searchQuery.get("type") === "st" ||
            searchQuery.get("type") === "ds") && (
            <>
              <Text fontSize={15} color="#3D2541" mb="20px">
                <b>{t("portal_orders_new_qc_product_title")}:</b>&nbsp;
                {order?.product?.title || "-"}
              </Text>
              <Text fontSize={15} color="#3D2541" mb="20px">
                <b>{t("portal_orders_new_qc_allergy_title")}:</b>&nbsp;
                {order?.allergy}
              </Text>
            </>
          )}
          {(searchQuery.get("type") === "qc" ||
            searchQuery.get("type") === "ds" ||
            searchQuery.get("type") === "se" ||
            searchQuery.get("type") === "st" ||
            searchQuery.get("type") === "ip") &&
            order?.product?.products?.length > 0 && (
              <div className="individual-products-table-scroll">
                <table
                  className="individual-products-table"
                  style={{ borderBottomWidth: 1 }}
                >
                  <thead>
                    <tr className="individual-products-table-tr">
                      <th className="individual-products-table-th">
                        <span className="individual-products-table-td-accent">
                          {t("portal_orders_new_qc_table_title_1")}
                        </span>
                      </th>
                      <th className="individual-products-table-th">
                        {t("portal_orders_new_qc_table_title_2")}
                      </th>
                      <th className="individual-products-table-th">
                        {t("portal_orders_new_qc_table_title_3")}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="individual-products-table-body">
                    {order.product.products.map((i) => (
                      <tr key={i?.ref} className="individual-products-table-tr">
                        <td className="individual-products-table-td">
                          <span className="individual-products-table-td-accent">
                            {allProducts.find((j) => j?.ref === i?.ref)?.title}
                          </span>
                        </td>
                        <td className="individual-products-table-td">
                          {i?.ref}
                        </td>
                        <td className="individual-products-table-td">
                          {i?.quantity}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Text textAlign="end" p={4}>
                  <b>Total:</b>&nbsp;
                  {getTotalQty(Object.values(order.product.products))}
                </Text>
              </div>
            )}
          {searchQuery.get("type") === "rb" && (
            <>
              <div className="af-tabs">
                <div
                  className={`title af-tab ${
                    !productsIsVisible && "af-tab-active"
                  }`}
                  onClick={() => setProductsIsVisible(false)}
                >
                  {t("portal_orders_view_tab_1")}
                </div>
                <div
                  className={`title af-tab ${
                    productsIsVisible && "af-tab-active"
                  }`}
                  onClick={() => setProductsIsVisible(true)}
                >
                  {t("portal_orders_view_tab_2")}
                </div>
              </div>
              {productsIsVisible ? (
                <>
                  <Flex justifyContent={"space-between"} alignItems={"center"}>
                    <h3
                      className="title"
                      style={{
                        fontSize: "26px",
                        color: "#3D2541",
                      }}
                    >
                      {t("portal_orders_view_tab_2_title")}
                    </h3>
                    {(expert?.canEditOrder || expert.roleId == 2) && (
                      <button
                        type="button"
                        className="custom-link"
                        onClick={onOpen}
                      >
                        {t("portal_orders_new_add_product")}
                      </button>
                    )}
                  </Flex>

                  <ProductsTable
                    products={products}
                    canEdit={expert?.canEditOrder || expert.roleId == 2}
                    onEdit={onEdit}
                    onDelete={onDelete}
                  />
                  <Flex direction="column" gap={2} mt={4}>
                    <Text fontSize={17} fontWeight="bold" color="#92001a">
                      Additional information on the treatment plan
                    </Text>
                    <Textarea
                      value={commentBlock}
                      onChange={(event) => {
                        setCommentBlock(event.target.value);
                      }}
                      resize="none"
                    />
                    <button
                      type="button"
                      className="custom-btn"
                      style={{ alignSelf: "flex-end" }}
                      disabled={isSendLoading}
                      onClick={onSend}
                    >
                      {isSendLoading ? "Sending" : "Update treatment"}
                    </button>
                  </Flex>

                  {(expert?.canEditOrder || expert.roleId == 2) && (
                    <ProductModal
                      product={productToEdit}
                      oldProducts={products}
                      setNewProducts={setProducts}
                      userID={user.id}
                      packageID={order?.packageId}
                      treatmentID={order?.treatmentId}
                      isOpen={isOpen}
                      onClose={() => {
                        onClose();
                        setProductToEdit(null);
                      }}
                    />
                  )}
                </>
              ) : (
                <>
                  <AssessmentForm initialState={assessment} viewOnly />
                  <div style={{ marginBottom: "30px" }}>
                    <p className="assessment-title">
                      {t("portal_orders_view_af_comment")}{" "}
                      {!assessment?.recommendation && "-"}
                    </p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: assessment?.recommendation,
                      }}
                    />
                  </div>
                  <div>
                    <span className="assessment-title">
                      {t("portal_orders_view_af_doctor")}
                    </span>{" "}
                    {assessment?.doctorName}
                  </div>
                </>
              )}
            </>
          )}
        </>
      ) : null}
    </Flex>
  );
};

export default OrdersView;
