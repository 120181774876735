import React from 'react'
import { Button, Flex } from '@chakra-ui/react'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'

const Pagination = ({
  pageIndex,
  pageOptions,
  previousPage,
  canPreviousPage,
  nextPage,
  canNextPage
}) => {
  const maxPage = typeof pageOptions === 'number' ? pageOptions : pageOptions.length;
  return (
    <Flex
      alignItems={"center"}
      gap={".5rem"}
      justifyContent={"flex-end"}
      mt={"1rem"}
    >
      <span>
        Page{" "}
        <b>
          {pageIndex + 1} of {maxPage}
        </b>
      </span>
      <Button
        onClick={() => previousPage()}
        disabled={!canPreviousPage}
        style={{
          width: "40px",
          height: "40px",
          borderRadius: "6px",
          border: '1px solid rgba(61, 37, 65, 0.2)',
          background: 'rgba(255, 255, 255, 0.8)',
          padding: 0,
        }}
      >
        <IoIosArrowBack />
      </Button>
      <Button
        onClick={() => nextPage()}
        disabled={!canNextPage}
        style={{
          width: "40px",
          height: "40px",
          borderRadius: "6px",
          border: '1px solid rgba(61, 37, 65, 0.2)',
          background: 'rgba(255, 255, 255, 0.8)',
          padding: 0,
        }}
      >
        <IoIosArrowForward />
      </Button>
    </Flex>
  )
}

export default Pagination