/*eslint-disable*/
// chakra imports
import {
  Box, Flex, useColorModeValue
} from "@chakra-ui/react";
import React from "react";
import SidebarContent from "./SidebarContent";

// FUNCTIONS

function Sidebar(props) {
  // to check for active links and opened collapses
  const mainPanel = React.useRef();
  let variantChange = "0.2s linear";

  const { logoText, routes, sidebarVariant } = props;

  //  BRAND
  //  Chakra Color Mode
  let sidebarBg = "none";
  let sidebarRadius = "0px";
  let sidebarMargins = "0px";
  if (sidebarVariant === "opaque") {
    sidebarBg = useColorModeValue("white", "gray.700");
    sidebarRadius = "16px";
    sidebarMargins = "16px 0px 16px 16px";
  }

  // SIDEBAR
  return (
    <Box ref={mainPanel}>
      <Flex 
        position="fixed"
        zIndex={10}
        flexDirection={'column'} 
      >
        <Box
          bg={sidebarBg}
          transition={variantChange}
          w={{xl: "250px", sm: '50px'}}
          maxW={{xl: "250px", sm: '50px'}}
          pl={{xl: "50px", sm: 0}}
          pt={{xl: "46px", sm: 0}}
          h="100vh"
          m={sidebarMargins}
          borderRadius={sidebarRadius}
          className="sidebar-container"
        >
          <SidebarContent routes={routes}
            display="none"
            sidebarVariant={sidebarVariant}
          />
        </Box>
      </Flex>
    </Box>
  );
}




export default Sidebar;
