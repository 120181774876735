import { SearchIcon } from "@chakra-ui/icons";
import { Box, Flex, Spinner, useDisclosure, useToast } from "@chakra-ui/react";
import centralApi from "centralApi";
import api from "api";
import DotsLoader from "components/DotsLoader";
import {
  CreateShipmentIcon,
  CreditIcon,
  CrossCircle,
  Doc,
  Headphones,
  PlusCircle,
  SendProd,
} from "components/Icons/Icons";
import Pagination from "components/Pagination";
import TableDropdown from "components/TableDropdown";
import { updateOrder, setOrders } from "store/slices/ordersSlice";
import { onRefund } from "dbManager";
import { getOrders, requestPayment, updateStorageOrders } from "helpers";
import moment from "moment/moment";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  useAsyncDebounce,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import SendToProductionModal from "../SendToProdModal";

const OrdersList = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const orders = useSelector((state) => state.orders.list);

  const toast = useToast();

  const [sentRequestIds, setSentRequestIds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const [search, setSearch] = useState("");
  const [sendProdData, setSendProdData] = useState(null);
  const [canSendToProduction, setCanSendToProduction] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const columns = useMemo(
    () => [
      {
        Header: t("portal_shipments_table_column_1_title"),
        accessor: "ref",
        Cell: (value) => (
          <Link
            to={
              value.cell.row.original.actionStatus === "send_assessment"
                ? `/admin/orders/send/${value.cell.row.original.id}/${value.cell.row.original.treatmentId}`
                : `/admin/order/${value.cell.row.original.id}${
                    value.cell.row.original.type?.includes("qc")
                      ? "?type=qc"
                      : value.cell.row.original.type === "ds"
                      ? "?type=ds"
                      : value.cell.row.original.type === "se"
                      ? "?type=se"
                      : value.cell.row.original.type === "st"
                      ? "?type=st"
                      : value.cell.row.original.type === "ip"
                      ? "?type=ip"
                      : "?type=rb&tab=products"
                  }`
            }
          >
            <u>{value?.cell?.value?.replace("LD", "")}</u>
          </Link>
        ),
        width: 90,
        maxWidth: 90,
        minWidth: 90,
      },
      {
        Header: t("portal_orders_table_column_1_title"),
        accessor: "created",
        Cell: ({ value }) =>
          value ? (
            <span title={moment(value).format("HH:mm MMM DD YYYY")}>
              {moment(value).format("DD/MM/YYYY")}
            </span>
          ) : (
            "-"
          ),
        width: 70,
        maxWidth: 70,
        minWidth: 70,
        sortType: (a, b) => {
          if (
            new Date(a.values.created).getTime() <
            new Date(b.values.created).getTime()
          ) {
            return -1;
          } else if (
            new Date(a.values.created).getTime() >
            new Date(b.values.created).getTime()
          ) {
            return 1;
          }

          return 0;
        },
      },
      {
        Header: t("portal_orders_table_column_2_title"),
        accessor: "userName",
        Cell: (value) => {
          return (
            <Link to={`/admin/customers/${value.cell.row.original.userId}`}>
              <u>{`${value.cell.row.original.user.firstName?.trim()} ${
                value.cell.row.original.user.lastName
              }`}</u>
            </Link>
          );
        },
      },
      {
        Header: t("portal_orders_table_column_5_title"),
        accessor: "type",
        Cell: ({ value }) => (value ? value.toUpperCase() : "-"),
        width: 50,
        maxWidth: 50,
        minWidth: 50,
      },
      {
        Header: t("portal_orders_table_column_4_title"),
        accessor: "status",
        Cell: (row) => (
          <span
            style={{
              color:
                ["paid_shopify", "paid_stripe", "delivered"].includes(
                  row.row.original.status
                ) && row.row.original.actionStatus !== "send_assessment"
                  ? "#219653"
                  : "#DF710C",
            }}
          >
            {row.row.original.actionStatus === "send_assessment" &&
            row.row.original.status !== "canceled" ? (
              "Completion needed"
            ) : (
              <>
                {row.row.original.status === "payment_needed"
                  ? "Payment needed"
                  : ""}
                {row.row.original.status === "paid_shopify" ||
                row.row.original.status === "paid_stripe"
                  ? row.row.original.type === "rb"
                    ? "Assessment required"
                    : "Paid"
                  : ""}
                {row.row.original.status === "delivery_in_process"
                  ? "Delivery In-Process"
                  : ""}
                {row.row.original.status === "delivery_needed"
                  ? "Delivery Needed"
                  : ""}
                {row.row.original.status === "delivered" ? "Delivered" : ""}
                {!!row.row.original.weeklyQuestionnaireDone &&
                row.row.original.status === "questionnaire_needed"
                  ? "Assessment Needed"
                  : ""}
                {!row.row.original.weeklyQuestionnaireDone &&
                row.row.original.status === "questionnaire_needed"
                  ? "Questionnaire Needed"
                  : ""}
                {row.row.original.status === "canceled" ? "Canceled" : ""}
                {row.row.original.status === "request_refund"
                  ? "Refund request"
                  : ""}
                {row.row.original.status === "refunded" ? "Refunded" : ""}
                {row.row.original.status === "refund_rejected"
                  ? "Refund rejected"
                  : ""}
              </>
            )}
          </span>
        ),
      },
    ],
    [sentRequestIds]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setGlobalFilter,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data: orders,
      initialState: { pageSize: 10, canSendToProduction },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useFlexLayout,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        ...columns,
        {
          id: "action",
          Header: () => "Manage",
          Cell: ({ row, initialState }) => (
            <TableDropdown>
              {(row.original.actionStatus === "update_assessment" ||
                row.original.actionStatus === "skip_weekly" ||
                row.original.actionStatus === "skip_initial") &&
                row.original.status !== "canceled" && (
                  <div
                    className="dropdown-item"
                    onClick={handleChangeUserQuestionnaireStatus(row)}
                  >
                    <Doc />
                    {t("portal_orders_table_menu_3")}
                  </div>
                )}
              {row.original.status !== "canceled" &&
                (row.original?.type !== "rb" || row.original.assessmentId) && (
                  <div
                    className="dropdown-item"
                    onClick={() =>
                      push(
                        `/admin/order/${row.original.id}?type=${
                          row.original?.type.includes("qc")
                            ? "qc"
                            : row.original?.type
                        }`
                      )
                    }
                  >
                    <Doc />
                    {t("portal_orders_table_menu_1")}
                  </div>
                )}
              {row.original.actionStatus === "payment_needed" &&
                row.original.status !== "canceled" &&
                row.original.status !== "request_refund" &&
                row.original.status !== "refunded" &&
                row.original.status !== "refund_rejected" && (
                  <>
                    <div
                      className="dropdown-item"
                      onClick={() =>
                        push(`/admin/payments?oid=${row.original.id}`)
                      }
                    >
                      <CreditIcon color="inherit" />
                      {t("portal_orders_table_menu_2")}
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={() =>
                        requestPayment(
                          row.original.userId,
                          row.original.userEmail,
                          sentRequestIds,
                          setSentRequestIds,
                          toast
                        )
                      }
                    >
                      <CreditIcon color="inherit" />
                      {sentRequestIds.includes(row.original.userId)
                        ? t("portal_customers_table_menu_8")
                        : t("portal_customers_table_menu_4")}
                    </div>
                  </>
                )}
              {initialState?.canSendToProduction &&
                row?.original?.status === "delivery_needed" && (
                  <div
                    className="dropdown-item"
                    style={{
                      color: !row.original?.automation?.send_to_production
                        ? "#CCC"
                        : "#3d254199",
                      cursor: !row.original?.automation?.send_to_production
                        ? "not-allowed"
                        : "pointer",
                    }}
                    onClick={() => {
                      if (row.original?.automation?.send_to_production) {
                        setSendProdData({
                          orderId: row.original.id,
                        });
                        onOpen();
                      }
                    }}
                  >
                    <SendProd color="inherit" />
                    {row?.original?.automation?.send_to_production === "initial"
                      ? t("portal_orders_table_menu_6")
                      : row?.original?.automation?.send_to_production ===
                        "update"
                      ? t(
                          "portal_orders_update_to_production",
                          "Update to production"
                        )
                      : t("portal_orders_table_menu_6")}
                  </div>
                )}
              {row?.original?.status === "delivery_needed" &&
                row?.original?.package_status === "waiting_shipment" &&
                row?.original?.hasShipment === 0 && (
                  <div
                    className="dropdown-item"
                    onClick={() =>
                      push(`/admin/orders/shipment/${row.original.id}`)
                    }
                  >
                    <CreateShipmentIcon color="inherit" />
                    Create a shipment
                  </div>
                )}
              {row?.original?.hasShipment === 1 && (
                <div
                  className="dropdown-item"
                  onClick={handleCancelShipment(row)}
                >
                  <CreateShipmentIcon color="inherit" />
                  Cancel the Shipment
                </div>
              )}
              {(row.original.status === "payment_needed" ||
                row.original.status === "questionnaire_needed") &&
                row.original.status !== "canceled" && (
                  <div
                    className="dropdown-item"
                    onClick={handleCancelOrder(row)}
                  >
                    <CrossCircle color="inherit" />
                    {t("portal_orders_table_menu_4")}
                  </div>
                )}
              {row.original.isRefund && (
                <div
                  className="dropdown-item"
                  onClick={async () => {
                    api
                      .post("updateOrder", {
                        id: row.original.id,
                        status: "request_refund",
                      })
                      .then(() => {
                        setOrders((prev) =>
                          prev.map((i) => {
                            return i.id === row.original.id
                              ? {
                                  ...i,
                                  isRefund: false,
                                  status: "request_refund",
                                }
                              : i;
                          })
                        );
                      });
                    await onRefund(
                      row.original.paymentId,
                      row.original.userId,
                      row.original.name,
                      user
                    );
                  }}
                >
                  <PlusCircle /> {t("portal_customers_table_menu_7")}
                </div>
              )}
              {(row.original.status === "request_refund" ||
                row.original.status === "refund_rejected") &&
                row.original.status !== "canceled" && (
                  <a href="mailto:hello@libi.com" className="dropdown-item">
                    <Headphones /> Contact us
                  </a>
                )}
              {row.original.actionStatus === "skip_initial" &&
                row.original.status !== "canceled" && (
                  <div
                    className="dropdown-item"
                    onClick={handleSetWaitingDiagnoseStatus(row)}
                  >
                    <CreditIcon color="inherit" />
                    {t("portal_customers_table_menu_5")}
                  </div>
                )}
              {row.original.actionStatus === "skip_weekly" &&
                row.original.status !== "canceled" && (
                  <div
                    className="dropdown-item"
                    onClick={handleSetWeeklyQuestionnaireDone(row)}
                  >
                    <CreditIcon color="inherit" />
                    {t("portal_customers_table_menu_5")}
                  </div>
                )}
              {row.original.actionStatus === "send_assessment" &&
                row.original.status !== "canceled" && (
                  <div
                    className="dropdown-item"
                    onClick={() =>
                      push(
                        `/admin/orders/send/${row.original.id}/${row.original.treatmentId}`
                      )
                    }
                  >
                    <PlusCircle /> {t("portal_customers_table_menu_6")}
                  </div>
                )}
            </TableDropdown>
          ),
        },
      ]);
    }
  );

  const handleCancelOrder = (row) => async () => {
    await centralApi.post("updateOrder", {
      id: row.original.id,
      status: "canceled",
    });
    dispatch(
      updateOrder({
        ...row.original,
        status: "canceled",
      })
    );
  };

  const handleCancelShipment = (row) => async () => {
    await centralApi.get(`shipment/${row.original.id}/cancel`);
    dispatch(
      updateOrder({
        ...row.original,
        hasShipment: 0,
        package_status: "waiting_shipment",
      })
    );
  };

  const handleSetWaitingDiagnoseStatus = (row) => async () => {
    await centralApi.post("updateUser", {
      id: row.original.userId,
      skipQuestionnaire: true,
      status: "waiting_diagnose",
    });
    updateStorageData(row.original.userId, row.original.id);
  };

  const handleSetWeeklyQuestionnaireDone = (row) => async () => {
    await centralApi.post("updateUser", {
      id: row.original.userId,
      weeklyQuestionnaireDone: true,
    });
    updateStorageData(row.original.userId, row.original.id);
  };

  const handleChangeUserQuestionnaireStatus = (row) => async () => {
    if (
      row.original.actionStatus === "skip_weekly" ||
      row.original.actionStatus === "skip_initial"
    ) {
      await centralApi.post("updateUser", {
        id: row.original.userId,
        ...(row.original.actionStatus === "skip_initial"
          ? {
              skipQuestionnaire: true,
              status: "waiting_diagnose",
            }
          : {
              weeklyQuestionnaireDone: true,
            }),
      });
      await updateStorageData(row.original.userId, row.original.id);
    }
    push(`/admin/orders/new/${row.original.userId}/${row.original.id}`);
  };

  const updateStorageData = async (userId, orderId) => {
    const { data: userData } = await centralApi.get("userByID", {
      params: { userId },
    });

    const { data: order } = await api.get("orderByID", { params: { orderId } });

    await updateStorageOrders(
      user.id,
      {
        ...userData,
        name:
          (userData?.firstName &&
            userData?.lastName &&
            `${userData?.firstName} ${userData?.lastName}`) ||
          userData?.name ||
          "-",
      },
      order,
      order.id
    );
  };

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  const fetchOrders = async () => {
    try {
      setIsLoading(true);
      const ordersArr = await getOrders(user.id);
      dispatch(setOrders(ordersArr));
    } catch (err) {
      console.log("err", err);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const getUser = async (userId) => {
    await centralApi.get("userByID", { params: { userId } }).then(async ({ data }) => {
      setCanSendToProduction(
        data?.canSendToProduction === undefined ||
          data?.canSendToProduction === null
          ? false
          : Boolean(data.canSendToProduction)
      );
    });
  };

  useEffect(() => {
    if (user.id) {
      fetchOrders();
    }
  }, [user.id]);

  useEffect(() => {
    if (user.id) {
      getUser(user.id);
    }
  }, [user.id]);

  return (
    <Flex flexDirection="column">
      <Flex alignItems={"center"} justifyContent="space-between">
        <div>
          <h2 className="title">{t("portal_orders_top_title")}</h2>
          {!isLoading && !isError && (
            <p className="descr">
              {orders?.length === 0
                ? t("portal_orders_top_description")
                : t("portal_orders_top_description_2")}
            </p>
          )}
        </div>
        {!isLoading && !isError && orders?.length === 0 && (
          <Link to="/admin/orders/new">
            <button type="button" className="custom-btn">
              {t("portal_orders_top_button")}
            </button>
          </Link>
        )}
      </Flex>

      {isLoading && (
        <Flex mx={"auto"} mt="20px">
          <Spinner color="red.700" size="xl" />
        </Flex>
      )}
      {!isLoading && isError && (
        <p className="error">{t("portal_error_message")}</p>
      )}
      {!isLoading && !isError ? (
        orders?.length ? (
          <>
            <Flex
              alignItems={{ md: "center", base: "flex-start" }}
              justifyContent="space-between"
              flexDirection={{
                base: "column",
                md: "row",
              }}
              mb={{ md: 0, base: "20px" }}
            >
              <label className="custom-input-wrapper">
                <SearchIcon color={"#3D2541"} w="18px" h="18px" />
                <input
                  type="text"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    onChange(e.target.value);
                  }}
                  placeholder={t("portal_orders_table_search_placeholder")}
                  className={"custom-input"}
                />
              </label>
              <span className="custom-link">
                <Link to="/admin/orders/new">
                  {t("portal_orders_new_link")}
                </Link>
              </span>
            </Flex>

            <div className="table table-with-action" {...getTableProps()}>
              <div className="thead">
                {headerGroups.map((headerGroup) => (
                  <div className="tr" {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <div
                        className="th"
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render("Header")}
                        <span className="table-sort">
                          {column.canSort ? (
                            <>
                              {column?.isSortedDesc && <> &#9660;</>}
                              {column?.isSortedDesc === false && <> &#9650;</>}
                              {column?.isSortedDesc === undefined && (
                                <span className="sort-arrows">
                                  <span>&#9650;</span>
                                  <span>&#9660;</span>
                                </span>
                              )}
                            </>
                          ) : null}
                        </span>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
              <div className="tbody" {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <div className="tr" {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <div className="td" {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </div>
                      ))}
                    </div>
                  );
                })}
              </div>
            </div>

            <Flex alignItems="center" justifyContent="space-between">
              <Box mt="1rem">{user.ordersIsUpdating && <DotsLoader />}</Box>

              <Pagination
                pageIndex={pageIndex}
                pageOptions={pageOptions}
                previousPage={previousPage}
                canPreviousPage={canPreviousPage}
                nextPage={nextPage}
                canNextPage={canNextPage}
              />
            </Flex>
          </>
        ) : (
          <p className="error">{t("portal_orders_empty_table")}</p>
        )
      ) : null}
      {isOpen && (
        <SendToProductionModal
          data={sendProdData}
          isOpen={isOpen}
          onClose={() => {
            onClose();
          }}
        />
      )}
    </Flex>
  );
};

export default OrdersList;
