import React from "react";
import {
  Flex,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Failed = () => {
  const { t } = useTranslation();
  const searchQuery = new URLSearchParams(location.search)

  return (
    <Flex
      h="50vh"
      direction="column"
      alignItems={"center"}
      justifyContent={'center'}
    >
      <h2 className="title">{t('portal_payment_failed_top_title')}</h2>
      <Link 
        to="/admin/payments"
        style={{
          fontSize: '15px',
          fontWeight: '500',
          color: '#92001A'
        }}
      >{t('portal_payment_failed_link_title')}</Link>
      <p 
        className="descr" 
        style={{
          textAlign: "center", 
          maxWidth: "490px",
          fontSize: '15px',
        }}
      >
        {t('portal_payment_failed_top_description')} <a href="mailto: hello@libi.com">hello@libi.com</a>
      </p>
      <p className="error-details">
        {searchQuery.get('error')}
      </p>
    </Flex>
  );
};

export default Failed;
