import { memo, useCallback } from "react";

const SortArrows = ({ sort = {}, setSort, name, title, canSort = true }) => {
  const handleSort = useCallback(() => {
    if (!canSort) return;
    if (!sort.columnName || sort.columnName !== name) {
      setSort({
        columnName: name,
        type: "ASC",
      });
    } else if (sort.type === "ASC") {
      setSort({
        columnName: name,
        type: "DESC",
      });
    } else if (sort.type === "DESC") {
      setSort({
        columnName: "",
        type: "",
      });
    }
  }, [sort, setSort, name, canSort]);

  return (
    <span
      onClick={handleSort}
      className="table-sort notifications-column-header-sort-wrapper"
    >
      <span className="notifications-column-header-sort-title">{title}</span>
      {canSort && (
        <span className="notifications-column-header-sort-arrows">
          {sort.columnName === name && sort.type === "DESC" && <>&#9660;</>}
          {sort.columnName === name && sort.type === "ASC" && <>&#9650;</>}
          {sort.columnName !== name && (
            <span className="sort-arrows">
              <span>&#9650;</span>
              <span>&#9660;</span>
            </span>
          )}
        </span>
      )}
    </span>
  );
};

export default memo(SortArrows);
