// chakra imports
import { Box, ChakraProvider, Portal } from "@chakra-ui/react";
import Footer from "components/Footer/Footer.js";
// core components
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import theme from "theme/theme.js";

import Home from "views/Static/Home/Home";
import Terms from "views/Static/Terms";

export default function StaticLayout(props) {
    return (
        <ChakraProvider theme={theme} resetCss={false} w="100%">
            <Box position={"relative"} minH="100vh">
                <Switch>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route path="/terms">
                        <Terms />
                        <Footer />
                    </Route>
                </Switch>
                {/* <div className="footer">&nbsp;</div> */}
                {/* <Box px="24px" mx="auto" width="1044px" maxW="100%"> */}
                {/* </Box>  */}
            </Box>
        </ChakraProvider>
    );
}
