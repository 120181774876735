import { memo } from "react";
import { useTranslation } from "react-i18next";

const ShipmentPreviewProductsTable = ({ products }) => {
  const { t } = useTranslation();

  if (!products || products.length === 0) {
    return null;
  }

  return (
    <>
      <hr className="hr" />
      <h2 className="shipment-preview-subtitle">
        {t("shipment.preview.product_table_title", "Products")}
      </h2>
      <div className="products-table-wrapper">
        <table className="products-table">
          <thead>
            <tr>
              <th>
                {t("shipment.preview.product_table_description", "Description")}
              </th>
              <th>{t("shipment.preview.product_table_price", "Price")}</th>
              <th>
                {t(
                  "shipment.preview.product_table_commodity_code",
                  "Commodity Code"
                )}
              </th>
              <th>
                {t(
                  "shipment.preview.product_table_shipment_references",
                  "Shipment References"
                )}
              </th>
              <th>
                {t(
                  "shipment.preview.product_table_manufacturer_country",
                  "Manufacturer Country"
                )}
              </th>
              <th>
                {t("shipment.preview.product_table_quantity", "Quantity")}
              </th>
              <th>{t("shipment.preview.product_table_weight", "Weight")}</th>
            </tr>
          </thead>
          <tbody>
            {products
              .sort(
                (a, b) =>
                  parseInt(
                    (a[1]?.ref ? a[1].ref : a[1]?.product)?.replace("p", "")
                  ) -
                  parseInt(
                    (b[1]?.ref ? b[1].ref : b[1]?.product)?.replace("p", "")
                  )
              )
              .map((product, id) => (
                <tr key={id}>
                  <td>{product.description}</td>
                  <td>{product.price}</td>
                  <td>{product.commodityCodes[0].value}</td>
                  <td>{product.customerReferences[0].value}</td>
                  <td>{product.manufacturerCountry}</td>
                  <td>{product.quantity.value}</td>
                  <td>{product.weight.netValue}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default memo(ShipmentPreviewProductsTable);
