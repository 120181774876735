import React from "react";
import ReactApexChart from "react-apexcharts";

const year = new Date().getFullYear()

const LineChart = ({ data, discount }) => {
  const yMax = Math.ceil(Math.max(...data) / 10) * 10
  const chartData = [
    {
      name: "Patients",
      data: [...data],
    },
    ...(discount ? [{
      name: "Discount",
      data: new Array(12).fill(discount),
    }] : [
      {
        name: "Tier 1",
        data: new Array(12).fill(5),
      },
      {
        name: "Tier 2",
        data: new Array(12).fill(10),
      },
      {
        name: 'Tier 3',
        data: new Array(12).fill(20),
      },
    ]
    )
  ]

  const chartOptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      enabled: false,
      theme: "light",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 2
    },
    markers: {
      size: [3, 0, 0, 0],
      colors: ['#fff', '#51BD1F', '#F9C612', '#F98012'],
      strokeColors: ['#92001A', '#51BD1F', '#F9C612', '#F98012'],
      strokeWidth: [2, 0, 0, 0],
      strokeOpacity: 1,
    },
    xaxis: {
      // type: "numeric",
      categories: [
        // `01/01/${year}`,
        // `01/02/${year}`,
        // `01/03/${year}`,
        // `01/04/${year}`,
        // `01/05/${year}`,
        // `01/06/${year}`,
        // `01/07/${year}`,
        // `01/08/${year}`,
        // `01/09/${year}`,
        // `01/10/${year}`,
        // `01/11/${year}`,
        // `01/12/${year}`,
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      labels: {
        style: {
          colors: "#3D2541",
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      floating: false,
      min: 0,
      max: yMax < 30 ? 30 : yMax,
      tickAmount: yMax < 30 ? 6 : yMax / 10,
      labels: {
        style: {
          colors: "#3D2541",
          fontSize: "12px",
        },
      },
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      position: 'top',
      horizontalAlign: 'center',
      fontSize: '16px',
      fontWeight: '500',
      inverseOrder: false,
      markers: {
        width: 10,
        height: 10,
      },
      onItemHover: {
        highlightDataSeries: false
      },
      onItemClick: {
        toggleDataSeries: false
      },
      itemMargin: {
        horizontal: 13,
        vertical: 0
      },
    },
    grid: {
      strokeDashArray: 0,
    },
    fill: {
      type: ['gradient', 'solid', 'solid', 'solid'],
      gradient: {
        shade: "light",
        type: "vertical",
        shadeIntensity: 0.8,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 0,
        stops: [],
      },
      colors: ["#92001A", "rgba(0,0,0,0)", "rgba(0,0,0,0)", "rgba(0,0,0,0)"],
    },
    colors: ['#92001A', '#51BD1F', '#F9C612', '#F98012'],
  }

  return (
    <ReactApexChart
      options={chartOptions}
      series={chartData}
      type="area"
      width="100%"
      height="100%"
    />
  );
}

export default LineChart;
