import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Spinner,
  Text,
} from "@chakra-ui/react";
import Checkbox from "components/Checkbox";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export const AddressForm = ({
  countries,
  onSendAddress,
  onAddAddress,
  onDeleteAddress,
  onSetDefaultAddress,
  isLoading,
}) => {
  const { t } = useTranslation();

  if (!countries) {
    return null;
  }

  if (isLoading) {
    return (
      <Flex justifyContent="center" alignItems="center" height="100%" mt={5}>
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <ConnectForm>
      {({ register, control, getValues, formState: { errors } }) => {
        const data = getValues();
        const dataKeys = Object.entries(data);
        const length = dataKeys.length;

        return (
          <div>
            {dataKeys.map(([key, value]) => {
              return (
                <div key={key}>
                  <Text
                    mt="46px"
                    fontSize="26px"
                    fontWeight={600}
                    color="#3D2541"
                    className="title"
                  >
                    {t("portal_customers_new_delivery_address_title") +
                      " #" +
                      parseInt(key)}
                  </Text>
                  <Flex
                    gap="38px"
                    my="24px"
                    flexDirection={{ lg: "row", sm: "column" }}
                  >
                    <FormControl isInvalid={errors?.fullname}>
                      <FormLabel fontSize={"15px"}>
                        {t("portal_customers_new_delivery_full_name_title")}
                      </FormLabel>
                      <Input
                        {...register(`${key}.fullname`, { required: true })}
                        type="text"
                        borderColor={"rgba(61, 37, 65, 0.4)"}
                        height="44px"
                        placeholder={t(
                          "portal_customers_new_delivery_full_name_placeholder"
                        )}
                      />
                      {errors?.fullname && (
                        <FormErrorMessage>
                          {errors.fullname?.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl>
                      <FormLabel fontSize={"15px"}>
                        {t("portal_customers_new_delivery_contact_phone_title")}
                      </FormLabel>
                      <Controller
                        control={control}
                        name={`${key}.phone`}
                        render={({ field: { onChange, value } }) => (
                          <PhoneInput
                            country={"gb"}
                            countryCodeEditable={false}
                            value={value}
                            onChange={(value) => {
                              onChange("+" + value);
                            }}
                            inputStyle={{
                              background: "transparent",
                              color: "#3D2541",
                              height: "44px",
                              width: "100%",
                              borderRadius: "0.375rem",
                              borderColor: "rgba(61, 37, 65, 0.4)",
                            }}
                            dropdownClass="phone-input-dropdown"
                            buttonClass="phone-input-wrapper"
                          />
                        )}
                      />
                      {errors?.phone && (
                        <FormErrorMessage>
                          {errors.phone?.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Flex>
                  <Flex
                    gap="38px"
                    mb="24px"
                    flexDirection={{ lg: "row", sm: "column" }}
                  >
                    <FormControl isInvalid={errors?.country}>
                      <FormLabel fontSize={"15px"}>
                        {t("portal_customers_new_delivery_country_title")}
                      </FormLabel>
                      <Controller
                        control={control}
                        name={`${key}.country`}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                            borderColor={"rgba(61, 37, 65, 0.4)"}
                            h="44px"
                          >
                            <option value="">
                              {t(
                                "portal_customers_new_delivery_country_placeholder"
                              )}
                            </option>
                            {countries.map((i) => (
                              <option key={i.code} value={i.code}>
                                {i.name}
                              </option>
                            ))}
                          </Select>
                        )}
                      />
                      {errors?.country && (
                        <FormErrorMessage>
                          {errors.country?.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl isInvalid={errors?.city}>
                      <FormLabel fontSize={"15px"}>
                        {t("portal_customers_new_delivery_city_title")}
                      </FormLabel>
                      <Input
                        {...register(`${key}.city`)}
                        type="text"
                        borderColor={"rgba(61, 37, 65, 0.4)"}
                        height="44px"
                        placeholder={t(
                          "portal_customers_new_delivery_city_placeholder"
                        )}
                      />
                      {errors?.city && (
                        <FormErrorMessage>
                          {errors.city?.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Flex>
                  <Flex
                    gap="38px"
                    mb="24px"
                    flexDirection={{ lg: "row", sm: "column" }}
                  >
                    <FormControl isInvalid={errors?.postalCode}>
                      <FormLabel fontSize={"15px"}>
                        {t("portal_customers_new_delivery_postcode_title")}
                      </FormLabel>
                      <Input
                        {...register(`${key}.postalCode`)}
                        type="text"
                        borderColor={"rgba(61, 37, 65, 0.4)"}
                        height="44px"
                        placeholder={t(
                          "portal_customers_new_delivery_postcode_placeholder"
                        )}
                      />
                      {errors?.postalCode && (
                        <FormErrorMessage>
                          {errors.postalCode?.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl isInvalid={errors?.state}>
                      <FormLabel fontSize={"15px"}>
                        {t(
                          "portal_customers_new_delivery_state_province_title"
                        )}
                      </FormLabel>
                      <Input
                        {...register(`${key}.state`)}
                        type="text"
                        borderColor={"rgba(61, 37, 65, 0.4)"}
                        height="44px"
                        placeholder={t(
                          "portal_customers_new_delivery_state_province_placeholder"
                        )}
                      />
                      {errors?.state && (
                        <FormErrorMessage>
                          {errors.state?.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Flex>
                  <Flex
                    gap="38px"
                    mb="30px"
                    flexDirection={{ lg: "row", sm: "column" }}
                  >
                    <FormControl isInvalid={errors?.line1}>
                      <FormLabel fontSize={"15px"}>
                        {t("portal_customers_new_delivery_street_title")}
                      </FormLabel>
                      <Input
                        {...register(`${key}.line1`)}
                        type="text"
                        borderColor={"rgba(61, 37, 65, 0.4)"}
                        height="44px"
                        placeholder={t(
                          "portal_customers_new_delivery_street_placeholder"
                        )}
                      />
                      {errors?.line1 && (
                        <FormErrorMessage>
                          {errors.line1?.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl isInvalid={errors?.line2}>
                      <FormLabel fontSize={"15px"}>
                        {t("portal_customers_new_delivery_appartment_title")}
                      </FormLabel>
                      <Input
                        {...register(`${key}.line2`)}
                        type="text"
                        borderColor={"rgba(61, 37, 65, 0.4)"}
                        height="44px"
                        placeholder={t(
                          "portal_customers_new_delivery_appartment_placeholder"
                        )}
                      />
                    </FormControl>
                  </Flex>
                  <Flex
                    flexDirection={{ lg: "row", sm: "column" }}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Flex alignItems="center" gap={2}>
                      <label key={1} className="assessment-label">
                        <Checkbox
                          checked={value?.isDefault}
                          onChange={(e) => onSetDefaultAddress(e, key)}
                          disabled={false}
                        />
                        Set as Delivery address
                      </label>
                      <Button
                        variant="unstyled"
                        style={{ marginBottom: "10px" }}
                        onClick={() => {
                          onDeleteAddress(key);
                        }}
                      >
                        Delete address
                      </Button>
                    </Flex>
                    <Flex gap={3} mb={3}>
                      {length === parseInt(key) && (
                        <button
                          disabled={false}
                          className="custom-btn"
                          onClick={onAddAddress}
                        >
                          new address
                        </button>
                      )}
                      <button
                        disabled={false}
                        className="custom-btn"
                        onClick={() => onSendAddress(key)}
                      >
                        {value?.fullname ? "update" : "save"}
                      </button>
                    </Flex>
                  </Flex>
                </div>
              );
            })}
          </div>
        );
      }}
    </ConnectForm>
  );
};

const ConnectForm = ({ children }) => {
  const methods = useFormContext();

  return children({ ...methods });
};
