import { ChakraProvider, useDisclosure, Spinner } from "@chakra-ui/react";
import Configurator from "components/Configurator/Configurator";
import Footer from "components/Footer/Footer.js";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar";
import React, { useEffect, useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import routes from "routes.js";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import theme from "theme/theme.js";
import MainPanel from "../components/Layout/MainPanel";
import PanelContainer from "../components/Layout/PanelContainer";
import PanelContent from "../components/Layout/PanelContent";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import { setUser, setUserLoggined } from "store/slices/userSlice";
import { getOrders } from "helpers";
import { getUsers } from "helpers";
import { getPayments } from "helpers";
import { getShipments } from "helpers";
import centralApi from "centralApi";

export default function Dashboard(props) {
  const { ...rest } = props;
  const dispatch = useDispatch();
  const { push } = useHistory();
  const location = useLocation();
  const auth = getAuth();

  const [access, setAccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const user = useSelector((state) => state.user);

  useEffect(() => {
    onAuthStateChanged(getAuth(), (usr) => {
      if (usr) {
        if (!usr.emailVerified) {
          push("/auth/signup");
        }

        centralApi.get("userByID", { params: { userId: usr.uid } }).then((res) => {
          const userObj = res.data;
          if (userObj && userObj.agreementSigned) {
            if (userObj.roleId != 3 && userObj.roleId != 2) {
              setErrorMessage("Error: Wrong user role");
            }
            if (userObj.approved) {
              setAccess(true);
              const swithedUser = JSON.parse(
                localStorage.getItem("switched_user")
              );
              let newUser = swithedUser;
              if (!swithedUser) {
                newUser = {
                  email: userObj.email,
                  id: usr.uid,
                  originalId: usr.uid,
                  avatar: userObj?.picture,
                  name: userObj?.firstName + " " + userObj?.lastName,
                  roleId: userObj.roleId,
                  canEditOrder: userObj?.canEditOrder,
                };
              }
              dispatch(setUser(newUser));
              dispatch(setUserLoggined());
            } else {
              push("/auth/signup");
            }
          } else {
            push("/auth/signup");
          }
        });
      } else {
        push("/auth/signup");
      }
    });
  }, []);

  // useEffect(() => {
  //   const userIsActive = user.id && user.authorized;
  //   if (userIsActive && location.pathname === "/admin/orders") {
  //     const storageOrders = JSON.parse(
  //       localStorage.getItem(`orders_${user.id}`)
  //     );

  //     if (storageOrders && storageOrders?.length) {
  //       getOrders(user.id);
  //     }
  //   }
  //   if (userIsActive && location.pathname === "/admin/customers") {
  //     const storageUsers = JSON.parse(localStorage.getItem(`users_${user.id}`));

  //     if (storageUsers && storageUsers?.length) {
  //       getUsers(user.id);
  //     }
  //   }
  //   if (userIsActive && location.pathname === "/admin/payments") {
  //     const storagePayments = JSON.parse(
  //       localStorage.getItem(`payments_${user.id}`)
  //     );

  //     if (storagePayments) {
  //       getPayments(user.id);
  //     }
  //   }
  //   if (userIsActive && location.pathname === "/admin/shipment") {
  //     getShipments(user.id);
  //   }
  // }, [user.id, user.authorized, location.pathname]);

  // states and functions
  const [sidebarVariant, setSidebarVariant] = useState("transparent");
  const [fixed, setFixed] = useState(false);
  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };

  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].views);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  // This changes navbar state(fixed or not)
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].views);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          if (routes[i].secondaryNavbar) {
            return routes[i].secondaryNavbar;
          }
        }
      }
    }
    return activeNavbar;
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.category === "account") {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            exact
          />
        );
      } else {
        return null;
      }
    });
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  //console.log("isOpen", isOpen);
  // Chakra Color Mode
  return (
    <>
      {" "}
      {access ? (
        <>
          <ChakraProvider theme={theme} resetCss={false}>
            {user.id !== user.originalId && (
              <div className="switched-user">
                Under {user.name} {user.email}
              </div>
            )}
            {
              <Sidebar
                routes={routes}
                logoText={"L&D Experts Portal"}
                display="none"
                sidebarVariant={sidebarVariant}
                {...rest}
              />
            }

            {
              <MainPanel
                w={{
                  base: "100%",
                  xl: "calc(100% - 250px)",
                  sm: "calc(100% - 50px)",
                }}
                style={{
                  background: "rgba(255,255,255,0.8)",
                  minHeight: "99vh",
                }}
              >
                {getRoute() ? (
                  <PanelContent style={{ padding: 0 }}>
                    <PanelContainer
                      padding={{
                        md: "46px 46px 110px",
                        base: "46px 20px 220px",
                      }}
                    >
                      <AdminNavbar
                        onOpen={onOpen}
                        logoText={"L&D Experts Portal"}
                        brandText={getActiveRoute(routes)}
                        secondary={getActiveNavbar(routes)}
                        fixed={fixed}
                        {...rest}
                      />
                      <Switch>
                        {getRoutes(routes)}
                        <Redirect from="/admin" to="/admin/experts" />
                      </Switch>
                    </PanelContainer>
                  </PanelContent>
                ) : null}
                <Footer />
                {/* <Portal>
                                    <FixedPlugin
                                        secondary={getActiveNavbar(routes)}
                                        fixed={fixed}
                                        onOpen={onOpen}
                                    />
                                </Portal> */}
                <Configurator
                  secondary={getActiveNavbar(routes)}
                  isOpen={isOpen}
                  onClose={onClose}
                  isChecked={fixed}
                  onSwitch={(value) => {
                    setFixed(value);
                  }}
                  onOpaque={() => setSidebarVariant("opaque")}
                  onTransparent={() => setSidebarVariant("transparent")}
                />
              </MainPanel>
            }
          </ChakraProvider>
        </>
      ) : (
        <>
          <div className="spinner-loader">
            {/* <Skeleton /> */}
            {errorMessage ? (
              <p>{errorMessage}</p>
            ) : (
              <Spinner color="red.700" size="xl" />
            )}
          </div>
        </>
      )}
    </>
  );
}
