import React from 'react'
import s from '../Home.module.scss'
import img from '../../../../assets/img/model.jpeg'
import {ReactComponent as Arrow} from '../../../../assets/svg/model-arrow.svg'

const data = [
  {
    title: "Prescribe Libi & Daughters' personalized skincare routines",
    text: "Evaluate patients' skin needs and prescribe a customized Libi & Daughters skincare routine to enhance treatment efficacy."
  },
  {
    title: "Libi ensures direct product delivery to patients' homes",
    text: "We handle the delivery of prescribed skincare products to patients' homes, ensuring a streamlined and prompt process."
  },
  {
    title: "Earn commissions and revenue shares from patient purchases",
    text: "As a partner, receive a commission for each prescribed skincare routine and a revenue share for subsequent purchases by patients you introduced to Libi & Daughters within their first year"
  },
]

const Model = () => {
  return (
    <div id="model" className={s.model}>
      <div className={s.container}>
        <div className={s.modelWrapper}>
          <img src={img} alt="Picture" className={s.modelImg} />

          <div>
            <h2 className={s.modelTitle}>The Libi & Daughters Partnership Model</h2>
            <p className={s.modelSubtitle}>Boost Your Revenue Stream and Enhance Patient Care</p>
            <ul className={s.modelList}>
              {data.map((i, index) => (
                <li key={index} className={s.modelListItem}>
                  <div className={s.modelItemNum}>{index + 1}.</div>
                  <div className={s.modelItemArrow}>
                    <Arrow className={s.modelItemArrowIcon} />
                  </div>
                  <h3 className={s.modelItemTitle}>{i.title}</h3>
                  <p className={s.modelItemText}>{i.text}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Model