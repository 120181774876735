import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
};

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setOrders(state, action) {
      state.list = action.payload;
    },
    updateOrder(state, action) {
      state.list = state.list.map((order) => {
        if (order.id === action.payload.id) {
          return {
            ...order,
            ...action.payload,
          };
        }
        return order;
      });
    },
  },
});

export const { setOrders, updateOrder } = ordersSlice.actions;

export default ordersSlice.reducer;
