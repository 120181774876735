import React from 'react'
import s from '../Home.module.scss'
import img from '../../../../assets/img/home-laptop.png'
import {ReactComponent as Check} from '../../../../assets/svg/home-check.svg'

const data = [
  {
    title: "Automate routine creation",
    text: "Leverage the portal's automated routine creation tools to efficiently develop customized skincare regimens for your patients."
  },
  {
    title: "Manage payments",
    text: "Easily collect payments for your services and keep track of your earnings through the portal's streamlined financial management features."
  },
  {
    title: "Monitor and report affiliate revenue",
    text: "Stay updated on your affiliate revenue, commissions, and treatment processes with comprehensive reporting tools available on the portal."
  },
]

const Portal = () => {
  return (
    <div id="portal" className={s.portal}>
      <div className={s.container}>
        <div className={s.portalWrapper}>
          <div className={s.portalMain}>
            <h2 className={s.portalTitle}>Streamline Your Practice with Our Partner Portal</h2>
            <p className={s.portalText}>The Libi & Daughters Partners' Portal provides a user-friendly platform for dermatologists to manage various aspects of their practice efficiently. With our portal, you can:</p>
            <ul className={s.portalList}>
              {data.map((i, index) => (
                <li key={index} className={s.portalListItem}>
                  <Check className={s.portalItemIcon} />
                  <h3 className={s.portalItemTitle}>{i.title}</h3>
                  <p className={s.portalItemText}>{i.text}</p>
                </li>
              ))}
            </ul>
          </div>
          <img src={img} alt="Laptop picture" className={s.portalImg} />
        </div>
      </div>
    </div>
  )
}

export default Portal