import React, { useEffect, useState } from "react";
import TemplateComponent from "react-mustache-template-component";
import { getDoc, doc } from "firebase/firestore";
import { db } from "../../firebase";
import moment from "moment";

function Terms() {
    const [date, setDate] = useState(null);
    const [htmlFull, setHtmlFull] = useState("");
    const [template, setTemplate] = useState(null);

    useEffect(() => {
        const docRef = doc(db, "Meta", "Terms");
        getDoc(docRef).then((snap) => {
            if (snap.exists()) {
                let data = snap.data();
                setHtmlFull(data.htmlFull);
                setDate({ date: moment().format("dddd, MMMM Do YYYY") });
                console.log(date);
                setTemplate(data.html);
            }
        });
    }, []);

    return (
        <>
            <TemplateComponent template={template} data={date} />
        </>
    );
}

export default Terms;
