import { SearchIcon } from '@chakra-ui/icons';
import { Flex } from '@chakra-ui/layout';
import { Spinner } from '@chakra-ui/spinner';
import { Doc } from 'components/Icons/Icons';
import TableDropdown from 'components/TableDropdown';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useAsyncDebounce, useFlexLayout, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { getAuth, onAuthStateChanged } from '@firebase/auth';
import Pagination from 'components/Pagination';
import { Link } from 'react-router-dom';
import centralApi from "centralApi";;

const Experts = () => {
  const { t } = useTranslation();
  const { push } = useHistory()

  const [experts, setExperts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const [search, setSearch] = useState("");

  const columns = useMemo(() => [
    {
      Header: t('portal_experts_table_column_1_title'),
      accessor: 'created',
      Cell: ({ value }) => value 
        ? <span title={moment(value).format("HH:mm MMM DD YYYY")}>
            {moment(value).format("DD/MM/YYYY")}
          </span> 
        : "-",
      width: 70,
      maxWidth: 70,
      minWidth: 70,
      sortType: (a, b) => {
        if (new Date(a.values.created).getTime() < new Date(b.values.created).getTime()) {
          return -1;
        } else if (new Date(a.values.created).getTime() > new Date(b.values.created).getTime()) {
          return 1;
        };

        return 0;
      },

    },
    {
      Header: t('portal_experts_table_column_2_title'),
      accessor: 'name',
      Cell: ({row, value}) => <Link to={`/auth/experts/${row.original.id}`}><u>{value}</u></Link>
    },
    {
      Header: t('portal_experts_table_column_3_title'),
      accessor: 'email',
    },
  ], [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setGlobalFilter,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data: experts,
      initialState: {pageSize: 10}
    },
    useFlexLayout,
    useGlobalFilter,
    useSortBy,
    usePagination,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        ...columns,
        {
          id: "action",
          Header: () => "Manage",
          Cell: ({ row }) => (
            <TableDropdown>
              <div
                className="dropdown-item"
                onClick={() =>
                  push(
                    `/auth/experts/${row.original.id}`
                  )
                }
              >
                <Doc /> {t('portal_experts_table_menu_1')}
              </div>
            </TableDropdown>
          ),
        },
      ]);
    }
  );

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  const fetchExperts = async () => {
    try {
      setIsLoading(true)

      const expertsArr = []

      const {
        data: { list: experts },
      } = await centralApi.get("expertsList");

      experts.forEach(i => {
        expertsArr.push({
          ...i,
          name:
            ((i?.firstName && i?.lastName) && `${i?.firstName} ${i?.lastName}`) ||
            i?.name || '-',
        })
      })
      setExperts(expertsArr.sort((a, b) => new Date(a?.created).getTime() > new Date(b?.created).getTime() ? -1 : 1)
      )
    } catch (err) {
      console.log('err', err)
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }

  onAuthStateChanged(getAuth(), (user) => {
    centralApi.get('userByID', {params: {userId: user.uid}}).then(res => {
      if (res.data?.roleId != 2) {
        push('/admin/dashboard')
      }
    })
  });

  useEffect(() => {
    fetchExperts()
  }, [])

  return (
    <Flex flexDirection="column">
      <Flex alignItems={"center"} justifyContent="space-between">
        <h2 className="title">{t('portal_dashboard_top_title')}</h2>
      </Flex>

      {isLoading && (
        <Flex mx={"auto"} mt="20px">
          <Spinner color="red.700" size="xl" />
        </Flex>
      )}
      {!isLoading && isError && (
        <p className="error">{t('portal_error_message')}</p>
      )}
      {!isLoading && !isError ? (
        experts?.length ? (
          <>
            <Flex
              alignItems={"center"}
              justifyContent="space-between"
            >
              <label className="custom-input-wrapper">
                <SearchIcon
                  color={"#3D2541"}
                  w="18px"
                  h="18px"
                />
                <input
                  type="text"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    onChange(e.target.value);
                  }}
                  placeholder={t('portal_experts_table_search_placeholder')}
                  className={"custom-input"}
                />
              </label>
            </Flex>

            <div
              className="table table-with-action"
              {...getTableProps()}
            >
              <div className="thead">
                {headerGroups.map((headerGroup) => (
                  <div
                    className="tr"
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column) => (
                      <div
                        className="th"
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render("Header")}
                        <span className="table-sort">
                          {column.canSort ? (
                            <>
                              {column?.isSortedDesc && <> &#9660;</>}
                              {column?.isSortedDesc === false && <> &#9650;</>}
                              {column?.isSortedDesc === undefined && (
                                <span className='sort-arrows'>
                                  <span>&#9650;</span>
                                  <span>&#9660;</span>
                                </span>
                              )}
                            </>
                          ) : null}
                        </span>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
              <div className="tbody" {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <div
                      className="tr"
                      {...row.getRowProps()}
                    >
                      {row.cells.map((cell) => (
                        <div
                          className="td"
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </div>
                      ))}
                    </div>
                  );
                })}
              </div>
            </div>

            <Pagination 
              pageIndex={pageIndex}
              pageOptions={pageOptions}
              previousPage={previousPage}
              canPreviousPage={canPreviousPage}
              nextPage={nextPage}
              canNextPage={canNextPage}
            />
          </>
        ) : (
          <p className="error">{t('portal_customers_view_empty')}</p>
        )
      ) : null}
    </Flex>
  )
}

export default Experts