import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  discount: null,
  rbDiscount: null,
  qcADiscount: null,
  qcBDiscount: null,
  userCurrency: null,
};

const discountSlice = createSlice({
  name: "discount",
  initialState,
  reducers: {
    setDiscount(state, action) {
      state.discount = action.payload;
    },
    setAllDiscounts(state, action) {
      state = action.payload;
    },
  },
});

export const { setDiscount, setAllDiscounts } = discountSlice.actions;

export default discountSlice.reducer;
