import React, { useMemo, useState } from "react";
import {
  Flex,
  Spinner,
  Text,
  Box
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { useAsyncDebounce, useFlexLayout, useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import { SearchIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Pagination from "components/Pagination";
import { getShipments } from "helpers";
import DotsLoader from "components/DotsLoader";
import api from "api";

const getTrackingLink = async (number) => {
  await api.get(`s/tracking/${number}`).then((res) => {
    window.open(res.data, "_blank");
  });
};

const statuses = {
  waiting_shipment: "Waiting Confirmation",
  waiting_shipment_confirmed: "To be shipped",
  shipped: "Transit",
  delivered: "Delivered",
  confirmed: "Delivered",
};

const Shipment = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);

  const [shipment, setShipment] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const [search, setSearch] = useState("");

  const columns = useMemo(() => [
    {
      Header: t('portal_shipments_table_column_1_title'),
      accessor: 'ref',
      Cell: (value) => value ? (
        <Link to={
          `/admin/order/${value.cell.row.original.orderId}${(value.cell.row.original.type === 'qcA' || value.cell.row.original.type === 'qcB')
            ? '?type=qc'
            : value.cell.row.original.type === 'ds'
              ? '?type=ds'
              : value.cell.row.original.type === 'se'
                ? '?type=se'
                : value.cell.row.original.type === 'st'
                  ? '?type=st'
                  : value.cell.row.original.type === 'ip'
                    ? '?type=ip'
                    : '?type=rb&tab=products'
          }`
        }>
          <u>{value.cell.value?.replace('LD', '')}</u>
        </Link>
      ) : '-',
      width: 110,
      maxWidth: 110,
      minWidth: 110,
    },
    {
      Header: t('portal_shipments_table_column_2_title'),
      accessor: "created",
      Cell: ({ value }) => {
        return value ? (
          <span title={moment(value).format("HH:mm MMM DD YYYY")}>
            {value ? moment(value).format("DD/MM/YYYY") : "-"}
          </span>
        ) : '-'
      },
      width: 90,
      maxWidth: 90,
      minWidth: 90,
      sortType: (a, b) => {
        if (new Date(a.values.created).getTime() < new Date(b.values.created).getTime()) {
          return -1;
        } else if (new Date(a.values.created).getTime() > new Date(b.values.created).getTime()) {
          return 1;
        };

        return 0;
      },

    },
    {
      Header: t('portal_shipments_table_column_3_title'),
      accessor: "status",
      Cell: ({ value }) => value ? (
        <span
          title={value}
          style={{
            whiteSpace: 'nowrap',
            color: (value == 'delivered' || value == 'confirmed') ? '#219653' : '#DF710C'
          }}
        >{statuses[value] || '-'}</span>
      ) : '-',
    },
    {
      Header: t('portal_shipments_table_column_4_title'),
      accessor: "type",
      Cell: ({ value }) => value ? value.toUpperCase() : '-',
      width: 70,
      maxWidth: 70,
      minWidth: 70,
    },
    {
      Header: t('portal_shipments_table_column_5_title'),
      accessor: "userName",
      Cell: (value) => {
        return value
          ? <Link to={`/admin/customers/${value.cell.row.original.userId}`}><u>{value.cell.value}</u></Link>
          : '-'
      }
    },
    {
      Header: t('portal_shipments_table_column_6_title'),
      accessor: "trackingNumber",
      Cell: ({ value }) =>
        value ? (
          <Text
            color="blue.400"
            cursor="pointer"
            textDecoration="underline"
            onClick={() => getTrackingLink(value)}
          >
            {t('portal_shipments_table_menu_1')}
          </Text>
        ) : (
          "-"
        ),
      width: 70,
      maxWidth: 70,
      minWidth: 70,
    },
  ], []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex },
    setGlobalFilter
  } = useTable(
    {
      columns,
      data: shipment,
      initialState: { pageSize: 10 }
    },
    useFlexLayout,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const shipmentArr = await getShipments(user.id);
      if(shipmentArr && shipmentArr.length) {
        setShipment(shipmentArr);
      }
    } catch (err) {
      console.log("err", err);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (user.id) {
      fetchData();
    }
  }, [user.id]);

  return (
    <Flex direction="column">
      <h2 className="title">{t('portal_shipments_top_title')}</h2>
      <p className="descr">{t('portal_shipments_top_description')}</p>

      {isLoading && (
        <Flex mx={"auto"} mt="20px">
          <Spinner color="red.700" size="xl" />
        </Flex>
      )}
      {!isLoading && isError && (
        <p className="error">{t('portal_error_message')}</p>
      )}
      {!isLoading && !isError ? (
        shipment?.length ? (
          <>
            <label className="custom-input-wrapper">
              <SearchIcon color={'#3D2541'} w="18px" h="18px" />
              <input
                type="text"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  onChange(e.target.value);
                }}
                placeholder={t('portal_shipments_table_search_placeholder')}
                className={'custom-input'}
              />
            </label>
            <div
              className="table"
              {...getTableProps()}
            >
              <div className="thead">
                {headerGroups.map((headerGroup) => (
                  <div className="tr" {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <div
                        className="th"
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render("Header")}
                        <span className="table-sort">
                          {column.canSort ? (
                            <>
                              {column?.isSortedDesc && <> &#9660;</>}
                              {column?.isSortedDesc === false && <> &#9650;</>}
                              {column?.isSortedDesc === undefined && (
                                <span className='sort-arrows'>
                                  <span>&#9650;</span>
                                  <span>&#9660;</span>
                                </span>
                              )}
                            </>
                          ) : null}
                        </span>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
              <div className="tbody" {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <div className="tr" {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <div className="td" {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </div>
                      ))}
                    </div>
                  );
                })}
              </div>
            </div>

            <Flex alignItems="center" justifyContent="space-between">
              <Box mt="1rem">
                {user.shipmentsIsUpdating && <DotsLoader />}
              </Box>
              <Pagination
                pageIndex={pageIndex}
                pageOptions={pageOptions}
                previousPage={previousPage}
                canPreviousPage={canPreviousPage}
                nextPage={nextPage}
                canNextPage={canNextPage}
              />
            </Flex>
          </>
        ) : (
          <p className="error">{t('portal_shipments_empty_table')}</p>
        )
      ) : null}
    </Flex>
  );
};

export default Shipment;
