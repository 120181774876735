import React, { useEffect } from "react";
import { getAuth, signOut } from "firebase/auth";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { removeUser } from "store/slices/userSlice";
// Chakra imports
import {
    Box,
    Flex,
    Heading,
    Link,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
// Assets
import signInImage from "assets/img/products.webp";
import LibiLogo from "assets/img/Libi.logo.webp";
import { Link as NavLink } from "react-router-dom";

function SignOut() {
    const dispatch = useDispatch();
    const { push } = useHistory();

    const handleLogOut = () => {
        const auth = getAuth();
        signOut(auth)
            .then(() => {
                dispatch(removeUser());
                push("/auth/signin");
            })
            .catch((error) => console.log(error));
    };
    useEffect(() => {
        handleLogOut();
    }, []);

    // Chakra color mode
    const titleColor = useColorModeValue(
        "rgb(139, 16, 38)",
        "rgb(139, 16, 38)"
    );
    const textColor = useColorModeValue("gray.400", "white");
    return (
        <Flex position="relative" mb="40px">
            <Flex
                h={{ sm: "initial", md: "75vh", lg: "85vh" }}
                w="100%"
                maxW="1044px"
                mx="auto"
                justifyContent="space-between"
                mb="30px"
                pt={{ sm: "100px", md: "0px" }}
            >
                <Flex
                    alignItems="center"
                    justifyContent="start"
                    style={{ userSelect: "none" }}
                    w={{ base: "100%", md: "50%", lg: "42%" }}
                >
                    <Flex
                        direction="column"
                        w="100%"
                        background="transparent"
                        p="48px"
                        mt={{ md: "150px", lg: "80px" }}
                    >
                        <Link as={NavLink} to="/admin/dashboard">
                            <Box
                                bgImage={LibiLogo}
                                w="133px"
                                h="100px"
                                bgSize="cover"
                            ></Box>
                        </Link>

                        <Heading color={titleColor} fontSize="32px" mb="10px">
                            Welcome Back
                        </Heading>
                        <Text
                            mb="36px"
                            ms="4px"
                            color={textColor}
                            fontWeight="bold"
                            fontSize="14px"
                        >
                            signed out
                        </Text>
                    </Flex>
                </Flex>
                <Box
                    display={{ base: "none", md: "block" }}
                    overflowX="hidden"
                    h="100%"
                    w="40vw"
                    position="absolute"
                    right="0px"
                >
                    <Box
                        bgImage={signInImage}
                        w="100%"
                        h="100%"
                        bgSize="cover"
                        bgPosition="50%"
                        position="absolute"
                        borderBottomLeftRadius="20px"
                    ></Box>
                </Box>
            </Flex>
        </Flex>
    );
}

export default SignOut;
