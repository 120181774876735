import React, { useState } from 'react'
import s from '../Home.module.scss'
import classNames from 'classnames'
import img1 from '../../../../assets/img/how-1.jpeg'
import img2 from '../../../../assets/img/how-2.jpeg'
import img3 from '../../../../assets/img/how-3.jpeg'
import img4 from '../../../../assets/img/how-4.jpeg'
import img5 from '../../../../assets/img/how-5.jpeg'

const data = [
  {
    title: "Offer tailored skincare routines",
    text: "Our digital assessment and tracking system ensures that each patient receives a personalized skincare routine tailored to their specific skin concerns, leading to better outcomes and patient satisfaction.",
    img: img1
  },
  {
    title: "Enhance patient engagement",
    text: "Our technology-driven approach allows for continuous monitoring and adjustment of skincare routines, fostering patient engagement and commitment to their skincare journey.",
    img: img2
  },
  {
    title: "Address various skin conditions",
    text: "Libi & Daughters' products cater to a wide range of skin types and conditions, ensuring that each patient receives the best possible care for their unique needs.",
    img: img3
  },
  {
    title: "Improve patient loyalty",
    text: "By providing exceptional results through personalized skincare, your patients are more likely to remain loyal to your practice and continue seeking your expertise for their skincare needs.",
    img: img4
  },
  {
    title: "Support patients' long-term skincare goals",
    text: "Our partnership enables you to guide your patients throughout their skincare journey, helping them maintain healthy and radiant skin for years to come.",
    img: img5
  },
]

const HowCan = () => {
  const [currentIndex, setCurrentIndex] = useState(0)

  return (
    <div id="how-can" className={s.how}>
      <div className={s.container}>
        <h2 className={s.sectionTitle}><span>How can we enhance your professional capabilities?</span></h2>
        <p className={s.sectionSubtitle}>Strengthening your dermatology practice with our partnership</p>
      </div>

      <div className={s.howWrapper}>
        {data.map((i, index) => (
          <div className={s.howItemWrapper}>
            <div
              key={index}
              className={classNames(s.howItem, { [s.howItemActive]: index === currentIndex })}
              onMouseEnter={() => setCurrentIndex(index)}
              onClick={() => setCurrentIndex(index)}
            >
              <h3 className={s.howItemTitle}>{i.title}</h3>
              <p className={s.howItemText}>{i.text}</p>
            </div>

            <div className={classNames(s.howImgWrapper, { [s.howImgWrapperActive]: index === currentIndex })}>
              <img
                src={i.img}
                alt="Picture"
                className={s.howImg}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default HowCan