export default {
  UA: 0.2,
  FR: 0.2,
  ES: 0.21,
  DE: 0.19,
  GB: 0.2,
  CH: 0.08,
  HU: 0.27,
  AD: 0.2,
  AT: 0.2,
  BE: 0.21,
  BG: 0.2,
  HR: 0.25,
  CY: 0.19,
  FI: 0.24,
  IL: 0.17,
  MC: 0.2,
  SK: 0.2,
  GR: 0.24,
  IT: 0.22,
  CZ: 0.21,
  DK: 0.25,
  EE: 0.2,
  IS: 0.24,
  IE: 0.23,
  LV: 0.21,
  LI: 0.08,
  LT: 0.21,
  LU: 0.17,
  MT: 0.18,
  NL: 0.21,
  NO: 0.25,
  PL: 0.23,
  PT: 0.23,
  RO: 0.19,
  SM: 0.2,
  SI: 0.22,
  SE: 0.25,
  VA: 0.2,
  US: 0.088,
  AE: 0.05,
};