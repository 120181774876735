import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
};

const customersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    setCustomers(state, action) {
      state.list = action.payload;
    },
    updateCustomer(state, action) {
      state.list = state.list.map((customer) => {
        if (customer.id === action.payload.id) {
          return {
            ...customer,
            ...action.payload,
          };
        }
        return customer;
      });
    },
  },
});

export const { setCustomers, updateCustomer } = customersSlice.actions;

export default customersSlice.reducer;
