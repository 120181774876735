import React, { useState } from "react";
import {
    getAuth,
    signInWithEmailAndPassword,
    onAuthStateChanged,
} from "firebase/auth";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setUser } from "store/slices/userSlice";
// Chakra imports
import {
    Box,
    Flex,
    Button,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Link,
    Switch,
    Text,
    useColorModeValue,
    Image,
    Center,
} from "@chakra-ui/react";
// Assets
import signInImage from "assets/img/libisignin.png";
import LibiLogo from "assets/img/libiLogoBlack.png";
import { Link as NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import centralApi from "centralApi";

function SignIn(props) {
    const { t } = useTranslation()
    const [email, setEmail] = useState("");
    const [emailAuth, setEmailAuth] = useState("");
    const [password, setPassword] = useState("");
    const [errorResponse, setErrorResponse] = useState("");
    const [auth, setAuth] = useState(getAuth());

    onAuthStateChanged(getAuth(), (user) => {
        setAuth(user);
        if (user && user.email) {
            setEmailAuth(user.email);
        }
    });

    const dispatch = useDispatch();
    const { push } = useHistory();

    const getDocumentSigned = async () => {
        const user = auth.currentUser;
        if (user) {
            try {
                const { data: document } = await centralApi.get('userByID', { params: { userId: user.uid } })
                return document && document.agreementSign
                    ? "signed"
                    : "unsigned";
            } catch (ex) {
                return ex.message;
            }
        }
        return "unsigned";
    };

    const handleLogin = (email, password) => {
        const auth = getAuth();
        
        signInWithEmailAndPassword(auth, email, password)
            .then(async ({ user }) => {
                dispatch(
                    setUser({
                        email: user.email,
                        id: user.uid,
                        originalId: user.uid,
                        avatar: user?.picture,
                        name: user?.firstName + ' ' + user?.lastName,
                        roleId: user.roleId,
                        canEditOrder: user?.canEditOrder,
                    })
                );
                if (!auth.currentUser.emailVerified) {
                    push("/auth/signup");
                    console.log(!auth?.currentUser?.emailVerified);
                } else {
                    const isSigned = await getDocumentSigned();
                    console.log("signed?", isSigned);
                    if (isSigned === "signed") {
                        push("/admin/experts");
                    } else if (isSigned === "unsigned") {
                        push("/auth/signup");
                    } else {
                        setErrorResponse(isSigned);
                    }
                }
            })
            .catch((error) => setErrorResponse(error.code));
    };

    const titleColor = useColorModeValue(
        "rgb(139, 16, 38)",
        "rgb(139, 16, 38)"
    );

    return (
        <Flex position="relative" mb="40px" bg="#FEF8F4">
            <Flex
                h={{ sm: "initial", md: "75vh", lg: "85vh" }}
                w="100%"
                maxW="1044px"
                mx="auto"
                justifyContent="space-between"
                mb="30px"
                pt={{ sm: "100px", md: "0px" }}
            >
                <Flex
                    alignItems="center"
                    justifyContent="start"
                    style={{ userSelect: "none" }}
                    w={{ base: "100%", md: "50%", lg: "42%" }}
                >
                    <Flex
                        direction="column"
                        w="100%"
                        background="transparent"
                        p="48px"
                        mt={{ md: "150px", lg: "80px" }}
                    >
                        <Center>
                            <Link as={NavLink} to="/admin/dashboard">
                                <Box
                                    bgImage={LibiLogo}
                                    w="113px"
                                    h="85px"
                                    bgSize="cover"
                                    alignItems="center"
                                ></Box>
                            </Link>
                        </Center>
                        {!auth && !auth?.currentUser ? (
                            <>
                                <Center>
                                    <Heading
                                        color={titleColor}
                                        fontSize="32px"
                                        mb="4px"
                                        mt="10px"
                                        fontFamily="Cormorant Garamond"
                                        fontWeight="400"
                                    >
                                        {t('portal_signin_top_title', 'Welcome')}
                                    </Heading>
                                </Center>
                                <Center>
                                    <Text
                                        mb="24px"
                                        ms="4px"
                                        color="#3D2541"
                                        fontWeight="400"
                                        fontSize="15px"
                                        fontStyle="normal"
                                        letterSpacing="0.5px"
                                    >
                                        {t('portal_signin_top_description', 'Enter your email and password to Sign in')}
                                    </Text>
                                </Center>
                                <FormControl>
                                    <FormLabel
                                        ms="4px"
                                        fontSize="15px"
                                        fontWeight="600"
                                        color="#3D2541"
                                        fontFamily="Montserrat"
                                    >
                                        {t('portal_signin_email_title', 'Email *')}
                                    </FormLabel>
                                    <Input
                                        borderRadius="15px"
                                        mb="24px"
                                        fontSize="sm"
                                        type="text"
                                        placeholder={t('portal_signin_email_placeholder', 'Your email address')}
                                        size="lg"
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        borderColor="#3D2541"
                                        _placeholder={{
                                            opacity: 0.7,
                                            color: "#3D2541",
                                        }}
                                    />
                                    <FormLabel
                                        ms="4px"
                                        fontSize="15px"
                                        fontWeight="600"
                                        color="#3D2541"
                                        fontFamily="Montserrat"
                                    >
                                        {t('portal_signin_password_title', 'Password *')}
                                    </FormLabel>
                                    <Input
                                        borderRadius="15px"
                                        fontSize="sm"
                                        type="password"
                                        placeholder={t('portal_signin_password_placeholder', 'Your password')}
                                        size="lg"
                                        value={password}
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                        borderColor="#3D2541"
                                        _placeholder={{
                                            opacity: 0.7,
                                            color: "#3D2541",
                                        }}
                                    />
                                    <Text
                                        mb="10px"
                                        ms="4px"
                                        color="red"
                                        fontWeight="bold"
                                        fontSize="14px"
                                    >
                                        {errorResponse}
                                    </Text>
                                    <button
                                        type="button"
                                        onClick={() => handleLogin(email, password)}
                                        className="custom-btn"
                                        style={{ width: '100%', margin: '24px auto' }}
                                    >
                                        {t('portal_signin_button', 'SIGN IN')}
                                    </button>
                                </FormControl>
                                <Flex
                                    flexDirection="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    maxW="100%"
                                    mt="0px"
                                >
                                    <Text
                                        fontWeight="400"
                                        fontSize="16px"
                                        color="#3D2541"
                                        mb="15px"
                                    >
                                        {t('portal_signin_no_account_title', "Don't have an account?")}
                                        <Link
                                            color={titleColor}
                                            ms="5px"
                                            as={NavLink}
                                            to="/auth/signup/"
                                            fontWeight="600"
                                            textDecorationLine="underline"
                                        >
                                            {t('portal_signin_no_account_link', 'Sign Up')}
                                        </Link>
                                    </Text>
                                    <Text
                                        fontWeight="400"
                                        fontSize="16px"
                                        color="#3D2541"
                                    >
                                        {t('portal_signin_forgot_pw_title', 'Forgot password?')}
                                        <Link
                                            color={titleColor}
                                            ms="5px"
                                            as={NavLink}
                                            to="/auth/forgot"
                                            fontWeight="600"
                                            textDecorationLine="underline"
                                        >
                                            {t('portal_signin_forgot_pw_link', 'Reset')}
                                        </Link>
                                    </Text>
                                </Flex>
                            </>
                        ) : (
                            <>
                                <h2 className="title" style={{ margin: '8px auto 6px' }}>Welcome</h2>
                                <Link
                                    fontSize={15}
                                    color="red.700"
                                    href="/auth/signout"
                                    textAlign={"center"}
                                >
                                    {t('portal_signin_signout_from', 'Sign Out from')}
                                </Link>{' '}
                                <Text my="24px" textAlign={"center"} fontSize={15}>
                                    {emailAuth}
                                </Text>
                                <Link href="/admin/experts" className="custom-btn" color={'#fff'}>
                                    {t('portal_signin_or_link', 'Continue')}
                                </Link>
                            </>
                        )}
                    </Flex>
                </Flex>
                <Box
                    display={{ base: "none", md: "block" }}
                    overflowX="hidden"
                    h="115%"
                    w="50%"
                    position="absolute"
                    right="0px"
                    bg="white"
                >
                    <Box
                        bgImage={signInImage}
                        w="100%"
                        h="70%"
                        bgSize="cover"
                        bgPosition="50%"
                        position="absolute"
                        // borderBottomLeftRadius="20px"
                        mt="25%"
                    ></Box>
                </Box>
            </Flex>
        </Flex>
    );
}

export default SignIn;
