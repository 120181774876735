import React, { useState } from 'react'
import s from '../Home.module.scss'
import { useForm } from 'react-hook-form';
import { FormControl, FormErrorMessage, FormLabel, Input, Text } from '@chakra-ui/react';
import { getAuth, onAuthStateChanged } from '@firebase/auth';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import centralApi from "centralApi";;

const requiredStr = yup.string().required("This field is required")

const schema = yup.object().shape({
  firstName: requiredStr,
  email: yup
    .string()
    .email("Invalid E-mail")
    .required("This field is required"),
  phone: requiredStr
})

const Form = () => {
  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: yupResolver(schema)
  });

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [isSuccess, setIsSuccess] = useState(false)

  const onSubmit = (data) => {
    if (isLoading || isSuccess) return

    const callback = async (user) => {
      setIsLoading(true)
      try {
        if (!user) {
          await centralApi.post(`s/create_user_for_crm`, data)
        } else {
          // await centralApi.get(`${CRM_URL}/s/crmadd/31000012299/${user?.uid}`)
        }
        reset()
        setIsSuccess(true)
        setTimeout(() => {
          setIsSuccess(false)
        }, 3000)
      } catch (err) {
        console.log('err', err)
        setError(err?.response?.data?.error)
      } finally {
        setIsLoading(false)
      }
    }

    onAuthStateChanged(getAuth(), callback)
  }

  return (
    <div id="join" className={s.form}>
      <div className={s.container}>
        <div className={s.formWrapper}>
          <h2 className={s.sectionTitle}><span>Join the Libi & Daughters</span></h2>

          <form onSubmit={handleSubmit(onSubmit)} className={s.formForm}>
            <FormControl isInvalid={errors?.firstName} mb="30px">
              <FormLabel className={s.formLabel}>Name *</FormLabel>
              <Input
                {...register("firstName")}
                type="text"
                placeholder="First name"
                className={s.formInput}
              />
              {errors?.firstName && (
                <FormErrorMessage>
                  {errors.firstName?.message}
                </FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={errors?.email} mb="30px">
              <FormLabel className={s.formLabel}>Email *</FormLabel>
              <Input
                {...register("email")}
                type="text"
                placeholder="Email address"
                className={s.formInput}
              />
              {errors?.email && (
                <FormErrorMessage>
                  {errors.email?.message}
                </FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={errors?.phone} mb="30px">
              <FormLabel className={s.formLabel}>Phone number*</FormLabel>
              <Input
                {...register("phone")}
                type="text"
                placeholder="Phone number"
                className={s.formInput}
              />
              <span className={s.formText}>Phone number in the international format</span>
              {errors?.phone && (
                <FormErrorMessage>
                  {errors.phone?.message}
                </FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={errors?.clinicName} mb="30px">
              <FormLabel className={s.formLabel}>Clinic name</FormLabel>
              <Input
                {...register("clinicName")}
                type="text"
                placeholder="Clinic name"
                className={s.formInput}
              />
              {errors?.clinicName && (
                <FormErrorMessage>
                  {errors.clinicName?.message}
                </FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={errors?.clinicLocation} mb="30px">
              <FormLabel className={s.formLabel}>Clinic location</FormLabel>
              <Input
                {...register("clinicLocation")}
                type="text"
                placeholder="Clinic location"
                className={s.formInput}
              />
              {errors?.clinicLocation && (
                <FormErrorMessage>
                  {errors.clinicLocation?.message}
                </FormErrorMessage>
              )}
            </FormControl>

            {error &&
              <Text fontSize={12} color={'red'}>{error}</Text>
            }

            <button
              className={s.formBtn}
              type="submit"
              disabled={isLoading || isSuccess}
            >
              {isSuccess ? 'Thank you' : 'Submit'}
            </button>
            <p className={s.formText} style={{ textAlign: 'center' }}>By clicking submit you acknowledge to our <a href="https://libi.com/policies/privacy-policy">Privacy Policy</a></p>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Form