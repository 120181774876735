import { useEffect, useState, memo, useCallback, useRef } from "react";
import { Flex, Text, Box } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setNotificationCount } from "store/slices/notificationSlice";

import centralApi from "centralApi";
import NotificationTable from "./NotificationTable";

const ITEMS_ON_PAGE = 15;

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const t = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(t);
    };
  }, [value, delay]);
  return debouncedValue;
};

const useGetNotifications = () => {
  const [notificationList, setNotificationList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [curPage, setCurPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);

  const fetchNotificationList = async ({
    sort,
    search,
    status,
    dateFrom,
    dateTo,
    page,
  }) => {
    setIsLoading(true);

    try {
      const filters = {};
      if (status !== "ALL") {
        filters.status = status;
      }
      if (search.length) {
        filters.search = search;
      }
      if (dateFrom) {
        filters.from = dateFrom;
      }
      if (dateTo) {
        filters.to = dateTo;
      }
      const requestBody = {
        rowsPerPage: ITEMS_ON_PAGE,
        page: (page ?? curPage) + 1,
        filters,
      };
      if (sort.columnName) {
        requestBody.sortColumn = sort.columnName;
        requestBody.sortOrder = sort.type;
      }
      const { data } = await centralApi.post("notifications/list", requestBody);
      setNotificationList(data.list);
      if (page !== undefined) {
        setCurPage(page);
      }
      setPageCount(Math.ceil(data.count / ITEMS_ON_PAGE));
    } catch (err) {
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  };

  const previousPage = useCallback(() => {
    setCurPage((prev) => prev - 1);
  }, []);

  const nextPage = useCallback(() => {
    setCurPage((prev) => prev + 1);
  }, []);

  return {
    curPage,
    pageCount,
    previousPage,
    nextPage,
    notificationList,
    fetchNotificationList,
    isNotificationListLoading: isLoading,
    setNotificationList,
  };
};

const Notifications = () => {
  const [sort, setSort] = useState({
    columnName: "",
    type: "",
    mounted: true,
  });
  const [status, setStatus] = useState("ALL");
  const [search, setSearch] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");

  const dispatch = useDispatch();
  const debouncedSearchTerm = useDebounce(search, 1000);
  const {
    notificationList,
    setNotificationList,
    isNotificationListLoading,
    curPage,
    pageCount,
    previousPage,
    nextPage,
    fetchNotificationList,
  } = useGetNotifications();

  useEffect(() => {
    fetchNotificationList({ sort, search, status, dateFrom, dateTo, page: 0 });
  }, [status, dateFrom, dateTo]);

  useEffect(() => {
    if (sort.mounted) return;
    fetchNotificationList({ sort, search, status, dateFrom, dateTo });
  }, [sort]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      fetchNotificationList({
        sort,
        search,
        status,
        dateFrom,
        dateTo,
        page: 0,
      });
    }
  }, [debouncedSearchTerm, sort, status, dateFrom, dateTo]);

  const handleSearch = useCallback(
    (e) => {
      setSearch(e.target.value);
    },
    [setSearch]
  );

  const handleStatusChange = useCallback(
    (status) => () => {
      setStatus(status);
    },
    [setStatus]
  );

  const handleDateFrom = useCallback(
    (e) => {
      setDateFrom(e.target.value);
    },
    [setDateFrom]
  );

  const handleDateTo = useCallback(
    (e) => {
      setDateTo(e.target.value);
    },
    [setDateTo]
  );

  const handleNextPage = useCallback(() => {
    fetchNotificationList({
      sort,
      search,
      status,
      dateFrom,
      dateTo,
      page: curPage + 1,
    });
    nextPage();
  }, [
    fetchNotificationList,
    sort,
    search,
    status,
    dateFrom,
    dateTo,
    curPage,
    nextPage,
  ]);

  const handlePrevPage = useCallback(() => {
    fetchNotificationList({
      sort,
      search,
      status,
      dateFrom,
      dateTo,
      page: curPage - 1,
    });
    previousPage();
  }, [
    fetchNotificationList,
    sort,
    search,
    status,
    dateFrom,
    dateTo,
    curPage,
    previousPage,
  ]);

  const markAllAsRead = useCallback(async () => {
    try {
      if (confirm("Do you really want to mark all as read?")) {
        const { data } = await centralApi.post("notifications/read", {});
        dispatch(setNotificationCount(0));
        setNotificationList((prevList) =>
          prevList.map((not) => ({
            ...not,
            status: "READ",
          }))
        );
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  const { t } = useTranslation();

  return (
    <>
      <div>
        <h2 className="title">
          {t("portal_notifications_top_title", "Notifications")}
        </h2>
      </div>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        flexDirection="row"
        mb="20px"
      >
        <label className="custom-input-wrapper">
          <SearchIcon color={"#3D2541"} w="18px" h="18px" />
          <input
            type="text"
            value={search}
            onChange={handleSearch}
            placeholder={t(
              "portal_notifications_table_search_placeholder",
              "Search Notifications"
            )}
            className={"custom-input"}
          />
        </label>
        <span className="custom-link">
          <Text onClick={markAllAsRead}>
            {t("portal_notifications_mark_all_as_read", "Mark all as read")}
          </Text>
        </span>
      </Flex>

      <Flex
        alignItems={{ md: "center", base: "flex-start" }}
        justifyContent="space-between"
        flexDirection={{
          base: "column",
          md: "row",
        }}
        mb={"20px"}
        gap={"20px"}
      >
        <Flex gap="38px" flexDirection={{ lg: "row", sm: "column" }}>
          <Box textAlign={"center"}>
            <div className="af-tabs">
              <div
                className={`title af-tab ${
                  status === "ALL" && "af-tab-active"
                }`}
                onClick={handleStatusChange("ALL")}
              >
                {t("portal_notifications_status_all", "ALL")}
              </div>
              <div
                className={`title af-tab ${
                  status === "NEW" && "af-tab-active"
                }`}
                onClick={handleStatusChange("NEW")}
              >
                {t("portal_notifications_status_new", "NEW")}
              </div>
              <div
                className={`title af-tab ${
                  status === "READ" && "af-tab-active"
                }`}
                onClick={handleStatusChange("READ")}
              >
                {t("portal_notifications_status_read", "READ")}
              </div>
            </div>
          </Box>
        </Flex>

        <Flex
          alignItems={"center"}
          justifyContent="space-between"
          flexDirection={{
            base: "column",
            md: "row",
          }}
          gap={"8px"}
        >
          <input
            className="custom-input custom-input-date"
            type="date"
            value={dateFrom}
            onChange={handleDateFrom}
          />
          <span>-</span>
          <input
            className="custom-input custom-input-date"
            type="date"
            value={dateTo}
            onChange={handleDateTo}
          />
        </Flex>
      </Flex>

      <NotificationTable
        notifications={notificationList}
        isNotificationListLoading={isNotificationListLoading}
        setNotificationList={setNotificationList}
        sort={sort}
        setSort={setSort}
        pageOptions={{
          pageIndex: curPage,
          pageOptions: pageCount,
          previousPage: handlePrevPage,
          canPreviousPage: curPage > 0,
          nextPage: handleNextPage,
          canNextPage: curPage + 1 < pageCount,
        }}
      />
    </>
  );
};

export default memo(Notifications);
