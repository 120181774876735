import HomeHeader from "./components/HomeHeader/HomeHeader";
import s from './Home.module.scss'
import Doctor from "./sections/Doctor";
import Hero from "./sections/Hero";
import HowCan from "./sections/HowCan";
import Why from "./sections/Why";
import Model from "./sections/Model";
import Portal from "./sections/Portal";
import Results from "./sections/Results";
import Form from "./sections/Form";
import HomeFooter from "./components/HomeFooter/HomeFooter";

function Home() {
  return (
    <>
      <HomeHeader />
      <main className={s.main}>
        <Hero />
        <Doctor />
        <HowCan />
        <Why />
        <Model />
        <Portal />
        <Results />
        <Form />
      </main>
      <HomeFooter />
    </>
  );
}

export default Home;
