import React from 'react'
import { Check } from 'components/Icons/Icons'

const Checkbox = (props) => {
  return (
    <div className="custom-checkbox">
      <input
        {...props}
        type="checkbox"
      />
      <span>
        <Check />
      </span>
    </div>
  )
}

export default Checkbox