import {
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Link,
  Text,
  useColorModeValue,
  Center,
  Heading,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { setDoc, doc, serverTimestamp, collection, getDoc } from "firebase/firestore";
import { CRM_URL, db } from "../../firebase";
import { setUser } from "store/slices/userSlice";
import { Link as NavLink } from "react-router-dom";
import LibiLogo from "assets/img/libiLogoBlack.png";
import signupImage from "assets/img/libisignup.png";
import { useTranslation } from "react-i18next";
import centralApi from "centralApi";
import api from "api";
import { useHistory } from "react-router-dom";

export default function ChooseEmail() {
  const { t } = useTranslation()

  const { push } = useHistory()
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorResponse, setErrorResponse] = useState("");

  const handleRegister = async () => {
    const auth = getAuth()

    try {
      setIsLoading(true)

      const { user } = await createUserWithEmailAndPassword(auth, email, password)
      console.log('handleRegister', user)

      dispatch(setUser({
        email,
        id: user.uid,
        originalId: user.uid,
        avatar: '',
        name: firstName + ' ' + lastName,
        roleId: 3,
        canEditOrder: false
      }))

      const docR = await api.post('createUser', {
        id: user.uid,
        email,
        firstName: firstName,
        lastName: lastName,
        roleId: 3,
        rbAvailability: 1,
        qcAvailability: 1,
        ipAvailability: 0,
        rbDiscount: 5,
        qcADiscount: 5,
        qcBDiscount: 5,
        canEditOrder: 0
      })

      console.log("User object written: ", user.uid, docR);

      await sendEmailVerification(user)
      // await axios.get(`${CRM_URL}/s/crmadd/31000021531/${user.uid}`)

      const emails = []
      const { data: admins } = await centralApi.get('getAdmins')

      admins.forEach(data => {
        if (data?.emails_permissions?.length) {
          const keys = data.emails_permissions.map(i => i?.key)
          if (keys.includes('new_partner_registered')) {
            emails.push(data.email)
          }
        }
      })

      let body = ''
      let subject = ''

      const metaRef = doc(db, 'Meta', 'notifications_txt')
      await getDoc(metaRef).then(metaDoc => {
        if (metaDoc.exists()) {
          body = metaDoc.data().email_new_partner_registered_body.replace('[[userId]]', user.uid)
          subject = metaDoc.data().email_new_partner_registered_subtitle
        }
      })

      const emailsRef = doc(collection(db, 'Emails'))
      await setDoc(emailsRef, {
        body,
        id: emailsRef.id,
        recipients: emails.join(', '),
        send: serverTimestamp(),
        sent: false,
        subject
      })

      push('/auth/email-confirmation')
    } catch (error) {
      console.log("Error", error)
      setErrorResponse(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  const titleColor = useColorModeValue(
    "rgb(139, 16, 38)",
    "rgb(139, 16, 38)"
  )

  return (
    <Flex position="relative" mb="40px" bg="#FEF8F4">
      <Flex
        h={{ sm: "initial", md: "75vh", lg: "115vh" }}
        // h="100%"
        w="100%"
        maxW="1044px"
        mx="auto"
        justifyContent="space-between"
        mb="30px"
        pt={{ sm: "100px", md: "0px" }}
      >
        <Flex
          alignItems="center"
          justifyContent="start"
          style={{ userSelect: "none" }}
          w={{ base: "100%", md: "50%", lg: "42%" }}
        >
          <Flex
            direction="column"
            w="100%"
            background="transparent"
            p="48px"
            mt={{ md: "150px", lg: "80px" }}
          >
            <Center>
              <Link as={NavLink} to="/admin/dashboard">
                <Box
                  bgImage={LibiLogo}
                  w="150px"
                  h="125px"
                  bgSize="cover"
                  alignItems="center"
                  mt="155px"
                ></Box>
              </Link>
            </Center>

            <Center>
              <Heading
                color={titleColor}
                fontSize="32px"
                mb="8px"
                fontFamily="Cormorant Garamond"
                fontWeight="400"
              >
                {t('portal_signup_top_title', 'Welcome')}
              </Heading>
            </Center>
            <Center>
              <Text
                mt="6px"
                mb="24px"
                ms="4px"
                color="#3D2541"
                fontWeight="400"
                fontSize="20px"
                fontStyle="normal"
                letterSpacing="0.5px"
                textAlign="center"
              >
                {t('portal_signup_top_description', 'Please enter your name, email and password in the fields below')}
              </Text>
            </Center>
            <FormControl>
              <FormLabel
                ms="4px"
                fontSize="15px"
                fontWeight="600"
                color="#3D2541"
                fontFamily="Montserrat"
              >
                {t('portal_signup_first_name_title', 'First name *')}
              </FormLabel>
              <Input
                borderRadius="15px"
                mb="24px"
                fontSize="sm"
                type="text"
                placeholder={t('portal_signup_first_name_placeholder', 'Your first name')}
                size="lg"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                borderColor="#3D2541"
                _placeholder={{
                  opacity: 0.7,
                  color: "#3D2541",
                }}
              />
              <FormLabel
                ms="4px"
                fontSize="15px"
                fontWeight="600"
                color="#3D2541"
                fontFamily="Montserrat"
              >
                {t('portal_signup_last_name_title', 'Last name *')}
              </FormLabel>
              <Input
                borderRadius="15px"
                mb="24px"
                fontSize="sm"
                type="text"
                placeholder={t('portal_signup_last_name_placeholder', 'Your last name')}
                size="lg"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                borderColor="#3D2541"
                _placeholder={{
                  opacity: 0.7,
                  color: "#3D2541",
                }}
              />
              <FormLabel
                ms="4px"
                fontSize="15px"
                fontWeight="600"
                color="#3D2541"
                fontFamily="Montserrat"
              >
                {t('portal_signup_email_title', 'Email *')}
              </FormLabel>
              <Input
                fontSize="sm"
                ms="4px"
                borderRadius="15px"
                type="email"
                placeholder={t('portal_signup_email_placeholder', 'Your email address')}
                mb="24px"
                size="lg"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                borderColor="#3D2541"
                _placeholder={{
                  opacity: 0.7,
                  color: "#3D2541",
                }}
              />
              <FormLabel
                ms="4px"
                fontSize="15px"
                fontWeight="600"
                color="#3D2541"
                fontFamily="Montserrat"
              >
                {t('portal_signup_password_title', 'Password *')}
              </FormLabel>
              <Input
                fontSize="15px"
                fontFamily="Montserrat"
                fontStyle="normal"
                fontWeight="400"
                ms="4px"
                borderRadius="10px"
                type="password"
                placeholder={t('portal_signup_password_placeholder', 'Your password')}
                mb="24px"
                size="lg"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                borderColor=""
                border="0.5px solid"
                _placeholder={{
                  opacity: 0.7,
                  color: "#3D2541",
                }}
              />
              <Checkbox defaultChecked>
                <span
                  style={{
                    fontWeight: "400",
                    color: "#3D2541",
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                  }}
                >
                  {t('portal_signup_checkbox_title', 'Agree with')}&nbsp;
                </span>
                <a
                  style={{
                    textDecoration: "underline",
                    color: "rgb(139, 16, 38)",
                    fontWeight: "bold",
                  }}
                  href="https://libi.com/policies/privacy-policy"
                >
                  {t('portal_signup_checkbox_link', 'Privacy Policy')}
                </a>
              </Checkbox>

              <Text
                ms="4px"
                color="red"
                fontWeight="bold"
                fontSize="14px"
                mt="10px"
              >
                {errorResponse}
              </Text>

              <button
                type="button"
                onClick={handleRegister}
                disabled={isLoading}
                className="custom-btn"
                style={{width: '100%', margin: '24px auto'}}
              >
                {isLoading ? 'Loading...' : t('portal_signup_button', 'SIGN UP')}
              </button>
            </FormControl>
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              maxW="100%"
              mt="0px"
            >
              <Text
                fontFamily="Montserrat"
                fontStyle="normal"
                fontWeight="400"
                fontSize="16px"
              >
                {t('portal_signup_have_an_account_title', 'Already have an account?')}
                <Link
                  textDecorationLine="underline"
                  color="#92001A"
                  fontSize="16px"
                  ms="5px"
                  as={NavLink}
                  to="/auth/signin"
                  fontWeight="600"
                >
                  {t('portal_signup_have_an_account_link', 'Sign In')}
                </Link>
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Box
          display={{ base: "none", md: "block" }}
          overflowX="hidden"
          h="115%"
          w="50%"
          position="absolute"
          right="0px"
          bg="white"
        >
          <Box
            bgImage={signupImage}
            w="100%"
            h="60%"
            bgSize="cover"
            bgPosition="50%"
            position="absolute"
            // borderBottomLeftRadius="20px"
            mt="25%"
          ></Box>
        </Box>
      </Flex>
    </Flex>
  );
}
