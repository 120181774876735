import { memo, useCallback, useMemo } from "react";
import {
  Flex,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  TableCaption,
  Spinner,
  Tooltip,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { setNotificationCount } from "store/slices/notificationSlice";
import centralApi from "centralApi";
import { Doc, ClockIcon } from "components/Icons/Icons";
import TableDropdown from "components/TableDropdown";
import Pagination from "components/Pagination";
import NotificationTableSortArrows from "./NotificationTableSortArrows";

const Tabel = ({
  notifications,
  isNotificationListLoading,
  setNotificationList,
  sort,
  setSort,
  pageOptions,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { count } = useSelector(state => state.notification);

  const markAsRead = useCallback(
    (id) => () => {
      centralApi
        .post(`notifications/${id}/read`, {})
        .then((res) => {
          dispatch(setNotificationCount(count - 1));
          setNotificationList((prev) =>
            prev.map((not) => {
              if (not.id !== id) {
                return not;
              } else {
                return {
                  ...not,
                  status: "READ",
                };
              }
            })
          );
        })
        .catch((err) => console.log(err));
    },
    []
  );

  return (
    <Box position="relative">
      {isNotificationListLoading && (
        <Box className="notifications-spinner-wrapper" backgroundColor="white">
          <Spinner
            color="red.700"
            size="xl"
            className="notifications-spinner"
          />
        </Box>
      )}
      <TableContainer>
        <Table variant="unstyled" as="div" className="notifications-table">
          <Thead as="div" className="notifications-table-block">
            <Tr as="div" className="notifications-table-row">
              <Th
                as="div"
                padding="0px 8px"
                className="notifications-table-cell-created"
              >
                <Flex alignItems="center">
                  <NotificationTableSortArrows
                    title={t(
                      "portal_notifications_table_column_1_title",
                      "Created"
                    )}
                    name="createdAt"
                    sort={sort}
                    setSort={setSort}
                  />
                </Flex>
              </Th>
              <Th
                as="div"
                padding="0px 8px"
                className="notifications-table-cell-status"
              >
                <Flex alignItems="center">
                  <NotificationTableSortArrows
                    title={t(
                      "portal_notifications_table_column_2_title",
                      "Status"
                    )}
                    name="status"
                    sort={sort}
                    setSort={setSort}
                  />
                </Flex>
              </Th>
              <Th
                as="div"
                padding="0px 8px"
                className="notifications-table-cell-message"
              >
                <Flex alignItems="center">
                  <NotificationTableSortArrows
                    title={t(
                      "portal_notifications_table_column_3_title",
                      "Message"
                    )}
                    name="message"
                    sort={sort}
                    setSort={setSort}
                    canSort={false}
                  />
                </Flex>
              </Th>
              <Th
                as="div"
                padding="0px 8px"
                className="notifications-table-cell-version"
              >
                <Flex alignItems="center">
                  <NotificationTableSortArrows
                    title={t(
                      "portal_notifications_table_column_4_title",
                      "Version"
                    )}
                    name="version"
                    sort={sort}
                    setSort={setSort}
                    canSort={false}
                  />
                </Flex>
              </Th>
              <Th
                as="div"
                padding="0px 8px"
                className="notifications-table-cell-customer"
              >
                <Flex alignItems="center">
                  <NotificationTableSortArrows
                    title={t(
                      "portal_notifications_table_column_5_title",
                      "Customer"
                    )}
                    name="customer"
                    sort={sort}
                    setSort={setSort}
                    canSort={false}
                  />
                </Flex>
              </Th>
              <Th
                as="div"
                padding="0px 8px"
                className="notifications-table-cell-source"
              >
                <Flex alignItems="center">
                  <NotificationTableSortArrows
                    title={t(
                      "portal_notifications_table_column_6_title",
                      "Source"
                    )}
                    name="source"
                    sort={sort}
                    setSort={setSort}
                  />
                </Flex>
              </Th>
              <Th
                as="div"
                padding="0px 8px"
                className="notifications-table-cell-manage"
              >
                <Flex alignItems="center" justifyContent="right">
                  <NotificationTableSortArrows
                    title={t(
                      "portal_notifications_table_column_7_title",
                      "Manage"
                    )}
                    name="manage"
                    sort={sort}
                    setSort={setSort}
                    canSort={false}
                  />
                </Flex>
              </Th>
            </Tr>
          </Thead>
          <Tbody as="div" className="notifications-table-block">
            {notifications?.length ? (
              notifications.map((notif) => (
                <Tr
                  key={notif.id}
                  as="div"
                  className="notifications-table-row notification-table-row-border"
                >
                  <Td
                    textDecoration="underline"
                    as="div"
                    padding="0px 8px"
                    className="notifications-table-cell notifications-table-cell-created"
                  >
                    <Tooltip
                      label={moment(notif.createdAt).format(
                        "HH:mm a DD/MM/YYYY"
                      )}
                      hasArrow
                      bg="white"
                      color="black"
                      border="1px solid rgba(61, 37, 65, 0.2)"
                      boxShadow="0px 20px 13px rgba(41, 72, 152, 0.025), 0px 8.14815px 6.51852px rgba(41, 72, 152, 0.0196296), 0px 1.85185px 3.14815px rgba(41, 72, 152, 0.012037)"
                      padding="8px 12px"
                      placement="top-end"
                    >
                      {moment(notif.createdAt).format("HH:mm a DD/MM/YYYY")}
                    </Tooltip>
                  </Td>
                  <Td
                    textTransform="uppercase"
                    as="div"
                    padding="0px 8px"
                    className="notifications-table-cell notifications-table-cell-status"
                  >
                    {notif.status}
                  </Td>
                  <Td
                    fontWeight="bold"
                    as="div"
                    padding="0px 8px"
                    className="notifications-table-cell notifications-table-cell-message"
                  >
                    <Tooltip
                      label={notif.message}
                      hasArrow
                      bg="white"
                      color="black"
                      border="1px solid rgba(61, 37, 65, 0.2)"
                      boxShadow="0px 20px 13px rgba(41, 72, 152, 0.025), 0px 8.14815px 6.51852px rgba(41, 72, 152, 0.0196296), 0px 1.85185px 3.14815px rgba(41, 72, 152, 0.012037)"
                      padding="8px 12px"
                      placement="top-end"
                    >
                      {notif.message}
                    </Tooltip>
                  </Td>
                  <Td
                    fontWeight="bold"
                    as="div"
                    padding="0px 8px"
                    className="notifications-table-cell notifications-table-cell-version"
                  >
                    {notif.version}
                  </Td>
                  <Td
                    as="div"
                    padding="0px 8px"
                    className="notifications-table-cell notifications-table-cell-customer"
                  >
                    {notif.client ? (
                      <Link
                        textDecoration="underline"
                        to={`/admin/customers/${notif.targetId}`}
                      >
                        {notif.client}
                      </Link>
                    ) : (
                      "-"
                    )}
                  </Td>
                  <Td
                    textTransform="capitalize"
                    as="div"
                    padding="0px 8px"
                    className="notifications-table-cell notifications-table-cell-source"
                  >
                    {notif.source}
                  </Td>
                  <Td
                    as="div"
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    padding="0px 8px"
                    className="notifications-table-cell-manage"
                  >
                    <TableDropdown>
                      <div className="dropdown-item">
                        <Doc />
                        <a href={notif.sourceLink} target="_blank">
                          {t(
                            "portal_notifications_table_menu_1",
                            "View sourse"
                          )}
                        </a>
                      </div>
                      {notif.status === "NEW" && (
                        <div
                          className="dropdown-item"
                          onClick={markAsRead(notif.id)}
                        >
                          <ClockIcon />
                          {t(
                            "portal_notifications_table_menu_2",
                            "Mark as read"
                          )}
                        </div>
                      )}
                    </TableDropdown>
                  </Td>
                </Tr>
              ))
            ) : (
              <></>
            )}
          </Tbody>
          <TableCaption as="div">
            <Pagination {...pageOptions} />
          </TableCaption>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default memo(Tabel);
