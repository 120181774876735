import React from 'react'
import s from '../Home.module.scss'
import {ReactComponent as Logo} from '../../../../assets/svg/libi-logo-white.svg'
import img from '../../../../assets/img/pattern.png'

const Hero = () => {
  return (
    <div className={s.hero}>
      <img src={img} alt="" className={s.heroImg} />
      <img src={img} alt="" className={s.heroImg2} />

      <div className={s.container}>
        <div className={s.heroWrapper}>
          <Logo />
          <h1 className={s.heroTitle}>Join the Libi & Daughters Family</h1>
          <p className={s.heroText}>We are skincare brand fostering meaningful relationships with professional dermatologists, providing individualized routines that boost your practice and achieve extraordinary patient outcomes.</p>
          <a href="#join" className={s.heroBtn}>Join</a>
        </div>
      </div>
    </div>
  )
}

export default Hero