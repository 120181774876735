import React from 'react'
import s from '../Home.module.scss'

const data = [
  {
    title: "250 years experience",
    text: "Drawing from our family's accumulated skincare knowledge passed down through generations, we offer unique and effective solutions for healthy, radiant skin."
  },
  {
    title: "Compatibility with clinical treatments",
    text: "Our products seamlessly integrate with existing clinical treatments, ensuring comprehensive care and optimal results for your patients."
  },
  {
    title: "Embracing innovation",
    text: "With our digital assessment and tracking system, we provide personalized, data-driven skincare solutions, ensuring high-quality care for your patients."
  },
  {
    title: "Additional revenue stream",
    text: "Our partnership model generates income from commissions on patient product sales and revenue share for additional sets purchased by patients you introduce to Libi within the first year."
  },
  {
    title: "Proven efficacy",
    text: "Tested by J.S. Hamilton lab, our products show real improvements in even severe skin conditions."
  },
]

const Why = () => {
  return (
    <div id="why" className={s.why}>
      <div className={s.container}>
        <h2 className={s.sectionTitle}><span>Why Choose Us?</span></h2>
        <p className={s.sectionSubtitle}>Advantages of Collaborating with Libi & Daughters</p>

        <div className={s.whyWrapper}>
          {data.map((i, index) => (
            <div key={index} className={s.whyItem}>
              <h3 className={s.whyItemTitle}>{i.title}</h3>
              <p className={s.whyItemText}>{i.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Why