import React, { useRef, useState } from 'react'
import useOnClickOutside from 'hooks/useOnClickOutside'
import s from './index.module.scss'
import { Dots } from 'components/Icons/Icons'
import { useTranslation } from 'react-i18next'

const TableDropdown = ({children}) => {
  const { t } = useTranslation();
  const ref = useRef(null)
  const [isVisible, setIsVisible] = useState(false)

  const hideHandler = () => {
    setIsVisible(false)
  }

  useOnClickOutside(ref, hideHandler)

  return (
    <div ref={ref} className={s.wrapper}>
      <div 
        className={s.icon}
        onClick={() => setIsVisible(prev => !prev)}
        style={{zIndex: isVisible ? 7 : 5}}
      >
        <Dots />
      </div>

      {isVisible &&
        <div className={s.dropdown}>
          <span className={s.dropdownTitle}>{t('portal_customers_table_menu_title')}</span>
          {children}
        </div>
      }
    </div>
  )
}

export default TableDropdown