import React, { useEffect, useState } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
// Chakra imports
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Link,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
// Assets
import signInImage from "assets/img/products.webp";
import LibiLogo from "assets/img/Libi.logo.webp";
import { Link as NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

function SignOut() {
    const { t } = useTranslation();
    const [email, setEmail] = useState("");
    const [errorResponse, setErrorResponse] = useState("");
    const [isLoading, setIsLoading] = useState(false)
    const [sent, setSent] = useState(false)

    const handleResetPass = () => {
        setIsLoading(true)
        const auth = getAuth();
        sendPasswordResetEmail(auth, email)
            .then(() => {
                setSent(true)
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorMessage);
                console.log(errorCode);
                setErrorResponse(errorCode);
            })
            .finally(() => setIsLoading(false))
    };

    // Chakra color mode
    const titleColor = useColorModeValue(
        "rgb(139, 16, 38)",
        "rgb(139, 16, 38)"
    );
    const textColor = useColorModeValue("gray.400", "white");
    return (
        <Flex position="relative" mb="40px">
            <Flex
                h={{ sm: "initial", md: "75vh", lg: "85vh" }}
                w="100%"
                maxW="1044px"
                mx="auto"
                justifyContent="space-between"
                mb="30px"
                pt={{ sm: "100px", md: "0px" }}
            >
                <Flex
                    alignItems="center"
                    justifyContent="start"
                    style={{ userSelect: "none" }}
                    w={{ base: "100%", md: "50%", lg: "42%" }}
                >
                    <Flex
                        direction="column"
                        w="100%"
                        background="transparent"
                        p="48px"
                        mt={{ md: "150px", lg: "80px" }}
                    >
                        <Link as={NavLink} to="/admin/dashboard">
                            <Box
                                bgImage={LibiLogo}
                                w="133px"
                                h="100px"
                                bgSize="cover"
                            ></Box>
                        </Link>

                        <Heading color={titleColor} fontSize="32px" mb="10px">
                            {t('portal_forgot_pw_top_title')}
                        </Heading>
                        <Text
                            mb="36px"
                            ms="4px"
                            color={textColor}
                            fontWeight="bold"
                            fontSize="14px"
                        >
                            {t('portal_forgot_pw_top_description')}
                        </Text>
                        {sent ? (
                            <Text
                                mb="36px"
                                fontSize="18px"
                            >
                                {t('portal_check_email')}
                            </Text>
                        ) : (
                            <FormControl>
                                <FormLabel
                                    ms="4px"
                                    fontSize="sm"
                                    fontWeight="normal"
                                >
                                    {t('portal_forgot_pw_email_title')}
                                </FormLabel>
                                <Input
                                    borderRadius="15px"
                                    mb="24px"
                                    fontSize="sm"
                                    type="text"
                                    placeholder={t('portal_forgot_pw_email_placeholder')}
                                    size="lg"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <Text
                                    mb="10px"
                                    ms="4px"
                                    color="red"
                                    fontWeight="bold"
                                    fontSize="14px"
                                >
                                    {errorResponse}
                                </Text>
                                <button
                                    type="button"
                                    className="custom-btn"
                                    style={{ width: '100%', marginBottom: '24px' }}
                                    onClick={() => handleResetPass()}
                                    disabled={isLoading}
                                >
                                    {isLoading ? t('portal_loading') : t('portal_forgot_pw_button')}
                                </button>
                            </FormControl>
                        )}
                        <Text color={textColor} fontWeight="medium">
                            {t('portal_forgot_pw_know_pw_title')}
                            <Link
                                color={titleColor}
                                ms="5px"
                                as={NavLink}
                                to="/auth/signin/"
                                fontWeight="bold"
                            >
                                {t('portal_forgot_pw_know_pw_link')}
                            </Link>
                        </Text>
                    </Flex>
                </Flex>
                <Box
                    display={{ base: "none", md: "block" }}
                    overflowX="hidden"
                    h="100%"
                    w="40vw"
                    position="absolute"
                    right="0px"
                >
                    <Box
                        bgImage={signInImage}
                        w="100%"
                        h="100%"
                        bgSize="cover"
                        bgPosition="50%"
                        position="absolute"
                        borderBottomLeftRadius="20px"
                    ></Box>
                </Box>
            </Flex>
        </Flex>
    );
}

export default SignOut;
