import { mode } from "@chakra-ui/theme-tools"

export const globalStyles = {
    colors: {
        gray: {
            700: "#3D2541",
        },
        red: {
            500: '#92001a'
        }
    },
    styles: {
        global: (props) => ({
            body: {
                bg: mode("#FEF8F4", "#FEF8F4")(props),
                fontFamily: "'Montserrat', sans-serif",
            },
            html: {
                fontFamily: "'Montserrat', sans-serif",
            },
        }),
    },
};
