// import
import SignOut from "views/Auth/Logout"
import SignIn from "views/Auth/SignIn.js"
import SignUp from "views/Auth/SignUp.js"
import Dashboard from "views/Dashboard/Dashboard"
import Profile from "views/Dashboard/Profile/"
import Failed from "views/Stripe/Failed"
import Success from "views/Stripe/Success"

import EmailConfirmation from "components/Authorization/EmailConfirmation"
import {
    CreditIcon,
    DocumentIcon,
    HomeIcon,
    PersonIcon,
    RocketIcon,
    ShipmentIcon,
    StatsIcon,
    SupportIcon,
    NotificationIcon,
} from "components/Icons/Icons"
import ChangeEmail from "views/Auth/ChangeEmail"
import ForgotPassword from "views/Auth/ForgotPassword"
import UserManagement from "views/Auth/UserManagement"
import Answer from "views/Dashboard/Answer"
import Commissions from "views/Dashboard/Commissions"
import CommissionsDetail from "views/Dashboard/Commissions/CommissionsDetail"
import Experts from "views/Dashboard/Experts"
import ShipmentPreview from 'views/Dashboard/Orders/ShipmentPreview'
import CreateShipment from "views/Dashboard/Orders/CreateShipment"
import OrdersList from "views/Dashboard/Orders/OrdersList"
import OrdersNew from "views/Dashboard/Orders/OrdersNew"
import OrdersSend from "views/Dashboard/Orders/OrdersSend"
import OrdersView from "views/Dashboard/Orders/OrdersView"
import Payments from "views/Dashboard/Payments"
import PaymentDetails from "views/Dashboard/Payments/PaymentDetails"
import PaymentHistory from "views/Dashboard/Payments/PaymentHistory"
import ProfileEdit from "views/Dashboard/Profile/ProdileEdit"
import Shipment from "views/Dashboard/Shipment"
import SwitchUser from "views/Dashboard/SwitchUser"
import ExpertsUsers from "views/Dashboard/Users"
import ExpertsUserInfo from "views/Dashboard/Users/ExpertsUserInfo"
import ExpertsUsersNew from "views/Dashboard/Users/ExpertsUsersNew"
import Notifications from "views/Dashboard/Notifications"

var dashRoutes = [ 
    {
        path: "/dashboard",
        name: "portal_left_menu_1",
        icon: <HomeIcon color="inherit" />,
        component: Dashboard,
        layout: "/admin",
        show: false,
    },
    {
        path: "/experts",
        name: "Experts",
        icon: <HomeIcon color="inherit" />,
        component: Experts,
        layout: "/admin",
        show: false,
    },
    {
        path: "/experts/:expertId",
        name: "Experts",
        icon: <HomeIcon color="inherit" />,
        component: ProfileEdit,
        layout: "/auth",
        show: false,
    },
    {
        path: "/customers",
        name: "portal_left_menu_2",
        icon: <PersonIcon color="inherit" />,
        component: ExpertsUsers,
        layout: "/admin",
        show: true,
    },
    {
        path: "/customers/new",
        name: "Create new user",
        icon: <PersonIcon color="inherit" />,
        component: ExpertsUsersNew,
        layout: "/admin",
        show: false,
    },
    {
        path: "/customers/edit/:userId",
        name: "Create new user",
        icon: <PersonIcon color="inherit" />,
        component: ExpertsUsersNew,
        layout: "/admin",
        show: false,
    },
    {
        path: "/customers/:id",
        name: "User info",
        icon: <PersonIcon color="inherit" />,
        component: ExpertsUserInfo,
        layout: "/admin",
        show: false,
    },
    {
        path: "/orders",
        name: "portal_left_menu_3",
        icon: <DocumentIcon color="inherit" />,
        component: OrdersList,
        layout: "/admin",
        show: true,
    },
    {
        path: "/order/:orderId",
        name: "Orders View",
        icon: <DocumentIcon color="inherit" />,
        component: OrdersView,
        layout: "/admin",
        show: false,
    },
    {
        path: "/orders/new",
        name: "New Order",
        icon: <DocumentIcon color="inherit" />,
        component: OrdersNew,
        layout: "/admin",
        show: false,
    },
    {
        path: "/orders/new/:userId",
        name: "New Order",
        icon: <DocumentIcon color="inherit" />,
        component: OrdersNew,
        layout: "/admin",
        show: false,
    },
    {
        path: "/orders/new/:userId/:orderId",
        name: "New Order",
        icon: <DocumentIcon color="inherit" />,
        component: OrdersNew,
        layout: "/admin",
        show: false,
    },
    {
        path: "/orders/send/:orderId/:treatmentId",
        name: "New Order",
        icon: <DocumentIcon color="inherit" />,
        component: OrdersSend,
        layout: "/admin",
        show: false,
    },
    {
        path: "/orders/shipment/:orderId",
        name: "Create shipment",
        icon: <DocumentIcon color="inherit" />,
        component: CreateShipment,
        layout: "/admin",
        show: false,
    },
    {
        path: "/orders/shipment-preview/:orderId",
        name: "Shipment Preview",
        icon: <DocumentIcon color="inherit" />,
        component: ShipmentPreview,
        layout: "/admin",
        show: false,
    },
    {
        path: "/notifications",
        name: "Notifications",
        icon: <NotificationIcon color="inherit" />,
        component: Notifications,
        layout: "/admin",
        show: true,
    },
    {
        path: "/payments",
        name: "portal_left_menu_4",
        icon: <CreditIcon color="inherit" />,
        component: Payments,
        layout: "/admin",
        show: true,
    },
    {
        path: "/payments/history",
        name: "Payments",
        icon: <CreditIcon color="inherit" />,
        component: PaymentHistory,
        layout: "/admin",
        show: false,
    },
    {
        path: "/payments/:paymentId",
        name: "Payments",
        icon: <CreditIcon color="inherit" />,
        component: PaymentDetails,
        layout: "/admin",
        show: false,
    },
    {
        path: "/shipment",
        name: "portal_left_menu_5",
        icon: <ShipmentIcon color="inherit" />,
        component: Shipment,
        layout: "/admin",
        show: true,
    },
    {
        path: "/commissions",
        name: "portal_left_menu_6",
        icon: <StatsIcon color="inherit" />,
        component: Commissions,
        layout: "/admin",
        show: true,
    },
    {
        path: "/commissions/:id",
        name: "Commissions Detail",
        icon: <StatsIcon color="inherit" />,
        component: CommissionsDetail,
        layout: "/admin",
        show: false,
    },
    {
        path: "/answer/:userId/:answerId",
        name: "Answer",
        icon: <StatsIcon color="inherit" />,
        component: Answer,
        layout: "/admin",
        show: false,
    },
    {
        path: "/switch-user",
        name: "Switch User",
        icon: <StatsIcon color="inherit" />,
        component: SwitchUser,
        layout: "/admin",
        show: false,
    },
    {
        path: "/signout",
        name: "portal_left_menu_7",
        icon: <SupportIcon color="inherit" />,
        component: SignOut,
        layout: "/auth",
        show: true,
    },
    {
        path: "/forgot",
        name: "Forgot password",
        icon: <SupportIcon color="inherit" />,
        component: ForgotPassword,
        layout: "/auth",
        show: false,
    },
    {
        path: "/usermgmt",
        name: "User Management",
        component: UserManagement,
        layout: "/auth",
        show: false,
    },
    {
        path: "/success",
        name: "",
        component: Success,
        layout: "/admin",
        show: false,
    },
    {
        path: "/failed",
        name: "",
        component: Failed,
        layout: "/admin",
        show: false,
    },
    {
        name: "ACCOUNT PAGES",
        category: "account",
        state: "pageCollapse",
        show: false,
        views: [
            {
                path: "/profile/edit",
                name: "Profile",
                icon: <PersonIcon color="inherit" />,
                secondaryNavbar: true,
                component: ProfileEdit,
                layout: "/auth",
            },
            {
                path: "/profile",
                name: "Profile",
                icon: <PersonIcon color="inherit" />,
                secondaryNavbar: true,
                component: Profile,
                layout: "/auth",
            },
            {
                path: "/signin",
                name: "Log out",
                icon: <SupportIcon color="inherit" />,
                component: SignIn,
                layout: "/auth",
            },
            {
                path: "/signup",
                name: "Sign Up",
                icon: <RocketIcon color="inherit" />,
                secondaryNavbar: true,
                component: SignUp,
                layout: "/auth",
            },
            {
                path: "/email-confirmation",
                name: "Email Confirmation",
                icon: <RocketIcon color="inherit" />,
                secondaryNavbar: true,
                component: EmailConfirmation,
                layout: "/auth",
            },
            {
                path: "/change-email",
                name: "Change email",
                icon: <RocketIcon color="inherit" />,
                secondaryNavbar: true,
                component: ChangeEmail,
                layout: "/auth",
            },
        ],
    },
];
export default dashRoutes;
