import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from "swiper";
import 'swiper/css';
import s from '../Home.module.scss'
import img1 from '../../../../assets/img/result-1.jpeg'
import img11 from '../../../../assets/img/result-1-1.jpeg'
import img2 from '../../../../assets/img/result-2.jpeg'
import img21 from '../../../../assets/img/result-2-1.jpeg'

const data = [
  [
    {
      img: img1,
      title: "anna United Kingdom problem: acne"
    },
    {
      img: img11,
      title: "after using l&d skincare products"
    },
  ],
  [
    {
      img: img2,
      title: "anna United Kingdom problem: pigmentation"
    },
    {
      img: img21,
      title: "after using l&d skincare products"
    },
  ],
  [
    {
      img: img1,
      title: "anna United Kingdom problem: acne"
    },
    {
      img: img11,
      title: "after using l&d skincare products"
    },
  ],
  [
    {
      img: img2,
      title: "anna United Kingdom problem: pigmentation"
    },
    {
      img: img21,
      title: "after using l&d skincare products"
    },
  ],

]

const Results = () => {
  const pagination = {
    clickable: true,
    renderBullet: (index, className) => `<span class="${className}" /></span>`
  }

  return (
    <div id="results" className={s.results}>
      <div className={s.container}>
        <h2 className={s.sectionTitle}><span>Our Results</span></h2>
        <p className={s.sectionSubtitle}>Discover amazing skincare journeys and explore our routine finder</p>
        <Swiper
          pagination={pagination}
          modules={[Pagination]}
          breakpoints={{
            0: {
              spaceBetween: 0,
              slidesPerView: 1
            },
            768: {
              spaceBetween: 52,
              slidesPerView: 2
            }
          }}
        >
          {data.map((i, index) => (
            <SwiperSlide key={index}>
              <div className={s.resultsSlide}>
                {i.map((j, jIndex) => (
                  <div key={jIndex} className={s.resultsSlideItem}>
                    <img src={j.img} alt="Picture" className={s.resultsSlideImg} />
                    <p className={s.resultsSlideText}>{j.title}</p>
                  </div>
                ))}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}

export default Results