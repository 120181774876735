export const time = {
  ['M']: 'Morning',
  ['E']: 'Evening',
  ['M&E']: 'Morning & Evening',
}

export const frequency = {
  ['ED']: 'Every Day',
  ['E2D']: 'Every 2 Day',
  ['E3D']: 'Every 3 Day',
  ['E7D']: 'Every 7 Day',
  ['E10D']: 'Every  10 Day',
  ['E12D']: 'Every  12 Day',
  ['E14D']: 'Every 14 Day',
  ['OTHER']: 'Other',
}

export const areas = [
  {
    key: "entire_face",
    val: "Entire face (Face + Eyelids)"
  },
  {
    key: "face_avoiding_eyelids",
    val: "Face avoiding eyelids"
  },
  {
    key: "eyelids",
    val: "Eyelids"
  },
  {
    key: "neck",
    val: "Neck"
  },
  {
    key: "chest",
    val: "Chest"
  },
  {
    key: "lips",
    val: "Lips"
  },
  {
    key: "back",
    val: "Back"
  },
  {
    key: "other",
    val: "Other"
  },
]