import {
  Box,
  Button,
  Flex,
  Input,
  Link,
  Spinner,
  Text,
  useColorModeValue,
} from "@chakra-ui/react"
import {
  applyActionCode,
  checkActionCode,
  confirmPasswordReset,
  getAuth,
  sendPasswordResetEmail,
  verifyPasswordResetCode,
} from "firebase/auth"
import qs from "query-string"
import { useEffect, useRef, useState } from "react"
import "react-loading-skeleton/dist/skeleton.css"
import { useHistory } from "react-router-dom"

function UserManagement() {
  const auth = getAuth();
  const { push } = useHistory();

  const parsed = qs.parse(location.search);
  const mode = parsed.mode;
  const actionCode = parsed.oobCode;
  const continueUrl = parsed.continueUrl;
  const lang = parsed.lang || "en";

  const [content, setContent] = useState(<Spinner color="red.700" size="xl" />)
  const [errorResponse, setErrorResponse] = useState("");

  const password1 = useRef(null)
  const password2 = useRef(null)

  const handleReset = () => {
    setErrorResponse('')
    
    if (password1.current.value !== password2.current.value) {
      setErrorResponse("Passwords don't match");
    } else {
      confirmPasswordReset(auth, actionCode, password1.current.value)
        .then((resp) => {
          setContent(
            <>
              <Text fontSize="4xl" color="red" fontWeight="bold">
                Password reset has been confirmed and new
                password updated.
              </Text>
              <Text fontSize="2xl" color="red">
                Please now you can login with a new password
              </Text>
              <Button
                fontSize="10px"
                type="submit"
                bg="rgb(139, 16, 38)"
                w="100%"
                h="45"
                mb="20px"
                color="white"
                mt="20px"
                _hover={{
                  bg: "red.200",
                }}
                _active={{
                  bg: "red.400",
                }}
                onClick={() => push("/auth/signin")}
              >
                Go to login
              </Button>
            </>
          );
        })
        .catch((error) => {
          setContent(
            <>
              <Text>
                Confirm password reset error: {error.message}
              </Text>
            </>
          );
        });
    }
  };

  function handleResetPassword(auth, actionCode, continueUrl, lang) {
    verifyPasswordResetCode(auth, actionCode)
      .then((email) => {
        const accountEmail = email;
        setContent(
          <>
            <Text fontSize="4xl" color="red" fontWeight="bold">
              Please enter new password for {accountEmail}
            </Text>
            <Input
              borderRadius="15px"
              mb="36px"
              fontSize="sm"
              type="password"
              placeholder="Your password"
              size="lg"
              ref={password1}
            />
            <Input
              borderRadius="15px"
              mb="36px"
              fontSize="sm"
              type="password"
              placeholder="Repeat password"
              size="lg"
              ref={password2}
            />
            <span className="error"></span>
            <Button
              fontSize="10px"
              type="submit"
              bg="rgb(139, 16, 38)"
              w="100%"
              h="45"
              mb="20px"
              color="white"
              mt="20px"
              _hover={{
                bg: "red.200",
              }}
              _active={{
                bg: "red.400",
              }}
              onClick={() => handleReset()}
            >
              RESET
            </Button>
          </>
        );
      })
      .catch((error) => {
        console.log("verifyPasswordResetCode", error);
        const parts = ("" + error).split(":");
        setContent(
          <>
            <Text>
              Verify password reset code error: {parts[2]}
            </Text>
          </>
        );
      });
  }

  function handleRecoverEmail(auth, actionCode, lang) {
    let restoredEmail = null;
    checkActionCode(auth, actionCode)
      .then((info) => {
        restoredEmail = info["data"]["email"];

        return applyActionCode(auth, actionCode);
      })
      .then(() => {
        sendPasswordResetEmail(auth, restoredEmail)
          .then(() => {
            setContent(
              <>
                <Text>
                  Password reset confirmation sent. &nbsp;
                  <b>Please, check your {restoredEmail}</b>
                </Text>
              </>
            );
          })
          .catch((error) => {
            console.log("sendPasswordResetEmail", error);
            setContent(
              <>
                <Text>
                  Send password reset error: {error.message}
                  <br />
                  Please,&nbsp;
                  <a href="/auth/signup" color="red">
                    Continue.
                  </a>
                </Text>
              </>
            );
          });
      })
      .catch((error) => {
        // Invalid code.
        setContent(
          <>
            <Text>Check action code error: {error.message}</Text>
            Please,&nbsp;
          </>
        );
      });
  }

  function handleVerifyEmail(auth, actionCode, continueUrl, lang) {
    setContent(
      <>
        <Text>Trying to apply the email verification code.</Text>
      </>
    );
    applyActionCode(auth, actionCode)
      .then((resp) => {
        setContent(
          <>
            <Text>
              The email address has been successfully verified.
              <br />
              Please,&nbsp;
              <Link href="/auth/signup" color="red.700">
                Continue.
              </Link>
            </Text>
          </>
        );
      })
      .catch((error) => {
        console.log("handleVerifyEmail", error.code);

        setContent(
          <>
            <Text>Verify email handle error: {error.code}</Text>
            <br />
            Please,&nbsp;
            <a href="/auth/signup" color="red">
              Continue.
            </a>
          </>
        );
      });
  }

  useEffect(() => {
    try {
      switch (mode) {
        case "resetPassword":
          handleResetPassword(auth, actionCode, continueUrl, lang);
          break;
        case "recoverEmail":
          handleRecoverEmail(auth, actionCode, lang);
          break;
        case "verifyEmail":
          handleVerifyEmail(auth, actionCode, continueUrl, lang);
          break;
        default:
          setContent(
            <>
              <Text>Invalid mode</Text>
              <br />
              Please,&nbsp;
              <a href="/auth/signup" color="red">
                Continue.
              </a>
            </>
          );
      }
    } catch (ex) {
      setErrorResponse(ex.message);
    }
  }, []);

  const bgColor = useColorModeValue("white", "gray.700");

  return (
    <Flex
      direction="column"
      alignSelf="center"
      justifySelf="center"
      overflow="hidden"
    >
      <Box
        position="absolute"
        minH={{ base: "70vh", md: "50vh" }}
        w={{ md: "calc(100vw - 50px)" }}
        borderRadius={{ md: "15px" }}
        left="0"
        right="0"
        bgRepeat="no-repeat"
        overflow="hidden"
        zIndex="-1"
        top="0"
        bgColor={"rgb(139, 16, 38)"}
        bgSize="cover"
        mx={{ md: "auto" }}
        mt={{ md: "14px" }}
      ></Box>
      <Flex
        direction="column"
        textAlign="center"
        justifyContent="center"
        align="center"
        mt="6.5rem"
        mb="30px"
      ></Flex>
      <Flex
        alignItems="center"
        justifyContent="center"
        mb="60px"
        mt="20px"
      >
        <Flex
          direction="column"
          w="90%"
          background="transparent"
          borderRadius="15px"
          p="40px"
          mx={{ base: "100px" }}
          bg={bgColor}
          boxShadow="0 20px 27px 0 rgb(0 0 0 / 5%)"
        >
          {content}
          <Text
            mb="36px"
            ms="4px"
            color="red"
            fontWeight="bold"
            fontSize="14px"
          >
            {errorResponse}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default UserManagement;
