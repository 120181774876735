import axios from 'axios'
import { getAuth } from 'firebase/auth'

const API_URL = process.env.REACT_APP_API_URL

const api = axios.create({
  baseURL: API_URL
})

api.interceptors.request.use(
  async config => {
    if (config.headers) {
      const auth = getAuth();
      const token = await auth?.currentUser?.getIdToken(true);
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
)

export default api