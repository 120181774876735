// Chakra Imports
import {
    Button,
    Drawer,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    Flex,
    Text,
    useColorMode,
    useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { invoicesData } from "variables/general";
import InvoicesRow from "components/Tables/InvoicesRow";
import { Separator } from "components/Separator/Separator";
import PropTypes from "prop-types";
import React, { useState } from "react";

export default function Configurator(props) {
    const { secondary, isOpen, onClose, fixed, ...rest } = props;
    const [switched, setSwitched] = useState(props.isChecked);
    const textColor = useColorModeValue("gray.700", "white");

    const { colorMode, toggleColorMode } = useColorMode();
    // Chakra Color Mode
    let fixedDisplay = "flex";
    if (props.secondary) {
        fixedDisplay = "none";
    }

    const settingsRef = React.useRef();
    return (
        <>
            <Drawer
                isOpen={props.isOpen}
                onClose={props.onClose}
                placement={
                    document.documentElement.dir === "rtl" ? "left" : "right"
                }
                finalFocusRef={settingsRef}
                blockScrollOnMount={false}
            >
                <DrawerContent>
                    <DrawerHeader pt="24px" px="24px">
                        <DrawerCloseButton />
                        <Text fontSize="xl" fontWeight="bold" mt="16px">
                            Documents
                        </Text>
                        <Text fontSize="md" mb="16px">
                            Subtitle
                        </Text>
                        <Separator />
                    </DrawerHeader>
                    <Card
                        p="22px"
                        my={{ sm: "24px", lg: "0px" }}
                        ms={{ sm: "0px" }}
                    >
                        <CardHeader>
                            <Flex
                                justify="space-between"
                                align="center"
                                mb="1rem"
                                w="100%"
                            >
                                <Text
                                    fontSize="lg"
                                    color={textColor}
                                    fontWeight="bold"
                                >
                                    TITLE
                                </Text>
                                <Button
                                    colorScheme="teal"
                                    borderColor="red.300"
                                    color="red.300"
                                    variant="outline"
                                    fontSize="xs"
                                    p="8px 32px"
                                >
                                    VIEW ALL
                                </Button>
                            </Flex>
                        </CardHeader>
                        <CardBody>
                            <Flex direction="column" w="100%">
                                {invoicesData.map((i,row) => {
                                    return (
                                        <InvoicesRow
                                            key={i}
                                            date={row.date}
                                            code={row.code}
                                            price={row.price}
                                            logo={row.logo}
                                            format={row.format}
                                        />
                                    );
                                })}
                            </Flex>
                        </CardBody>
                    </Card>
                </DrawerContent>
            </Drawer>
        </>
    );
}
Configurator.propTypes = {
    secondary: PropTypes.bool,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    fixed: PropTypes.bool,
};
