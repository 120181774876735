// Chakra imports
import { Box, Button, Center, Link, Heading } from "@chakra-ui/react";
// Assets

import React, { useEffect, useState } from "react";
import { getAuth, sendEmailVerification } from "firebase/auth";
import { Link as NavLink } from "react-router-dom";
import LibiLogo from "assets/img/libiLogoBlack.png";
import { useTranslation } from "react-i18next";

export default function EmailConfirmation() {
    const { t } = useTranslation();
    const auth = getAuth();
    const [resendEnabled, setResendEnabled] = useState(false);
    const [counterTicks, setCounterTicks] = useState(20);
    let dec;
    const delayMax = 45;

    const counter = async () => {
        dec--;
        if (dec <= 0) {
            console.log("End of the counter");
            setResendEnabled(true);
            dec = delayMax;
        } else {
            setTimeout(counter, 1000);
        }
        setCounterTicks(dec);
    };

    const resentVerification = () => {
        sendEmailVerification(auth?.currentUser);
        setResendEnabled(false);
        dec = delayMax;
        setTimeout(counter, 1000);
    };

    useEffect(() => {
        dec = delayMax;
        setCounterTicks(dec);
        setTimeout(counter, 1000);
    }, []);

    return (
        <div className="confirm-email">
            <Center>
                <Link as={NavLink} to="/admin/dashboard">
                    <Box
                        bgImage={LibiLogo}
                        w="150px"
                        h="125px"
                        bgSize="cover"
                        alignItems="center"
                    ></Box>
                </Link>
            </Center>
            <Center>
                <Heading
                    color="#92001A"
                    fontSize="32px"
                    mb="6px"
                    mt="10px"
                    fontFamily="Cormorant Garamond"
                    fontWeight="400"
                >
                    {t('portal_email_confirmation_top_title', 'Email confirmation')}
                </Heading>
            </Center>
            <Center>
                <Box
                    fontSize="15px"
                    color="#3D2541"
                    fontWeight="500"
                    mt="10px"
                    mb="10px"
                    align="center"
                >
                    <p
                        style={{
                            color: "#92001A",
                            fontFamily: "Montserrat",
                            fontStyle: "normal",
                            fontWeight: "500",
                            fontSize: "15px",
                        }}
                    >
                        {auth?.currentUser?.emailVerified
                            ? t('portal_email_confirmation_email_ok', 'Your email [[email]] is ok... Problem on our side')?.replace('[[email]]', auth?.currentUser?.email)
                            : t('portal_email_confirmation_email_not_verified', 'Your email [[email]] is not verified.')?.replace('[[email]]', auth?.currentUser?.email)
                        }
                    </p>
                    <br />
                    {t('portal_email_confirmation_top_description', 'Please check your email box and click on the confirmation link.')}
                </Box>
            </Center>
            <Center>
                <Button
                    fontSize="14px"
                    fontFamily="Montserrat"
                    fontStyle="normal"
                    fontWeight="600"
                    borderRadius="6px"
                    border="0.5px solid"
                    type="submit"
                    bg="#FEF8F4"
                    w="270px"
                    h="45px"
                    mb="20px"
                    color="#92001A"
                    letterSpacing="1.5px"
                    mt="5px"
                    // _hover={{
                    //     bg: "red.200",
                    // }}
                    // _active={{
                    //     bg: "red.400",
                    // }}
                    onClick={() => resentVerification()}
                    disabled={!resendEnabled}
                >
                    {t('portal_email_confirmation_button', 'RESEND VERIFICATION EMAIL')}
                </Button>
            </Center>
            <Center>
                <p
                    style={{
                        fontWeight: "400",
                        fontSize: "16px",
                        color: "#3D2541",
                    }}
                >
                    {resendEnabled ? (
                        <> </>
                    ) : (
                        <>
                            {t('portal_email_confirmation_another_try', 'Another try after:')}{" "}
                            <span style={{ fontWeight: "600" }}>
                                {counterTicks}
                            </span>
                            <br />
                        </>
                    )}
                    {t('portal_email_confirmation_done_title', 'Done?')}&nbsp;
                    <a
                        style={{
                            color: "rgb(139, 16, 38)",
                            textDecorationLine: "underline",
                        }}
                        href="/auth/signup"
                    >
                        {t('portal_email_confirmation_done_link', 'Continue')}
                    </a>
                </p>
            </Center>
        </div>
    );
}
