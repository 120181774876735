import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

function useScrollToTop() {
  const { action } = useHistory()
  const { pathname } = useLocation()

  useEffect(() => {
    if (action === 'POP') return

    window.scrollTo(0, 0)
  }, [action, pathname])
}

export default useScrollToTop;
