import { CRM_URL, db } from "./firebase";
import { doc, getDoc } from "firebase/firestore";
import moment from "moment";
import { setOrdersUpdating } from "store/slices/userSlice";
import { store } from "store";
import {
  setCustomersUpdating,
  setPaymentsUpdating,
  setShipmentsUpdating,
} from "store/slices/userSlice";
import { setOrders } from "store/slices/ordersSlice";
import { setCustomers } from "store/slices/customersSlice";
import { setPayments } from "store/slices/paymentsSlice";
import i18n from "i18next";
import centralApi from "centralApi";

export const fileSizeIsValid = (fileSize, maxSize) => {
  const currentSize = fileSize / 1024 / 1024;

  return currentSize <= maxSize;
};

export const chunk = (arr, size) => {
  const result = [];

  for (let i = 0; i < Math.ceil(arr.length / size); i++) {
    result.push(arr.slice(i * size, i * size + size));
  }

  return result;
};

export const getTierLevel = async (expertId) => {
  let limit = 0;

  const {
    data: { list: ordersArr },
  } = await centralApi.get("ordersByExpertID", {
    params: { expertId },
  });

  if (!ordersArr?.length) return 0;

  const activeUsers = ordersArr
    .filter(
      (i) => !["payment_needed", "refunded", "canceled"].includes(i.status)
    )
    .filter((j) => {
      const created = moment(j.created);
      const diff = moment(Date.now()).diff(created, "days");

      if (diff <= 60) return j;
    })
    .map((k) => k.userId);

  const paidUsersCount = [...new Set(activeUsers)].length;

  const pricesRef = doc(db, "Meta", "PartnersPrices");
  const pricesSnap = await getDoc(pricesRef);

  if (pricesSnap.exists()) {
    const prices = pricesSnap.data();
    console.log("prices", prices);

    if (paidUsersCount < prices.limit5) {
      limit = 5;
    } else if (
      paidUsersCount >= prices.limit5 &&
      paidUsersCount < prices.limit10
    ) {
      limit = 10;
    } else if (
      paidUsersCount >= prices.limit10 &&
      paidUsersCount < prices.limit15
    ) {
      limit = 15;
    } else if (paidUsersCount >= prices.limit15) {
      limit = 20;
    }
  }

  return limit;
};

export const getUserActionStatus = async (
  userData,
  order,
  checkInactive = false,
  userPackage
) => {
  let lStatus = userData?.lStatus || "active";
  let actionStatus = userData?.lStatus || "registered";
  let lastPackage = "";
  let isRefund = false;

  if (userData?.weeklyQuestionnaireDone === 0) {
    lStatus = "action_needed";
  }

  if (
    order.status === "payment_needed" ||
    order.status === "paid_stripe" ||
    order.status === "paid_shopify"
  ) {
    lStatus = "action_needed";
  } else if (
    order.status === "delivered" ||
    order.status === "delivery_in_process"
  ) {
    lStatus = "active";
  }

  if (order.status === "payment_needed" && order.submitted) {
    actionStatus = "payment_needed";
  } else if (
    order.status === "paid_stripe" ||
    order.status === "paid_shopify"
  ) {
    if (
      ["registered", "waiting_list", "questionnaire1"].includes(
        userData?.status
      ) &&
      !userData?.skipQuestionnaire
    ) {
      actionStatus = "skip_initial";
    } else if (
      [
        "questionnaire1",
        "waiting_diagnose",
        "waiting_payment",
        "waiting_delivery",
        "active",
        "lost_customer",
      ].includes(userData?.status) &&
      userData?.weeklyQuestionnaireDone === 0
    ) {
      actionStatus = "skip_weekly";
    } else if (order?.assessmentId && !order.submitted) {
      actionStatus = "send_assessment";
    } else if (order?.type === "rb") {
      lStatus = "assessment_needed";
      actionStatus = "update_assessment";
    }
  } else if (order.status === "questionnaire_needed") {
    if (
      [
        "waiting_diagnose",
        "waiting_payment",
        "waiting_delivery",
        "active",
        "lost_customer",
      ].includes(userData?.status)
    ) {
      if (userData?.weeklyQuestionnaireDone === 1) {
        lStatus = "assessment_needed";
        actionStatus = "give_assessment";
      } else {
        lStatus = "questionnaire_needed";
        actionStatus = "skip_weekly";
      }
    } else if (
      ["registered", "waiting_list", "questionnaire1"].includes(
        userData?.status
      )
    ) {
      if (userData?.skipQuestionnaire === 1) {
        lStatus = "assessment_needed";
        actionStatus = "give_assessment";
      } else {
        lStatus = "questionnaire_needed";
        actionStatus = "skip_initial";
      }
    } else {
      lStatus = "action_needed";
    }
  } else if (!order.submitted) {
    actionStatus = "send_assessment";
  } else {
    actionStatus = "give_assessment";
  }

  if (
    order.status === "paid_stripe" ||
    order.status === "paid_shopify" ||
    order.status === "delivery_needed"
  ) {
    isRefund = true;
  } else if (
    order.status === "delivery_in_process" ||
    order.status === "delivered"
  ) {
    const diff = moment().diff(moment(order?.paymentDate), "days");
    if (diff < 14) {
      isRefund = true;
    }
  }

  if (checkInactive) {
    const created = moment(userPackage?.created);
    const diff = moment(Date.now()).diff(created, "days");

    lastPackage = created;

    if (
      diff > 60 &&
      order.status !== "payment_needed" &&
      order.status !== "paid_stripe" &&
      order.status !== "paid_shopify"
    ) {
      lStatus = "inactive";
      actionStatus = "inactive";
    }
  }

  return {
    lStatus,
    actionStatus,
    lastPackage,
    isRefund,
  };
};

export const getOrders = async (userID, page = 1) => {
  store.dispatch(setOrdersUpdating(true));

  const {
    data: { list: ordersData },
  } = await centralApi.get("ordersByExpertID", {
    params: { expertId: userID },
  });

  if (ordersData?.length) {
    for (let i = 0; i < ordersData.length; i++) {
      const order = ordersData[i];

      if (order?.userId) {
        const userData = order.user;

        const { actionStatus, isRefund } = await getUserActionStatus(
          userData,
          order
        );

        ordersData[i] = {
          ...order,
          userId: order.userId,
          name:
            (userData?.firstName &&
              userData?.lastName &&
              `${userData?.firstName} ${userData?.lastName}`) ||
            userData?.name ||
            "-",
          email: userData?.email || "-",
          userStatus: userData?.status,
          weeklyQuestionnaireDone: userData?.weeklyQuestionnaireDone,
          actionStatus,
          isRefund,
        };
      }
    }

    const sortedOrders = ordersData.sort((a, b) =>
      new Date(a.created).getTime() < new Date(b.created).getTime() ? 1 : -1
    );

    store.dispatch(setOrders(sortedOrders));
    store.dispatch(setOrdersUpdating(false));
    return sortedOrders;
  }

  return [];
};

export const updateStorageOrders = async (
  expertId,
  userData,
  order,
  orderId
) => {
  const orders = store.getState().orders.list;
  console.log(orders);
  const users = store.getState().customers.list;
  const payments = store.getState().payments.list;
  const discounts = store.getState().discounts;

  let newOrder = orderId
    ? {
        ...orders.find((i) => i.id === orderId),
        ...order,
      }
    : order;

  const {
    lStatus,
    actionStatus,
    lastPackage,
    isRefund,
  } = await getUserActionStatus(userData, newOrder);

  newOrder = {
    ...newOrder,
    created: orderId ? newOrder.created : new Date(),
    name:
      (userData?.firstName &&
        userData?.lastName &&
        `${userData?.firstName} ${userData?.lastName}`) ||
      userData?.name ||
      "-",
    actionStatus,
    isRefund,
  };

  const newOrders = orderId
    ? orders.map((i) => {
        if (i.id === orderId) {
          return { ...i, ...newOrder };
        }
        return i;
      })
    : [newOrder, ...orders];

  store.dispatch(setOrders(newOrders));

  const newUsers = users.map((i) => {
    if (i.id === userData.id) {
      return {
        ...i,
        lStatus,
        actionStatus,
        lastPackage,
        isRefund,
      };
    }
    return i;
  });

  store.dispatch(setCustomers(newUsers));

  if (newOrder.status === "payment_needed" && !newOrder.paid) {
    if (discounts) {
      const {
        discount,
        rbDiscount,
        qcADiscount,
        qcBDiscount,
        userCurrency,
      } = discounts;

      const pricesRef = doc(db, "Meta", "PartnersPrices");
      const pricesSnap = await getDoc(pricesRef);
      if (pricesSnap.exists()) {
        const prices = pricesSnap.data();

        const newPayment = [newOrder].map((i) => {
          let consumerTotal = 0;
          let priceId = "";

          if (i?.type.includes("qc")) {
            consumerTotal =
              prices[`${i.type}PricePartner${qcADiscount}${userCurrency}`];
            priceId =
              prices[
                `${i.type}PricePartner${qcADiscount}ID${userCurrency}${
                  !!userData?.isTestUser ||
                  process.env.REACT_APP_ENV === "development"
                    ? "_test"
                    : ""
                }`
              ];
          } else if (i?.type === "ds") {
            consumerTotal =
              prices[`${i.type}PricePartner${qcADiscount}${userCurrency}`];
            priceId =
              prices[
                `${i.type}PricePartner${qcADiscount}ID${userCurrency}${
                  !!userData?.isTestUser ||
                  process.env.REACT_APP_ENV === "development"
                    ? "_test"
                    : ""
                }`
              ];
          } else if (i?.type === "se") {
            consumerTotal =
              prices[`${i.type}PricePartner${qcADiscount}${userCurrency}`];
            consumerTotal =
              prices[
                `ip${i.product.products[0].ref.replace(
                  /p/gi,
                  ""
                )}PricePartner${qcADiscount}${userCurrency}`
              ];
            priceId =
              prices[
                `${i.type}PricePartner${qcADiscount}ID${userCurrency}${
                  !!userData?.isTestUser ||
                  process.env.REACT_APP_ENV === "development"
                    ? "_test"
                    : ""
                }`
              ];
          } else if (i?.type === "st") {
            consumerTotal =
              prices[`${i.type}PricePartner${qcADiscount}${userCurrency}`];
            priceId =
              prices[
                `${i.type}PricePartner${qcADiscount}ID${userCurrency}${
                  !!userData?.isTestUser ||
                  process.env.REACT_APP_ENV === "development"
                    ? "_test"
                    : ""
                }`
              ];
            consumerTotal =
              prices[
                `${i.type}${
                  i.product.title.split(" ")[0]
                }PricePartner${qcADiscount}${userCurrency}`
              ];
            priceId =
              prices[
                `${i.type}${
                  i.product.title.split(" ")[0]
                }PricePartner${qcADiscount}ID${userCurrency}${
                  !!userData?.isTestUser ||
                  process.env.REACT_APP_ENV === "development"
                    ? "_test"
                    : ""
                }`
              ];
          } else if (i?.type === "rb") {
            consumerTotal =
              prices[`rbPricePartner${rbDiscount}${userCurrency}`];
            priceId =
              prices[
                `rbPricePartner${rbDiscount}ID${userCurrency}${
                  !!userData?.isTestUser ||
                  process.env.REACT_APP_ENV === "development"
                    ? "_test"
                    : ""
                }`
              ];
          } else if (i?.type === "ip") {
            consumerTotal = i?.total;
            priceId =
              prices[
                `ipShippingPricePartner0ID${userCurrency}${
                  !!userData?.isTestUser ||
                  process.env.REACT_APP_ENV === "development"
                    ? "_test"
                    : ""
                }`
              ] +
              i?.product?.products?.reduce(
                (prev, curr) =>
                  prev +
                  "," +
                  new Array(curr?.quantity)
                    .fill(
                      prices[
                        `i${curr?.ref}PricePartner0ID${userCurrency}${
                          !!userData?.isTestUser ||
                          process.env.REACT_APP_ENV === "development"
                            ? "_test"
                            : ""
                        }`
                      ]
                    )
                    .join(","),
                ""
              );
          }

          return {
            ...i,
            consumerTotal,
            priceId,
            total: consumerTotal - consumerTotal * (discount / 100),
          };
        });

        store.dispatch(setPayments([...newPayment, ...payments]));
      }
    }
  }
};

export const getUsers = async (userID) => {
  store.dispatch(setCustomersUpdating(true));

  let usersData = [];

  const {
    data: { list: usersByExpertID },
  } = await centralApi.get("usersByExpertID", {
    params: { expertId: userID },
  });

  if (usersByExpertID?.length) {
    usersByExpertID.forEach((i) => {
      usersData.push({
        ...i,
        name:
          (i?.firstName && i?.lastName && `${i?.firstName} ${i?.lastName}`) ||
          i?.name ||
          "-",
      });
    });
  }

  const {
    data: { list: ordersData },
  } = await centralApi.get("ordersByExpertID", {
    params: { expertId: userID },
  });
  const { data: expertPackages } = await centralApi.get(
    "getLastPackagesByExpertID",
    {
      params: { expertId: userID },
    }
  );

  if (usersData.length) {
    for (let i = 0; i < usersData.length; i++) {
      const userData = usersData[i];

      const userOrders = ordersData
        .filter((j) => j.userId === userData.id)
        .filter((k) => k.status !== "canceled")
        .sort((a, b) =>
          new Date(a?.created).getTime() < new Date(b?.created).getTime()
            ? -1
            : 1
        );

      if (userOrders.length) {
        let lastOrder = "";
        let lastOrderStatus = "";
        let lastOrderId = "";
        let lastOrderTreatmentId = "";
        let lastOrderPaymentId = "";

        const order = userOrders[userOrders.length - 1];
        const userPackage = expertPackages.find(
          (i) => i.userId === userData.id
        );

        let {
          lStatus,
          actionStatus,
          lastPackage,
          isRefund,
        } = await getUserActionStatus(userData, order, true, userPackage);

        lastOrder = order.created;
        lastOrderStatus = order.status;
        lastOrderId = order.id;
        lastOrderTreatmentId = order?.treatmentId;
        lastOrderPaymentId = order?.paymentId;

        usersData[i].lStatus = lStatus;
        usersData[i].actionStatus = actionStatus;
        usersData[i].lastPackage = lastPackage;
        usersData[i].lastOrder = lastOrder;
        usersData[i].lastOrderStatus = lastOrderStatus;
        usersData[i].lastOrderId = lastOrderId;
        usersData[i].lastOrderTreatmentId = lastOrderTreatmentId;
        usersData[i].lastOrderPaymentId = lastOrderPaymentId;
        usersData[i].isRefund = isRefund;

        if (lStatus !== userData?.lStatus) {
          await centralApi.post("updateUser", { id: userData.id, lStatus });
        }
      } else {
        usersData[i].lStatus = "registered";
        usersData[i].actionStatus = "give_assessment";
      }
    }
  }

  const sortedUsers = usersData.sort((a, b) =>
    new Date(a?.created).getTime() < new Date(b?.created).getTime() ? 1 : -1
  );

  store.dispatch(setCustomersUpdating(false));
  return sortedUsers;
};

export const getPayments = async (userID) => {
  store.dispatch(setPaymentsUpdating(true));

  const {
    data: { list: ordersArr },
  } = await centralApi.get("ordersByExpertID", {
    params: { expertId: userID },
  });

  const { data: countries } = await centralApi.get("getCountries");

  let paymentNeededOrders =
    ordersArr?.filter((i) => i.status === "payment_needed" && !i.paid) || [];

  if (paymentNeededOrders?.length === 0) {
    store.dispatch(setPaymentsUpdating(false));
    return {
      payments: [],
    };
  } else {
    const { data: userByID } = await centralApi.get("userByID", {
      params: { userId: userID },
    });

    const tierDiscount = userByID?.tierDiscount;
    const rbDiscount =
      userByID?.rbDiscount === undefined || userByID?.rbDiscount === null
        ? 5
        : userByID?.rbDiscount;
    const qcADiscount =
      userByID?.qcADiscount === undefined || userByID?.qcADiscount === null
        ? 5
        : userByID?.qcADiscount;
    const qcBDiscount =
      userByID?.qcBDiscount === undefined || userByID?.qcBDiscount === null
        ? 5
        : userByID?.qcBDiscount;

    const tierLevel = !tierDiscount ? await getTierLevel(userID) : null;
    console.log("tierDiscount", tierDiscount);
    console.log("tierLevel", tierLevel);

    let userCurrency = "";
    if (
      ["GB", "UK", "United Kingdom"].includes(
        countries.find((i) => i.id === userByID?.shippingAddress?.countryId)
          ?.code
      ) ||
      ["GB", "UK", "United Kingdom"].includes(
        countries.find((i) => i.id === userByID?.countryId)?.code
      )
    ) {
      userCurrency = "UK";
    } else {
      userCurrency = "HU";
    }

    // get price
    const pricesRef = doc(db, "Meta", "PartnersPrices");
    const pricesSnap = await getDoc(pricesRef);
    if (pricesSnap.exists()) {
      const prices = pricesSnap.data();

      paymentNeededOrders = paymentNeededOrders.map((i) => {
        let consumerTotalUK = 0;
        let consumerTotalHU = 0;
        let priceIdUK = "";
        let priceIdHU = "";

        if (i?.type.includes("qc")) {
          consumerTotalUK = prices[`${i.type}PricePartner${qcADiscount}UK`];
          consumerTotalHU = prices[`${i.type}PricePartner${qcADiscount}HU`];
          priceIdUK =
            prices[
              `${i.type}PricePartner${qcADiscount}IDUK${
                !!i?.isTestUser || process.env.REACT_APP_ENV === "development"
                  ? "_test"
                  : ""
              }`
            ];
          priceIdHU =
            prices[
              `${i.type}PricePartner${qcADiscount}IDHU${
                !!i?.isTestUser || process.env.REACT_APP_ENV === "development"
                  ? "_test"
                  : ""
              }`
            ];
        } else if (i?.type === "ds") {
          consumerTotalUK = prices[`${i.type}PricePartner${qcADiscount}UK`];
          consumerTotalHU = prices[`${i.type}PricePartner${qcADiscount}HU`];
          priceIdUK =
            prices[
              `${i.type}PricePartner${qcADiscount}IDUK${
                !!i?.isTestUser || process.env.REACT_APP_ENV === "development"
                  ? "_test"
                  : ""
              }`
            ];
          priceIdHU =
            prices[
              `${i.type}PricePartner${qcADiscount}IDHU${
                !!i?.isTestUser || process.env.REACT_APP_ENV === "development"
                  ? "_test"
                  : ""
              }`
            ];
        } else if (i?.type === "se") {
          consumerTotalUK =
            prices[
              `ip${i.product.products[0].ref.replace(/p/gi, "")}PricePartner0UK`
            ];
          consumerTotalHU =
            prices[
              `ip${i.product.products[0].ref.replace(/p/gi, "")}PricePartner0HU`
            ];
          priceIdUK =
            prices[
              `ip${i.product.products[0].ref.replace(
                /p/gi,
                ""
              )}PricePartner0IDUK${
                !!i?.isTestUser || process.env.REACT_APP_ENV === "development"
                  ? "_test"
                  : ""
              }`
            ];
          priceIdHU =
            prices[
              `ip${i.product.products[0].ref.replace(
                /p/gi,
                ""
              )}PricePartner0IDHU${
                !!i?.isTestUser || process.env.REACT_APP_ENV === "development"
                  ? "_test"
                  : ""
              }`
            ];
        } else if (i?.type === "st") {
          consumerTotalUK =
            prices[`${i.type}${i.product.title.split(" ")[0]}PricePartner0UK`];
          consumerTotalHU =
            prices[`${i.type}${i.product.title.split(" ")[0]}PricePartner0HU`];
          priceIdUK =
            prices[
              `${i.type}${i.product.title.split(" ")[0]}PricePartner0IDUK${
                !!i?.isTestUser || process.env.REACT_APP_ENV === "development"
                  ? "_test"
                  : ""
              }`
            ];
          priceIdHU =
            prices[
              `${i.type}${i.product.title.split(" ")[0]}PricePartner0IDHU${
                !!i?.isTestUser || process.env.REACT_APP_ENV === "development"
                  ? "_test"
                  : ""
              }`
            ];
        } else if (i?.type === "rb") {
          consumerTotalUK = prices[`rbPricePartner${rbDiscount}UK`];
          consumerTotalHU = prices[`rbPricePartner${rbDiscount}HU`];
          priceIdUK =
            prices[
              `rbPricePartner${rbDiscount}IDUK${
                !!i?.isTestUser || process.env.REACT_APP_ENV === "development"
                  ? "_test"
                  : ""
              }`
            ];
          priceIdHU =
            prices[
              `rbPricePartner${rbDiscount}IDHU${
                !!i?.isTestUser || process.env.REACT_APP_ENV === "development"
                  ? "_test"
                  : ""
              }`
            ];
        } else if (i?.type === "ip") {
          consumerTotalUK = i?.total;
          consumerTotalHU = i?.total;
          priceIdUK = i?.product?.products?.reduce(
            (prev, curr, index) =>
              prev +
              `${index > 0 ? "," : ""}` +
              new Array(curr?.quantity)
                .fill(
                  prices[
                    `i${curr?.ref}PricePartner0IDUK${
                      !!i?.isTestUser ||
                      process.env.REACT_APP_ENV === "development"
                        ? "_test"
                        : ""
                    }`
                  ]
                )
                .join(","),
            ""
          );
          priceIdHU = i?.product?.products?.reduce(
            (prev, curr, index) =>
              prev +
              `${index > 0 ? "," : ""}` +
              new Array(curr?.quantity)
                .fill(
                  prices[
                    `i${curr?.ref}PricePartner0IDHU${
                      !!i?.isTestUser ||
                      process.env.REACT_APP_ENV === "development"
                        ? "_test"
                        : ""
                    }`
                  ]
                )
                .join(","),
            ""
          );
        }
        return {
          ...i,
          consumerTotalUK,
          consumerTotalHU,
          priceIdUK,
          priceIdHU,
          totalUK:
            consumerTotalUK -
            consumerTotalUK * ((tierDiscount || tierLevel) / 100),
          totalHU:
            consumerTotalHU -
            consumerTotalHU * ((tierDiscount || tierLevel) / 100),
        };
      });
    }

    const payments = paymentNeededOrders.sort((a, b) =>
      new Date(a.created).getTime() < new Date(b.created).getTime() ? 1 : -1
    );
    store.dispatch(setPaymentsUpdating(false));
    return {
      payments,
      discount: tierDiscount || tierLevel,
      rbDiscount,
      qcADiscount,
      qcBDiscount,
      userCurrency,
    };
  }
};

export const getShipments = async (userID) => {
  store.dispatch(setShipmentsUpdating(true));

  const {
    data: { list: packagesByExpertID },
  } = await centralApi.get("packagesByExpertID", {
    params: { expertId: userID },
  });

  if (packagesByExpertID?.length) {
    const shipmentArr = packagesByExpertID
      .filter((i) => i?.deleted !== true)
      .sort((a, b) =>
        new Date(a.created).getTime() < new Date(b.created).getTime() ? 1 : -1
      );

    store.dispatch(setShipmentsUpdating(false));
    return shipmentArr;
  }
};

export function numberWithCommas(x) {
  return !!x ? x.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00";
}

export const getMaxRange = (number) => {
  return Array.from({ length: number.toString().length }).reduce(
    (prev, curr) => prev + "0",
    "1"
  );
};

export const requestPayment = async (
  userId,
  userEmail,
  sentRequestIds,
  setSentRequestIds,
  toast
) => {
  if (sentRequestIds.includes(userId)) return;

  if (confirm(`${i18n.t("portal_customers_table_menu_9")} <${userEmail}>`)) {
    setSentRequestIds((prev) => [...prev, userId]);

    const expert = await centralApi.get("userByID", {
      params: { userId: store.getState().user.id },
    });

    api
      .post("sendMailgun", {
        user_id: userId,
        to: userEmail,
        subject: "Request payment",
        template: "request payment",
        params: {
          button_link: encodeURI(
            `${
              process.env.REACT_APP_API_URL
            }s/emailpayments?email=${userEmail}&country=${
              expert.data?.country || expert.data?.shippingAddress?.country
            }`
          ),
          button_name: "Pay",
          expert_name: `${expert.data?.firstName} ${expert.data?.lastName}`,
        },
      })
      .then(() => {
        toast({
          title: i18n.t("portal_customers_table_menu_8"),
          position: "top-right",
          isClosable: true,
          status: "success",
        });
      })
      .catch(() => {
        toast({
          title: i18n.t("portal_customers_table_menu_4"),
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      });
  }
};
