import { Flex, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Spinner, Text } from '@chakra-ui/react'
import centralApi from "centralApi";
import api from "api";
import { Select as MultiSelect } from "chakra-react-select"
import { collection, getDocs, orderBy, query } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { areas, frequency, time } from 'variables/product'
import { db } from '../../../firebase'

const ProductModal = (props) => {
  const {
    product,
    oldProducts,
    setNewProducts,
    userID,
    packageID,
    treatmentID,
    isOpen,
    onClose
  } = props

  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isError, setIsError] = useState(false)
  const [validError, setValidError] = useState(false)
  const [products, setProducts] = useState([])
  const [selectedProduct, setSelectedProduct] = useState('')
  const [selectedTime, setSelectedTime] = useState('')
  const [selectedFrequency, setSelectedFrequency] = useState('')
  const [otherFrequency, setOtherFrequency] = useState('')
  const [selectedArea, setSelectedArea] = useState([])
  const [otherArea, setOtherArea] = useState('')
  const [qty, setQty] = useState(1)

  const checkFrequency = (selectedFrequency) => {
    Object.keys(frequency).map(i => {
      if (i === selectedFrequency) {
        return frequency[i]
      }
    })
    if (selectedFrequency === 'OTHER') {
      return otherFrequency
    }
    return selectedFrequency
  }

  const isOtherFrequency = (selectedFrequency) => !Object.keys(frequency).includes(selectedFrequency)

  const checkIsOtherArea = (selectedArea) => selectedArea?.find(i => i.value === 'other')

  const onSubmit = async () => {
    try {
      setIsSubmitting(true)

      if(!selectedProduct || !selectedTime || !selectedFrequency || !selectedArea?.length || !qty) {
        setValidError(true)
        return
      }

      let newProducts = {}

      const area = selectedArea
        .map(i => ({ key: i.value, val: i.label }))
        .map(i => i.key === 'other' ? { key: i.key, val: otherArea } : i)
      const {ref, ...foundProduct} = products.find(i => i.ref === selectedProduct)

      const newProduct = {
        [selectedProduct]: {
          ...foundProduct,
          time: selectedTime,
          frequency: checkFrequency(selectedFrequency),
          area,
          qty: Number(qty),
          product: selectedProduct?.replace('.', '')
        }
      }

      const newPackageProduct = {
        time: selectedTime,
        frequency: checkFrequency(selectedFrequency),
        area,
        qty: Number(qty),
        title: foundProduct?.title || '',
        value: selectedProduct
      }

      if (product) {
        newProducts = Object.fromEntries(Object.entries(oldProducts).map(i => {
          if (i[0] === selectedProduct) {
            return [i[0], newProduct[i[0]]]
          } else {
            return i
          }
        }))
      } else {
        newProducts = {
          ...oldProducts,
          ...newProduct
        }
      }

      await api.post("updateTreatment", {
        id: treatmentID,
        products: newProducts,
      });

      // const treatmentRef = doc(db, 'Users', userID, 'Treatments', treatmentID)
      // await updateDoc(treatmentRef, { products: newProducts })

      if (packageID) {
        const newPackageProducts = Object.values(oldProducts)
          .filter(i => i.product !== selectedProduct?.replace('.', ''))
          .map(i => ({
            area: i?.area || [],
            frequency: i?.frequency || '',
            qty: i?.qty || 1,
            time: i?.time || '',
            title: i?.title || '',
            value: i?.product || ''
          }))

        await centralApi.post('updatePackage', {id: packageID, products: [...newPackageProducts, newPackageProduct]})
      }

      setNewProducts(newProducts)
      onClose()
      setSelectedProduct('')
      setSelectedTime('')
      setSelectedFrequency('')
      setSelectedArea([])
    } catch (err) {
      console.log('err', err)
      setIsError(true)
    } finally {
      setIsSubmitting(false)
    }
  }

  const getProducts = async () => {
    try {
      const p = []
      const productsRef = collection(db, 'Products')
      const productsQuery = query(productsRef, orderBy('order', 'asc'))
      const productsSnap = await getDocs(productsQuery)

      if (!productsSnap.empty) {
        productsSnap.forEach(doc => {
          p.push(doc.data())
        })
      }

      const sortedP = p.sort((a, b) => parseInt(a.ref?.replace('p', '')) - parseInt(b.ref?.replace('p', '')))

      setProducts(sortedP)
    } catch (err) {
      console.log('err', err)
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getProducts()
  }, [])

  useEffect(() => {
    setSelectedProduct(product?.key || '')
    setSelectedTime(product?.time || '')
    
    if (isOtherFrequency(product?.frequency)) {
      setSelectedFrequency('OTHER')
      setOtherFrequency(product?.frequency)
    } else {
      setSelectedFrequency(product?.frequency)
      setOtherFrequency('')
    }


    const area = product?.area?.map(i => ({ value: i.key, label: i.val }))
      .map(i => {
        if (i.value === 'other') {
          setOtherArea(i.label)
          return { value: i.value, label: 'Other' }
        }
        return i
      })
    setSelectedArea(area || [])
    setQty(product?.qty)
  }, [product])

  useEffect(() => {
    if (!isOtherFrequency(selectedFrequency)) {
      setOtherFrequency('')
    }
  }, [selectedFrequency])

  useEffect(() => {
    if (!checkIsOtherArea(selectedArea)) {
      setOtherArea('')
    }
  }, [selectedArea])

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={() => {
        setValidError(false)
        onClose()
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{product ? t('portal_orders_new_edit_product') : t('portal_orders_new_add_product')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isLoading && (
            <Flex mx={"auto"}>
              <Spinner color="red.700" size="xl" />
            </Flex>
          )}
          {!isLoading && isError && (
            <p className="error" style={{ margin: '0 auto' }}>{t('portal_error_message')}</p>
            )}
          {!isLoading && !isError ? <>
            <Text fontWeight={500} mb={"5px"}>{t('portal_orders_new_product_title')}</Text>
            <Select
              placeholder={t('portal_orders_new_select_product_title')}
              w={'100%'}
              h="44px"
              borderColor={'#3D2541'}
              value={selectedProduct}
              onChange={(ev) => setSelectedProduct(ev.target.value)}
              disabled={product}
            >
              {products.length
                ? products.map(p => (
                  <option value={p.ref} key={p.ref}>{p.title}</option>
                ))
                : (
                  <option value={"rb"}>{t('portal_orders_new_products_choise_1')}</option>
                )
              }
            </Select>

            <Text fontWeight={500} mb={"5px"} mt={"10px"}>{t('portal_orders_new_time_title')}</Text>
            <Select
              placeholder={t('portal_orders_new_select_time_title')}
              w={'100%'}
              h="44px"
              borderColor={'#3D2541'}
              value={selectedTime}
              onChange={(ev) => setSelectedTime(ev.target.value)}
            >
              {Object.entries(time).map(([key, value]) => (
                <option value={key} key={key}>{value}</option>
              ))}
            </Select>

            <Text fontWeight={500} mb={"5px"} mt={"10px"}>{t('portal_orders_new_quantity_title')}</Text>
            <Input
              h={"44px"}
              borderColor={'#3D2541'}
              _placeholder={{ color: '#333' }}
              placeholder={t('portal_orders_new_quantity_placeholder')}
              type='number'
              required
              min={1}
              value={qty}
              onChange={(ev) => setQty(ev.target.value)}
            />

            <Text fontWeight={500} mb={"5px"} mt={"10px"}>{t('portal_orders_new_frequency_title')}</Text>
            <Select
              placeholder={t('portal_orders_new_select_frequency_title')}
              w={'100%'}
              h="44px"
              borderColor={'#3D2541'}
              value={selectedFrequency}
              onChange={(ev) => setSelectedFrequency(ev.target.value)}
            >
              {Object.entries(frequency).map(([key, value]) => (
                <option value={key} key={key}>{value}</option>
              ))}
            </Select>
            { selectedFrequency === 'OTHER' &&
              (<>
              <Text fontWeight={500} mb={"5px"} mt={"10px"}>{t('portal_orders_new_frequency_other_title')}</Text>
            <Input
              h={"44px"}
              borderColor={'#3D2541'}
              _placeholder={{ color: '#333' }}
              placeholder={t('portal_orders_new_frequency_other_placeholder')}
              type='text'
              required
              value={otherFrequency}
              onChange={(ev) => setOtherFrequency(ev.target.value)}
            />
            </>)
            }

            <Text fontWeight={500} mb={"5px"} mt={"10px"}>{t('portal_orders_new_area_title')}</Text>
            <MultiSelect
              isMulti
              value={selectedArea}
              onChange={(value) => setSelectedArea(value)}
              options={areas?.map(i => ({
                value: i.key,
                label: i.val
              }))}
              placeholder={t('portal_orders_new_select_area_title')}
              chakraStyles={{
                container: (provided) => ({
                  ...provided,
                  width: '100%',
                  height: 'auto',
                  borderColor: '#3D2541'
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                  bg: "transparent",
                  px: 2,
                  cursor: "inherit"
                }),
                indicatorSeparator: (provided) => ({
                  ...provided,
                  display: "none"
                }),
                placeholder: (provided) => ({
                  ...provided,
                  color: '#333'
                }),
                inputContainer: (provided) => ({
                  ...provided,
                  height: '40px',
                }),
              }}
            />

            { checkIsOtherArea(selectedArea) &&
              (<>
                <Text fontWeight={500} mb={"5px"} mt={"10px"}>{t('portal_orders_new_area_other_title')}</Text>
                <Input
                  h={"44px"}
                  borderColor={'#3D2541'}
                  _placeholder={{ color: '#333' }}
                  placeholder={t('portal_orders_new_area_other_placeholder')}
                  type='text'
                  required
                  value={otherArea}
                  onChange={(ev) => setOtherArea(ev.target.value)}
                />
              </>)
            }

            {validError && (
              <p 
                className="error" 
                style={{ margin: '20px auto 0', opacity: 1, color: 'red' }}
              >
                {t('portal_orders_new_error_1')}
              </p>
            )}
          </> : null}
        </ModalBody>

        <ModalFooter justifyContent={"center"} >
          <button
            type="button"
            className="custom-btn"
            onClick={onSubmit}
            disabled={isSubmitting}
          >
            {t('portal_orders_new_save_button')}
          </button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ProductModal