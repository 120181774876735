import React from "react";
import { Center, Flex, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import LibiLogo from "assets/img/libiLogoBlack.png";
import { useTranslation } from "react-i18next";

export default function WaitingVerification() {
  const { t } = useTranslation();

  return (
    <Flex
      direction="column"
      alignItems={"center"}
      justifyContent="center"
      bg={"#fff"}
      h="100vh"
      textAlign={"center"}
    >
      <Center mb="8px">
        <img width={"135px"} src={LibiLogo} alt="Logo" />
      </Center>
      <h2 className="title">{t('portal_agreement_signed_top_title')}</h2>
      <Text fontSize={15} fontWeight={500} color="#92001A" mt="6px" mb="15px">{t('portal_agreement_signed_top_description')}</Text>
      <Text mb="15px" dangerouslySetInnerHTML={{__html: t('portal_agreement_signed_text')}}></Text>
      <p>{t('portal_agreement_signed_go_profile_title')}&nbsp;
        <Link to="/auth/profile/">
          <Text
            as={"span"}
            color={"#92001A"}
            fontSize="16px"
            fontWeight={"600"}
            textDecoration="underline"
          >
            {t('portal_agreement_signed_go_profile_link')}
          </Text>
        </Link>
      </p>
    </Flex>
  );
}
