import { memo } from "react";
import { Text } from "@chakra-ui/react";
import PropTypes from "prop-types";

const TextInfoRow = ({ title, value }) => (
  <Text fontSize={15} color={"#3D2541"} mb={"8px"}>
    <b>{title}</b>&nbsp;
    {value}
  </Text>
);
TextInfoRow.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
};

export default memo(TextInfoRow);
