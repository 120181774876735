import React, { useState } from "react";
import { ReactComponent as PlusIcon } from "assets/svg/plus-circle.svg";
import { ReactComponent as MinusIcon } from "assets/svg/minus-circle.svg";

function Counter({value = 0, setValue}) {
  const [count, setCount] = useState(value);

  const handleIncrement = () => {
    setCount(count + 1);
    setValue && setValue(count + 1);
  };

  const handleDecrement = () => {
    setCount(Math.max(count - 1, 0));
    setValue && setValue(Math.max(count - 1, 0));
  };

  const handleInputChange = (event) => {
    setCount(Math.max(parseInt(event.target.value), 0));
    setValue && setValue(Math.max(parseInt(event.target.value), 0));
  };

  return (
    <div className="counter">
      <input 
        type="number" 
        value={count} 
        onChange={handleInputChange} 
        min={0} 
        className="counter-input"
      />
      <PlusIcon className="counter-increment" onClick={handleIncrement} />
      <MinusIcon className="counter-decrement" onClick={handleDecrement} />
    </div>
  );
}

export default Counter;
