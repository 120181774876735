import React, { forwardRef, useEffect, useRef } from 'react'
import { Check } from 'components/Icons/Icons';

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <label className="custom-checkbox">
      <input type="checkbox" ref={resolvedRef} {...rest} />
      <span>
        <Check />
        <span />
        <span />
      </span>
    </label>
  );
});

export default IndeterminateCheckbox
