import axios from 'axios'

export const API_URL_1 = process.env.REACT_APP_STORE_URL_1;
export const API_URL_2 = process.env.REACT_APP_STORE_URL_2;
export const API_TOKEN_1 = process.env.REACT_APP_STORE_TOKEN_1;
export const API_TOKEN_2 = process.env.REACT_APP_STORE_TOKEN_2;

export const api1 = axios.create({
  baseURL: API_URL_1
})

export const api2 = axios.create({
  baseURL: API_URL_2
})
