import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Select,
  Spinner,
  Textarea,
} from "@chakra-ui/react";
import centralApi from "centralApi";
import moment from "moment";
import { Select as MultiSelect } from "chakra-react-select";
import { PlusCircle } from "components/Icons/Icons";
import { memo, useCallback, useEffect, useState, useMemo } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useHistory, useParams } from "react-router-dom";
import { ShipmentFormSchema, ShipmentSubmitFormSchema } from "./ShipmentScheme";
import ProductList from "./ProductList";
import taxes from "./taxes";

const currencyOptions = [
  { value: "USD", label: "USD" },
  { value: "EUR", label: "EUR" },
  { value: "GBP", label: "GBP" },
];

const getNewProduct = (number = 1) => ({
  number,
  description: "",
  price: 1,
  quantity: {
    value: 1,
    unitOfMeasurement: "PCS",
  },
  commodityCodes: [
    {
      typeCode: "outbound",
      value: "0000.00.00.00",
    },
  ],
  customerReferences: [
    {
      typeCode: "BRD",
      value: "N0 NNNNNNNN",
    },
  ],
  manufacturerCountry: "HU",
  weight: {
    grossValue: 0.01,
    netValue: 0.01,
  },
});

const CreateShipment = () => {
  const history = useHistory();
  const savedData = history?.location?.state?.data;

  const [shipmentDate, setShipmentDate] = useState("");
  const [responseErrorMessage, setResponseErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const minDate = useMemo(() => new Date().toISOString().split("T")[0], []);
  const minDateAndTime = useMemo(
    () =>
      new Date()
        .toISOString()
        .slice(0, new Date().toISOString().lastIndexOf(":")),
    []
  );

  const { orderId = "" } = useParams();

  const {
    handleSubmit,
    register,
    control,
    reset,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(ShipmentSubmitFormSchema),
  });

  const products = watch("products");
  const packagesToChooseFrom = watch("packagesToChooseFrom");
  const lineItems = watch("lineItems");
  const customerReferences = watch("customerReferences");
  const destinationType = watch("destinationType");

  const onShipmentDateChange = useCallback(
    (ev) => {
      setShipmentDate(ev.target.value);
    },
    [shipmentDate, setShipmentDate]
  );

  const onAddReference = useCallback(() => {
    if (!customerReferences) {
      setValue("customerReferences", [{ value: "" }], { shouldValidate: true });
    } else if (customerReferences?.length < 3) {
      setValue("customerReferences", [...customerReferences, { value: "" }], {
        shouldValidate: true,
      });
    }
  }, [setValue, customerReferences]);

  const onAddProduct = useCallback(() => {
    if (!lineItems) {
      setValue("lineItems", [getNewProduct()]);
    } else {
      setValue("lineItems", [
        ...lineItems,
        getNewProduct(lineItems.length + 1),
      ]);
    }
  }, [lineItems]);

  const calcTotalPrice = useCallback(() => {
    const sumPrices = lineItems.reduce(
      (acc, item) => acc + Number(item.price),
      0
    );
    const taxValue =
      taxes[getValues("receiverDetails.postalAddress.countryCode")] || 0;
    let totalPrice;
    if (getValues("receiverDetails.postalAddress.countryCode") === "GB") {
      totalPrice = Math.round(sumPrices * (1 - taxValue) * 10) / 10;
    } else {
      totalPrice = Math.round(sumPrices * (1 + taxValue) * 10) / 10;
    }
    setValue("declaredValue", totalPrice);
  }, [lineItems]);

  const getShipmentPrepare = async (orderId, shipmentDate) => {
    try {
      setResponseErrorMessage(null);
      setIsLoading(true);
      const shipmentResponse = await centralApi.get(
        `shipment/${orderId}/prepare`,
        { params: { plannedShippingDate: shipmentDate } }
      );
      reset(
        ShipmentFormSchema.parse({
          orderId,
          ...shipmentResponse.data,
        })
      );
    } catch (err) {
      console.log("err", err);
      if (err?.response?.data?.message) {
        setResponseErrorMessage(err.response.data.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = (data) => {
    history.push({
      pathname: `/admin/orders/shipment-preview/${orderId}`,
      state: {
        data: ShipmentSubmitFormSchema.parse({
          ...data,
          plannedShippingDate: moment(shipmentDate).format("yyyy-MM-DD"),
        }),
      },
    });
  };

  const removeProductFromList = useCallback(
    (index) => (event) => {
      event.preventDefault();

      if (confirm("Do you really want to delete product?")) {
        setValue(
          "lineItems",
          lineItems.filter((item, itemIndex) => itemIndex !== index)
        );
      }
    },
    [setValue, lineItems]
  );

  useEffect(() => {
    if (savedData) {
      setShipmentDate(savedData.plannedShippingDate);
      reset(
        ShipmentSubmitFormSchema.parse({
          ...savedData,
        })
      );
    } else if (orderId && shipmentDate) {
      getShipmentPrepare(orderId, shipmentDate);
    }
  }, [orderId, shipmentDate, savedData]);

  return (
    <>
      <Flex flexDirection="column" gap={5} mb={responseErrorMessage ? 1 : 5}>
        <h2 className="title">Creating a shipment</h2>
        <Flex flexDirection="column" justifyContent="flex-end">
          <FormLabel fontSize={"15px"}>Planned Shipping Date</FormLabel>
          <input
            disabled={Boolean(savedData)}
            className="custom-input shipment-create-date-input"
            type="date"
            value={shipmentDate}
            onChange={onShipmentDateChange}
            min={minDate}
          />
        </Flex>
      </Flex>
      {responseErrorMessage && (
        <Alert status="error" mt={4}>
          <AlertIcon />
          <AlertTitle>{responseErrorMessage}</AlertTitle>
        </Alert>
      )}
      {isLoading && (
        <Flex alignItems="center" justifyContent="center" mt="20px">
          <Spinner color="red.700" size="xl" />
        </Flex>
      )}
      {products?.length > 0 && !isLoading && !responseErrorMessage && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex flexDirection="column" gap={4} my={3}>
            <Flex flexDirection="column" gap={3}>
              <h2 className="shipment-subtitle" style={{ fontSize: "18px" }}>
                Shipment Details
              </h2>
              <Grid templateColumns="repeat(2, 1fr)" gap={3}>
                <GridItem>
                  <FormControl isInvalid={errors?.orderId}>
                    <FormLabel fontSize={"15px"}>Order ID</FormLabel>
                    <Input
                      type="text"
                      disabled
                      borderColor={"#3D2541"}
                      height="44px"
                      placeholder="Order ID"
                      value={orderId}
                      {...register("orderId")}
                    />
                    {errors?.orderId && (
                      <FormErrorMessage>
                        {errors?.orderId?.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl isInvalid={errors?.plannedShippingTime}>
                    <FormLabel fontSize={"15px"}>
                      Planned Shipping Time
                    </FormLabel>
                    <Input
                      min={minDateAndTime}
                      className="custom-input"
                      style={{
                        padding: "5px 10px",
                        height: "44px",
                        borderColor: "#3D2541",
                      }}
                      type="time"
                      {...register("plannedShippingTime")}
                    />
                    {errors?.plannedShippingTime && (
                      <FormErrorMessage>
                        {errors?.plannedShippingTime?.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
                {destinationType !== "eu" && (
                  <>
                    <GridItem>
                      <FormControl isInvalid={errors?.invoice?.date}>
                        <FormLabel fontSize={"15px"}>Invoice Date</FormLabel>
                        <Input
                          min={minDate}
                          className="custom-input"
                          style={{
                            padding: "5px 10px",
                            height: "44px",
                            borderColor: "#3D2541",
                          }}
                          type="date"
                          {...register("invoice.date", {
                            setValueAs: (val) =>
                              moment(val).format("yyyy-MM-DD"),
                          })}
                        />
                        {errors?.invoice?.date && (
                          <FormErrorMessage>
                            {errors?.invoice?.date?.message}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl isInvalid={errors?.invoice?.number}>
                        <FormLabel fontSize={"15px"}>Invoice Number</FormLabel>
                        <Input
                          {...register("invoice.number")}
                          type="text"
                          borderColor={"#3D2541"}
                          height="44px"
                          placeholder="Invoice Number"
                        />
                        {errors?.invoice?.number && (
                          <FormErrorMessage>
                            {errors?.invoice?.number?.message}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </GridItem>
                  </>
                )}
                {customerReferences?.map((_, index) => (
                  <GridItem key={index}>
                    <FormControl
                      isInvalid={errors?.customerReferences?.[index]?.value}
                    >
                      <FormLabel fontSize={"15px"}>
                        Shipment References №{index + 1}
                      </FormLabel>
                      <Input
                        {...register(`customerReferences[${index}].value`)}
                        type="text"
                        borderColor={"#3D2541"}
                        height="44px"
                        placeholder="Shipment References"
                      />
                      {errors?.customerReferences?.[index]?.value && (
                        <FormErrorMessage>
                          {errors?.customerReferences?.[index]?.value?.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </GridItem>
                ))}
              </Grid>
              {(!customerReferences || customerReferences.length < 3) && (
                <Button
                  leftIcon={<PlusCircle />}
                  borderRadius="0.375rem"
                  h="44px"
                  type="button"
                  colorScheme="red"
                  onClick={onAddReference}
                >
                  Add reference
                </Button>
              )}
            </Flex>
            <Flex flexDirection="column" gap={3}>
              <h2 className="shipment-subtitle" style={{ fontSize: "18px" }}>
                Receiver Details
              </h2>
              <Grid templateColumns="repeat(2, 1fr)" gap={3}>
                <GridItem>
                  <FormControl isInvalid={errors?.receiverDetails?.typeCode}>
                    <FormLabel fontSize={"15px"}>Business Contact</FormLabel>
                    <Input
                      {...register("receiverDetails.typeCode")}
                      type="text"
                      disabled
                      borderColor={"#3D2541"}
                      height="44px"
                      placeholder="Business Contact"
                    />
                    {errors?.receiverDetails?.typeCode && (
                      <FormErrorMessage>
                        {errors?.receiverDetails?.typeCode?.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
              </Grid>
            </Flex>
            <Flex flexDirection="column" gap={3}>
              <h2 className="shipment-subtitle" style={{ fontSize: "18px" }}>
                Receiver Details - Contact Information
              </h2>
              <Grid templateColumns="repeat(2, 1fr)" gap={3}>
                <GridItem>
                  <FormControl
                    isInvalid={
                      errors?.receiverDetails?.contactInformation?.fullName
                    }
                  >
                    <FormLabel fontSize={"15px"}>Full Name</FormLabel>
                    <Input
                      {...register(
                        "receiverDetails.contactInformation.fullName"
                      )}
                      type="text"
                      borderColor={"#3D2541"}
                      height="44px"
                      placeholder="Full Name"
                    />
                    {errors?.receiverDetails?.contactInformation?.fullName && (
                      <FormErrorMessage>
                        {
                          errors?.receiverDetails?.contactInformation?.fullName
                            ?.message
                        }
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl
                    isInvalid={
                      errors?.receiverDetails?.contactInformation?.companyName
                    }
                  >
                    <FormLabel fontSize={"15px"}>Company Name</FormLabel>
                    <Input
                      {...register(
                        "receiverDetails.contactInformation.companyName"
                      )}
                      type="text"
                      borderColor={"#3D2541"}
                      height="44px"
                      placeholder="Company Name"
                    />
                    {errors?.receiverDetails?.contactInformation
                      ?.companyName && (
                      <FormErrorMessage>
                        {
                          errors?.receiverDetails?.contactInformation
                            ?.companyName?.message
                        }
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl
                    isInvalid={
                      errors?.receiverDetails?.contactInformation?.phone
                    }
                  >
                    <FormLabel fontSize={"15px"}>Contact Phone</FormLabel>
                    <Input
                      {...register("receiverDetails.contactInformation.phone")}
                      type="text"
                      borderColor={"#3D2541"}
                      height="44px"
                      placeholder="Contact Phone"
                    />
                    {errors?.receiverDetails?.contactInformation?.phone && (
                      <FormErrorMessage>
                        {
                          errors?.receiverDetails?.contactInformation?.phone
                            ?.message
                        }
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl
                    isInvalid={
                      errors?.receiverDetails?.contactInformation?.mobilePhone
                    }
                  >
                    <FormLabel fontSize={"15px"}>Mobile Phone</FormLabel>
                    <Input
                      {...register(
                        "receiverDetails.contactInformation.mobilePhone"
                      )}
                      type="text"
                      borderColor={"#3D2541"}
                      height="44px"
                      placeholder="Mobile Phone"
                    />
                    {errors?.receiverDetails?.contactInformation
                      ?.mobilePhone && (
                      <FormErrorMessage>
                        {
                          errors?.receiverDetails?.contactInformation
                            ?.mobilePhone?.message
                        }
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl
                    isInvalid={
                      errors?.receiverDetails?.contactInformation?.email
                    }
                  >
                    <FormLabel fontSize={"15px"}>email</FormLabel>
                    <Input
                      {...register("receiverDetails.contactInformation.email")}
                      type="text"
                      borderColor={"#3D2541"}
                      height="44px"
                      placeholder=" email"
                    />
                    {errors?.receiverDetails?.contactInformation?.email && (
                      <FormErrorMessage>
                        {
                          errors?.receiverDetails?.contactInformation?.email
                            ?.message
                        }
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
              </Grid>
            </Flex>
            <Flex flexDirection="column" gap={3}>
              <h2 className="shipment-subtitle" style={{ fontSize: "18px" }}>
                Receiver Details - Postal Address
              </h2>
              <Grid templateColumns="repeat(2, 1fr)" gap={3}>
                <GridItem>
                  <FormControl
                    isInvalid={
                      errors?.receiverDetails?.postalAddress?.countryCode
                    }
                  >
                    <FormLabel fontSize={"15px"}>Country Code</FormLabel>
                    <Input
                      {...register("receiverDetails.postalAddress.countryCode")}
                      type="text"
                      borderColor={"#3D2541"}
                      height="44px"
                      placeholder="Country Code"
                    />
                    {errors?.receiverDetails?.postalAddress?.countryCode && (
                      <FormErrorMessage>
                        {
                          errors?.receiverDetails?.postalAddress?.countryCode
                            ?.message
                        }
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl
                    isInvalid={
                      errors?.receiverDetails?.postalAddress?.countryName
                    }
                  >
                    <FormLabel fontSize={"15px"}>Country Name</FormLabel>
                    <Input
                      {...register("receiverDetails.postalAddress.countryName")}
                      type="text"
                      borderColor={"#3D2541"}
                      height="44px"
                      placeholder="Country Name"
                    />
                    {errors?.receiverDetails?.postalAddress?.countryName && (
                      <FormErrorMessage>
                        {
                          errors?.receiverDetails?.postalAddress?.countryName
                            ?.message
                        }
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl
                    isInvalid={
                      errors?.receiverDetails?.postalAddress?.provinceCode
                    }
                  >
                    <FormLabel fontSize={"15px"}>Province Code</FormLabel>
                    <Input
                      {...register(
                        "receiverDetails.postalAddress.provinceCode"
                      )}
                      type="text"
                      borderColor={"#3D2541"}
                      height="44px"
                      placeholder="Province Code"
                    />
                    {errors?.receiverDetails?.postalAddress?.provinceCode && (
                      <FormErrorMessage>
                        {
                          errors?.receiverDetails?.postalAddress?.provinceCode
                            ?.message
                        }
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl
                    isInvalid={
                      errors?.receiverDetails?.postalAddress?.provinceName
                    }
                  >
                    <FormLabel fontSize={"15px"}>Province Name</FormLabel>
                    <Input
                      {...register(
                        "receiverDetails.postalAddress.provinceName"
                      )}
                      type="text"
                      borderColor={"#3D2541"}
                      height="44px"
                      placeholder="Province Name"
                    />
                    {errors?.receiverDetails?.postalAddress?.provinceName && (
                      <FormErrorMessage>
                        {
                          errors?.receiverDetails?.postalAddress?.provinceName
                            ?.message
                        }
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl
                    isInvalid={errors?.receiverDetails?.postalAddress?.cityName}
                  >
                    <FormLabel fontSize={"15px"}>City</FormLabel>
                    <Input
                      {...register("receiverDetails.postalAddress.cityName")}
                      type="text"
                      borderColor={"#3D2541"}
                      height="44px"
                      placeholder="City"
                    />
                    {errors?.receiverDetails?.postalAddress?.cityName && (
                      <FormErrorMessage>
                        {
                          errors?.receiverDetails?.postalAddress?.cityName
                            ?.message
                        }
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl
                    isInvalid={
                      errors?.receiverDetails?.postalAddress?.addressLine1
                    }
                  >
                    <FormLabel fontSize={"15px"}>Address Line 1</FormLabel>
                    <Input
                      {...register(
                        "receiverDetails.postalAddress.addressLine1"
                      )}
                      type="text"
                      borderColor={"#3D2541"}
                      height="44px"
                      placeholder="Address Line 1"
                    />
                    {errors?.receiverDetails?.postalAddress?.addressLine1 && (
                      <FormErrorMessage>
                        {
                          errors?.receiverDetails?.postalAddress?.addressLine1
                            ?.message
                        }
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl
                    isInvalid={
                      errors?.receiverDetails?.postalAddress?.addressLine2
                    }
                  >
                    <FormLabel fontSize={"15px"}>Address Line 2</FormLabel>
                    <Input
                      {...register(
                        "receiverDetails.postalAddress.addressLine2"
                      )}
                      type="text"
                      borderColor={"#3D2541"}
                      height="44px"
                      placeholder="Address Line 2"
                    />
                    {errors?.receiverDetails?.postalAddress?.addressLine2 && (
                      <FormErrorMessage>
                        {
                          errors?.receiverDetails?.postalAddress?.addressLine2
                            ?.message
                        }
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl
                    isInvalid={
                      errors?.receiverDetails?.postalAddress?.addressLine3
                    }
                  >
                    <FormLabel fontSize={"15px"}>Address Line 3</FormLabel>
                    <Input
                      {...register(
                        "receiverDetails.postalAddress.addressLine3"
                      )}
                      type="text"
                      borderColor={"#3D2541"}
                      height="44px"
                      placeholder="Address Line 3"
                    />
                    {errors?.receiverDetails?.postalAddress?.addressLine3 && (
                      <FormErrorMessage>
                        {
                          errors?.receiverDetails?.postalAddress?.addressLine3
                            ?.message
                        }
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl
                    isInvalid={
                      errors?.receiverDetails?.postalAddress?.postalCode
                    }
                  >
                    <FormLabel fontSize={"15px"}>Postal Code</FormLabel>
                    <Input
                      {...register("receiverDetails.postalAddress.postalCode")}
                      type="text"
                      borderColor={"#3D2541"}
                      height="44px"
                      placeholder="Postal Code"
                    />
                    {errors?.receiverDetails?.postalAddress?.postalCode && (
                      <FormErrorMessage>
                        {
                          errors?.receiverDetails?.postalAddress?.postalCode
                            ?.message
                        }
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl
                    isInvalid={
                      errors?.receiverDetails?.postalAddress?.countyName
                    }
                  >
                    <FormLabel fontSize={"15px"}>County</FormLabel>
                    <Input
                      {...register("receiverDetails.postalAddress.countyName")}
                      type="text"
                      borderColor={"#3D2541"}
                      height="44px"
                      placeholder="County"
                    />
                    {errors?.receiverDetails?.postalAddress?.countyName && (
                      <FormErrorMessage>
                        {
                          errors?.receiverDetails?.postalAddress?.countyName
                            ?.message
                        }
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
              </Grid>
            </Flex>
            <Flex flexDirection="column" gap={3}>
              <h2 className="shipment-subtitle" style={{ fontSize: "18px" }}>
                Summarize the contents of your shipment (in detail)
              </h2>
              <Flex gap={3}>
                <FormControl isInvalid={errors?.content?.incoterm}>
                  <FormLabel fontSize={"15px"}>Incoterm</FormLabel>
                  <Input
                    {...register("content.incoterm")}
                    type="text"
                    disabled
                    borderColor={"#3D2541"}
                    height="44px"
                    placeholder="Incoterm"
                  />
                  {errors?.content?.incoterm && (
                    <FormErrorMessage>
                      {errors?.content?.incoterm?.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
                <FormControl isInvalid={errors?.content?.description}>
                  <FormLabel fontSize={"15px"}>Description</FormLabel>
                  <Textarea
                    {...register("content.description")}
                    type="text"
                    borderColor={"#3D2541"}
                    height="44px"
                    placeholder="Description"
                  />
                  {errors?.content?.description && (
                    <FormErrorMessage>
                      {errors?.content?.description?.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Flex>
            </Flex>
            <Flex flexDirection="column" gap={3}>
              <h2 className="shipment-subtitle" style={{ fontSize: "18px" }}>
                Common Fields
              </h2>
              <Grid templateColumns="repeat(2, 1fr)" gap={3}>
                <GridItem>
                  <FormControl isInvalid={errors?.product}>
                    <FormLabel fontSize={"15px"}>Type of shipment</FormLabel>
                    <Controller
                      name="product"
                      control={control}
                      render={({ field }) => (
                        <Select
                          h="44px"
                          borderColor={"#3D2541"}
                          value={field.value?.id}
                          onChange={(ev) => {
                            const product = products.find(
                              (product) =>
                                product.id === Number(ev.target.value)
                            );
                            if (product) field.onChange(product);
                          }}
                        >
                          {products?.map((product) => (
                            <option
                              value={product.id}
                              key={product.id}
                            >{`${product.productName} - ${product.price}`}</option>
                          ))}
                        </Select>
                      )}
                    />
                    {errors?.product && (
                      <FormErrorMessage>
                        {errors?.product?.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl isInvalid={errors?.chosenPackages}>
                    <FormLabel fontSize={"15px"}>Selected packaging</FormLabel>
                    <Controller
                      name="chosenPackages"
                      control={control}
                      render={({ field }) => (
                        <MultiSelect
                          isMulti
                          ref={field.ref}
                          value={field.value?.map((i) => ({
                            value: i.id,
                            label: i.value,
                          }))}
                          onChange={(value) => {
                            field.onChange(
                              value.map((i) => ({
                                id: i.value,
                                value: i.label,
                              }))
                            );
                          }}
                          options={packagesToChooseFrom?.map((i) => ({
                            value: i.id,
                            label: i.value,
                          }))}
                          chakraStyles={{
                            container: (provided) => ({
                              ...provided,
                              width: "100%",
                              height: "auto",
                              borderColor: "#3D2541",
                            }),
                            dropdownIndicator: (provided) => ({
                              ...provided,
                              bg: "transparent",
                              px: 2,
                              cursor: "inherit",
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none",
                            }),
                            placeholder: (provided) => ({
                              ...provided,
                              color: "#333",
                            }),
                            inputContainer: (provided) => ({
                              ...provided,
                              height: "40px",
                            }),
                          }}
                        />
                      )}
                    />
                    {errors?.chosenPackages && (
                      <FormErrorMessage>
                        {errors?.chosenPackages?.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl isInvalid={errors?.declaredValue}>
                    <FormLabel fontSize={"15px"}>Total Price</FormLabel>
                    <Input
                      {...register("declaredValue")}
                      disabled
                      type="number"
                      borderColor={"#3D2541"}
                      height="44px"
                      placeholder="Total Price"
                    />
                    {errors?.declaredValue && (
                      <FormErrorMessage>
                        {errors?.declaredValue?.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl isInvalid={errors?.declaredValueCurrency}>
                    <FormLabel fontSize={"15px"}>Currency</FormLabel>
                    <Controller
                      name="declaredValueCurrency"
                      control={control}
                      render={({ field }) => (
                        <Select
                          h="44px"
                          borderColor={"#3D2541"}
                          value={field.value}
                          onChange={field.onChange}
                        >
                          {currencyOptions?.map((cur) => (
                            <option
                              value={cur.value}
                              key={cur.value}
                            >{`${cur.label}`}</option>
                          ))}
                        </Select>
                      )}
                    />
                    {errors?.declaredValueCurrency && (
                      <FormErrorMessage>
                        {errors?.declaredValueCurrency?.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl isInvalid={errors?.destinationType}>
                    <FormLabel fontSize={"15px"}>Destination Type</FormLabel>
                    <Input
                      {...register("destinationType")}
                      type="text"
                      disabled
                      borderColor={"#3D2541"}
                      height="44px"
                      placeholder="Destination Type"
                    />
                    {errors?.destinationType && (
                      <FormErrorMessage>
                        {errors?.destinationType?.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
              </Grid>
            </Flex>
            <Flex flexDirection="column" gap={3}>
              <h2 className="shipment-subtitle" style={{ fontSize: "18px" }}>
                Products
              </h2>
              <ProductList
                errors={errors}
                lineItems={lineItems}
                removeProductFromList={removeProductFromList}
                calcTotalPrice={calcTotalPrice}
                register={register}
              />
              <Button
                leftIcon={<PlusCircle />}
                borderRadius="0.375rem"
                h="44px"
                type="button"
                colorScheme="red"
                onClick={onAddProduct}
              >
                Add product
              </Button>
            </Flex>
          </Flex>
          <Flex my={4}>
            <Button
              borderRadius="0.375rem"
              h="44px"
              w="100%"
              type="submit"
              colorScheme="red"
            >
              Create
            </Button>
          </Flex>
        </form>
      )}
    </>
  );
};

export default memo(CreateShipment);
