import { useCallback } from 'react';
import { Flex } from '@chakra-ui/react'
import { BackArrow } from 'components/Icons/Icons'
import UserAnswers from 'components/UserAnswers/UserAnswers'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'

const Answer = () => {
  const { t } = useTranslation()
  const {userId, answerId} = useParams()
  const history = useHistory()

  const goBack = useCallback(() => {
    if (history.length > 1) {
      history.goBack();
    } else {
      window.close();
    }
  }, [history]);

  return (
    <Flex flexDirection="column">
      <div onClick={goBack} className='back-link'>
        <BackArrow /> {t('portal_customers_view_back_button')}
      </div>
      <div>
        <h2 className="title">Answer</h2>
        {/* <p className="descr">{t('portal_customers_view_top_description')}</p> */}
      </div>

      <hr className='hr' />

      <UserAnswers userId={userId} answerId={answerId} />
    </Flex>
  )
}

export default Answer