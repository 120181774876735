import {
  Box,
  Flex,
  Link,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import logo from '../../assets/svg/LibiLogo.svg'
import { useTranslation } from "react-i18next";
import { HiSwitchHorizontal, HiOutlineRefresh } from "react-icons/hi";
import { Headphones } from "components/Icons/Icons";
import { HomeIcon } from "components/Icons/Icons";

const SidebarContent = ({ routes }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const { user } = useSelector((store) => store);

  const toast = useToast()

  const [state, setState] = React.useState({});

  const activeRoute = (routeName) => location.pathname.includes(routeName) ? "active" : ""

  const Links = () => {
    return routes.map((prop, key) => {
      if (prop.redirect || !prop.show) {
        return null;
      }
      if (prop.hide && !user.id) {
        return null;
      }
      if (prop.category) {
        var st = {};
        st[prop["state"]] = !state[prop.state];
        return (
          <div key={prop.name}>
            <Text
              display={{ xl: 'initial', sm: 'none' }}
              color={isActive ? '#92001A' : 'rgba(61, 37, 65, 0.5)'}
              fontWeight="500"
              fontSize={"16px"}
              lineHeight="135%"
            >
              {t(prop.name)}
            </Text>
            {createLinks(prop.views)}
          </div>
        );
      }

      const isActive = activeRoute(prop.layout + prop.path) === "active";

      return (
        <NavLink to={prop.layout + prop.path} key={prop.name}>
          <Flex
            gap={{ xl: "14px", sm: 0 }}
            alignItems={"center"}
            justifyContent={{ xl: 'flex-start', sm: 'center' }}
            mb="26px"
            className="sidebar-title"
            cursor={'pointer'}
          >
            <Box color={isActive ? '#92001A' : 'rgba(61, 37, 65, 0.5)'}>
              {prop.icon}
            </Box>
            <Text
              display={{ xl: 'initial', sm: 'none' }}
              color={isActive ? '#92001A' : 'rgba(61, 37, 65, 0.5)'}
              fontWeight="500"
              fontSize={"16px"}
              lineHeight="135%"
            >
              {t(prop.name)}
            </Text>
            <Box as="span" display={{ xl: 'none', sm: 'flex' }}>{t(prop.name)}</Box>
          </Flex>
        </NavLink>
      );
    });
  };

  const showExperts = user.roleId == 2 && user.id === user.originalId

  return (
    <>
      <Box
        px={{ xl: 0, sm: '3px' }}
        mt={{ xl: 0, sm: '17px' }}
        mb={{ xl: "42px", sm: '17px' }}
        ml={{ xl: "-50px", sm: 0 }}>
        <Link
          href={`${process.env.PUBLIC_URL}/admin/dashboard`}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <img src={logo} />
        </Link>
      </Box>
      <Stack direction="column" >
        <Box>
          <NavLink to={showExperts ? "/admin/experts" : "/admin/dashboard"}>
            <Flex
              gap={{ xl: "14px", sm: 0 }}
              alignItems={"center"}
              justifyContent={{ xl: 'flex-start', sm: 'center' }}
              mb="26px"
              className="sidebar-title"
              cursor={'pointer'}
            >
              <Box color={activeRoute(showExperts ? "/admin/experts" : "/admin/dashboard") === "active" ? '#92001A' : 'rgba(61, 37, 65, 0.5)'}>
                <HomeIcon color="inherit" />
              </Box>
              <Text
                display={{ xl: 'initial', sm: 'none' }}
                color={activeRoute(showExperts ? "/admin/experts" : "/admin/dashboard") === "active" ? '#92001A' : 'rgba(61, 37, 65, 0.5)'}
                fontWeight="500"
                fontSize={"16px"}
                lineHeight="135%"
              >
                {t('portal_left_menu_1')}
              </Text>
              <Box as="span" display={{ xl: 'none', sm: 'flex' }}>{t('portal_left_menu_1')}</Box>
            </Flex>
          </NavLink>

          <Links />

          <a href="mailto:hello@libi.com" target={'_blank'}>
            <Flex
              gap={{ xl: "14px", sm: 0 }}
              alignItems={"center"}
              justifyContent={{ xl: 'flex-start', sm: 'center' }}
              mb="26px"
              className="sidebar-title"
              cursor={'pointer'}
            >
              <Box color={'rgba(61, 37, 65, 0.5)'}>
                <Headphones />
              </Box>
              <Text
                display={{ xl: 'initial', sm: 'none' }}
                color={'rgba(61, 37, 65, 0.5)'}
                fontWeight="500"
                fontSize={"16px"}
                lineHeight="135%"
              >
                Need help?
              </Text>
              <Box as="span" display={{ xl: 'none', sm: 'flex' }}>Need help?</Box>
            </Flex>
          </a>

          {user.roleId == 2 &&
            <NavLink to="/admin/switch-user">
              <Flex
                gap={{ xl: "14px", sm: 0 }}
                alignItems={"center"}
                justifyContent={{ xl: 'flex-start', sm: 'center' }}
                mb="26px"
                className="sidebar-title"
                cursor={'pointer'}
              >
                <Box color={activeRoute('/admin/switch-user') === "active" ? '#92001A' : 'rgba(61, 37, 65, 0.5)'}>
                  <HiSwitchHorizontal />
                </Box>
                <Text
                  display={{ xl: 'initial', sm: 'none' }}
                  color={activeRoute('/admin/switch-user') === "active" ? '#92001A' : 'rgba(61, 37, 65, 0.5)'}
                  fontWeight="500"
                  fontSize={"16px"}
                  lineHeight="135%"
                >
                  Switch User
                </Text>
                <Box as="span" display={{ xl: 'none', sm: 'flex' }}>Switch User</Box>
              </Flex>
            </NavLink>
          }
          <Flex
            gap={{ xl: "14px", sm: 0 }}
            alignItems={"center"}
            justifyContent={{ xl: 'flex-start', sm: 'center' }}
            mb="26px"
            className="sidebar-title"
            cursor={'pointer'}
            onClick={() => {
              localStorage.clear()
              toast({
                title: 'All cleared succesfully',
                position: 'top-right',
                isClosable: true,
                status: 'success'
              })
            }}
          >
            <Box color={'rgba(61, 37, 65, 0.5)'}>
              <HiOutlineRefresh />
            </Box>
            <Text
              display={{ xl: 'initial', sm: 'none' }}
              color={'rgba(61, 37, 65, 0.5)'}
              fontWeight="500"
              fontSize={"16px"}
              lineHeight="135%"
            >
              Clear cache
            </Text>
            <Box as="span" display={{ xl: 'none', sm: 'flex' }}>Clear cache</Box>
          </Flex>
        </Box>
      </Stack>
    </>
  );
};

export default SidebarContent;
