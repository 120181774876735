import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import s from './HomeHeader.module.scss'
import logo from '../../../../../assets/img/gold-logo.webp'
import classNames from 'classnames'
import {ReactComponent as Arrow} from '../../../../../assets/svg/home-link-arrow.svg'

const Dropdown = ({title, path, list}) => {
  const [isActive, setIsActive] = useState(false)

  return (
    <span
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
      className={s.navDropdownWrapper}
    >
      <a 
        href={path} 
        className={classNames(s.navLink, s.navLinkDropdown, {[s.navLinkActive]: isActive})}
      >
        {title}
        <Arrow className={s.navLinkArrow} />
        <span 
          className={classNames(s.navDropdownPlus, {[s.navDropdownPlusActive]: isActive})} 
          onClick={(ev) => {
            ev.preventDefault()
            setIsActive(prev => !prev)
          }}
        >
          <span />
          <span />
        </span>
      </a>
      <span className={classNames(s.navDropdown, {[s.navDropdownActive]: isActive})}>
        {list.map(i => <a href={i.path} className={s.navDropdownLink}>{i.title}</a>)}
      </span>
    </span>
  )
}

const HomeHeader = () => {
  const [navIsVisible, setNavIsVisible] = useState(false)

  const hideNav = () => {
    setNavIsVisible(false)
    document.querySelector('html').style.overflowY = 'auto'
  }

  const toggleNav = () => {
    if (navIsVisible) {
      hideNav()
    } else {
      setNavIsVisible(true)
      document.querySelector('html').style.overflowY = 'hidden'
    }
  }

  return (
    <header className={s.header}>
      <div className={s.headerContainer}>
        <div className={s.headerWrapper}>
          <Link to="/">
            <img src={logo} alt="Libi&Daughters" className={s.headerLogo} />
          </Link>

          <div 
            className={classNames(s.menuOverlay, {[s.menuOverlayActive]: navIsVisible})}
            onClick={() => hideNav()}
          />

          <div className={classNames(s.menuWrapper, { [s.menuActive]: navIsVisible })}>
            <div 
              className={s.closeIcon}
              onClick={() => hideNav()}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                <path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" d="M5.9394 6.53033L0.469727 1.06066L1.53039 0L7.00006 5.46967L12.4697 0L13.5304 1.06066L8.06072 6.53033L13.5304 12L12.4697 13.0607L7.00006 7.59099L1.53039 13.0607L0.469727 12L5.9394 6.53033Z"></path>
              </svg>
            </div>

            <nav className={s.nav}>
              <a href="https://libi.com/" className={s.navLink}>Home</a>
              <a href="https://libi.com/" className={s.navLink}>Customers</a>
              <Dropdown
                title={'Product'}
                path={''}
                list={[
                  {title: 'Our Lab', path: 'https://libi.com/pages/our-lab'},
                  {title: 'Personalisation', path: 'https://libi.com/pages/personalisation'},
                  {title: 'Subscription', path: 'https://libi.com/pages/subscription'},
                  {title: 'Our Range', path: 'https://libi.com/pages/subscription#our-range'},
                  {title: 'Library', path: 'https://libi.com/blogs/library'},
                ]}
              />
              <a href="https://libi.com/pages/family-story" className={s.navLink}>HERITAGE</a>
              <Dropdown
                title={'Store'}
                path={'https://libi.com/collections'}
                list={[
                  {title: 'Holiday Collections', path: 'https://libi.com/collections/holiday-collection'},
                  {title: 'Quintessential Collections', path: 'https://libi.com/collections/quintessential-collections'},
                  {title: 'Red Box Subscriptions', path: 'https://libi.com/collections/red-box-subscriptions'},
                ]}
              />
            </nav>

            <Link to="/auth/signin" className={s.joinLink} onClick={() => hideNav()}>Enter Libi & Daughters</Link>
          </div>

          <div
            className={s.hamburger}
            onClick={toggleNav}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
              <path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" d="M17 1.5H0V0H17V1.5ZM17 7.5H0V6H17V7.5ZM0 13.5H17V12H0V13.5Z" transform="translate(0.5)"></path>
            </svg>
          </div>
        </div>
      </div>
    </header>
  )
}

export default HomeHeader