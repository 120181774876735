const getFields = (t) => [
    {
      title: t('portal_orders_new_af_questionnaire_passed_title'),
      key: "questionnaire_passed",
      variants: [
        {
          label: t('portal_orders_new_af_questionnaire_passed_variant_1'),
          value: "online",
        },
        {
          label: t('portal_orders_new_af_questionnaire_passed_variant_2'),
          value: "offline",
        },
      ],
    },
    {
      title: t('portal_orders_new_af_sex_title'),
      key: "sex",
      variants: [
        {
          label: t('portal_orders_new_af_sex_variant_1'),
          value: "male",
        },
        {
          label: t('portal_orders_new_af_sex_variant_2'),
          value: "female",
        },
        {
          label: t('portal_orders_new_af_sex_variant_3'),
          value: "other",
        },
      ],
    },
    {
      title: t('portal_orders_new_af_age_range_title'),
      key: "age",
      variants: [
        {
          label: "9 – 15",
          value: "9–15",
        },
        {
          label: "16 – 29",
          value: "16–29",
        },
        {
          label: "30 – 49",
          value: "30–49",
        },
        {
          label: "50+",
          value: "50+",
        },
      ],
    },
    {
      title: t('portal_orders_new_af_skin_type_title'),
      key: "skin_type",
      variants: [
        {
          label: t('portal_orders_new_af_skin_type_variant_1'),
          value: "dry",
        },
        {
          label: t('portal_orders_new_af_skin_type_variant_2'),
          value: "oily",
        },
        {
          label: t('portal_orders_new_af_skin_type_variant_3'),
          value: "normal",
        },
        {
          label: t('portal_orders_new_af_skin_type_variant_4'),
          value: "combined",
        },
      ],
    },
    {
      title: t('portal_orders_new_af_acne_title'),
      key: "acne",
      variants: [
        {
          label: t('portal_orders_new_af_acne_variant_1'),
          value: "severe",
        },
        {
          label: t('portal_orders_new_af_acne_variant_2'),
          value: "moderate",
        },
        {
          label: t('portal_orders_new_af_acne_variant_3'),
          value: "mild",
        },
        {
          label: t('portal_orders_new_af_acne_variant_4'),
          value: "none",
        },
      ],
    },
    {
      title: t('portal_orders_new_af_acne_area_title'),
      key: "acne_problem_area",
      multi: true,
      variants: [
        {
          label: t('portal_orders_new_af_acne_area_variant_1'),
          value: "face",
        },
        {
          label: t('portal_orders_new_af_acne_area_variant_2'),
          value: "chest",
        },
        {
          label: t('portal_orders_new_af_acne_area_variant_3'),
          value: "back",
        },
        {
          label: t('portal_orders_new_af_acne_area_variant_4'),
          value: "other",
        },
      ],
    },
    {
      title: t('portal_orders_new_af_rosacea_title'),
      key: "rosacea",
      variants: [
        {
          label: t('portal_orders_new_af_rosacea_variant_1'),
          value: "severe",
        },
        {
          label: t('portal_orders_new_af_rosacea_variant_2'),
          value: "moderate",
        },
        {
          label: t('portal_orders_new_af_rosacea_variant_3'),
          value: "mild",
        },
        {
          label: t('portal_orders_new_af_rosacea_variant_4'),
          value: "none",
        },
      ],
    },
    {
      title: t('portal_orders_new_af_seborrhea_title'),
      key: "seborrhea",
      variants: [
        {
          label: t('portal_orders_new_af_seborrhea_variant_1'),
          value: "yes",
        },
        {
          label: t('portal_orders_new_af_seborrhea_variant_2'),
          value: "no",
        },
      ],
    },
    {
      title: t('portal_orders_new_af_seborrhea_area_title'),
      key: "seborrhea_problem_area",
      multi: true,
      variants: [
        {
          label: t('portal_orders_new_af_seborrhea_area_variant_1'),
          value: "scalp",
        },
        {
          label: t('portal_orders_new_af_seborrhea_area_variant_2'),
          value: "face",
        },
        {
          label: t('portal_orders_new_af_seborrhea_area_variant_3'),
          value: "chest",
        },
        {
          label: t('portal_orders_new_af_seborrhea_area_variant_4'),
          value: "groin",
        },
      ],
    },
    {
      title: t('portal_orders_new_af_hyperpigmentation_title'),
      key: "hyperpigmentation",
      multi: true,
      variants: [
        {
          label: t('portal_orders_new_af_hyperpigmentation_variant_1'),
          value: "melasma",
        },
        {
          label: t('portal_orders_new_af_hyperpigmentation_variant_2'),
          value: "solar_or_age_spots",
        },
        {
          label: t('portal_orders_new_af_hyperpigmentation_variant_3'),
          value: "post_inflammatory",
        },
        {
          label: t('portal_orders_new_af_hyperpigmentation_variant_4'),
          value: "none",
        },
      ],
    },
    {
      title: t('portal_orders_new_af_hyperpigmentation_area_title'),
      key: "hyperpigmentation_problem_area",
      multi: true,
      variants: [
        {
          label: t('portal_orders_new_af_hyperpigmentation_area_variant_1'),
          value: "face",
        },
        {
          label: t('portal_orders_new_af_hyperpigmentation_area_variant_2'),
          value: "body",
        },
      ],
    },
    {
      title: t('portal_orders_new_af_ageing_title'),
      key: "ageing",
      variants: [
        {
          label: t('portal_orders_new_af_ageing_variant_1'),
          value: "yes",
        },
        {
          label: t('portal_orders_new_af_ageing_variant_2'),
          value: "no",
        },
      ],
    },
    {
      title: t('portal_orders_new_af_dark_circles_title'),
      key: "dark_circles",
      variants: [
        {
          label: t('portal_orders_new_af_dark_circles_variant_1'),
          value: "yes",
        },
        {
          label: t('portal_orders_new_af_dark_circles_variant_2'),
          value: "no",
        },
      ],
    },
    {
      title: t('portal_orders_new_af_allergy_title'),
      key: "allergy",
      variants: [
        {
          label: t('portal_orders_new_af_allergy_variant_1'),
          value: "yes",
        },
        {
          label: t('portal_orders_new_af_allergy_variant_2'),
          value: "no",
        },
      ],
    },
    {
      title: t('portal_orders_new_af_sensitivity_title'),
      key: "sensitivity",
      variants: [
        {
          label: t('portal_orders_new_af_sensitivity_variant_1'),
          value: "yes",
        },
        {
          label: t('portal_orders_new_af_sensitivity_variant_2'),
          value: "no",
        },
      ],
    },
    {
      title: t('portal_orders_new_af_ld_box_title'),
      key: "box_composed_by_ld",
      variants: [
        {
          label: t('portal_orders_new_af_ld_box_variant_1'),
          value: "yes",
        },
        {
          label: t('portal_orders_new_af_ld_box_variant_2'),
          value: "no",
        },
      ],
    },
    {
      title: t('portal_orders_new_af_subscription_days_title'),
      key: "subscription_frequency_recommended",
      variants: [
        {
          label: "30",
          value: "30",
        },
        {
          label: "45",
          value: "45",
        },
        {
          label: "60",
          value: "60",
        },
      ],
    },
  ];

export default getFields;
