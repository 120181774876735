import React from 'react'
import s from '../Home.module.scss'
import img from '../../../../assets/img/doctor.jpeg'
import {ReactComponent as Quote} from '../../../../assets/svg/quote.svg'

const Doctor = () => {
  return (
    <div className={s.doctor}>
      <img src={img} alt="Photo" className={s.doctorImg} />
      <div className={s.doctorWrapper}>
        <p className={s.doctorText}>
          <Quote className={s.quote1} />
          Libi & Daughters recreates the traditional approach of treating skin and combines it with the latest advancements in the beauty industry and technology.
          <Quote className={s.quote2} />
        </p>
        <p className={s.doctorPosition}>Dr. Inna Szalontay, Co-Founder & Clinical Dermatologist</p>
      </div>
    </div>
  )
}

export default Doctor