import {
  Flex,
  Box,
  Center,
  Text,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import { React, useEffect, useState } from "react";
import { db } from "../../../firebase";
import { doc, getDoc } from "firebase/firestore";
import { Link, useHistory } from "react-router-dom";
import { CopyIcon } from "@chakra-ui/icons";
import LibiLogo from "assets/img/libiLogoBlack.png";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import moment from "moment";
import { BsImage } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import centralApi from "centralApi";

function Profile() {
  const { t } = useTranslation();
  const history = useHistory()

  const toast = useToast()

  const { id, originalId } = useSelector(state => state.user)

  const [user, setUser] = useState({})
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [countries, setCountries] = useState([])

  const copyHandler = () => {
    navigator.clipboard.writeText(user?.inviteCode).then(() => {
      toast({
        title: 'Copied successfully',
        position: 'top-right',
        isClosable: true,
        status: 'success'
      })
    })
  }

  const getUser = async (userId) => {
    try {
      setIsLoading(true)

      const { data: userByID } = await centralApi.get('userByID', {params: { userId }})

      if (userByID) {
        if (userByID?.agreementID) {
          const docRef = doc(db, 'docs', userByID.agreementID)
          getDoc(docRef).then(doc => {
            if (doc.exists()) {
              setUser({
                ...userByID,
                pdf: doc.data()?.pdf
              })
            } else {
              setUser(userByID)
            }
          })
        } else {
          setUser(userByID)
        }
      }
    } catch (err) {
      console.log('err', err)
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    centralApi.get('getCountries').then(res => {
      setCountries(res.data)

      if (id !== originalId) {
        getUser(id)
      } else {
        onAuthStateChanged(getAuth(), async (usr) => {
          if (usr) {
            getUser(usr.uid)
          } else {
            history.push("/auth/signup");
          }
        })
      }
    })
  }, [])


  return (
    <Box w={"100%"} h={"100%"} minH="100vh" background={"#FFFFFF"} p="34px">
      <Box maxW={"920px"} margin={"0 auto"}>
        <Center mb="56px">
          <Link to="/admin/dashboard">
            <img src={LibiLogo} width="135" alt="Logo" />
          </Link>
        </Center>
        <Flex alignItems={"center"} justifyContent="space-between">
          <div>
            <h2 className="title">{t("portal_profile_top_title")}</h2>
            <p className="descr">{t("portal_profile_top_description")}</p>
          </div>
          {user?.inviteCode && (
            <Flex
              alignItems={"center"}
              gap="10px"
              cursor={"pointer"}
              color="#92001A"
            >
              <Flex
                h="46px"
                alignItems={"center"}
                className="custom-link products-table-title"
              >
                {t("portal_profile_my_invitation_code_link")?.replace(
                  "[[code]]",
                  user.inviteCode
                )}
                <span>{user.inviteCode}</span>
              </Flex>
              <div onClick={copyHandler}>
                <CopyIcon />
              </div>
            </Flex>
          )}
        </Flex>

        <hr className="hr" style={{ margin: "30px 0" }} />

        {isLoading && (
          <Flex textAlign={"center"}>
            <Spinner color="red.700" size="xl" />
          </Flex>
        )}
        {!isLoading && isError && (
          <p className="error">{t("portal_error_message")}</p>
        )}
        {!isLoading && !isError ? (
          <>
            <Flex
              alignItems={"center"}
              justifyContent="space-between"
              mb="30px"
            >
              <Flex alignItems={"center"} gap="35px">
                <Flex w="72px" h="72px" borderRadius={"50%"} overflow="hidden">
                  {user?.picture ? (
                    <img
                      width={72}
                      height={72}
                      src={user?.picture}
                      alt="Avatar"
                    />
                  ) : (
                    <Flex
                      w="72px"
                      h="72px"
                      alignItems={"center"}
                      justifyContent="center"
                      border={"2px solid #92001A"}
                      borderRadius={"50%"}
                      color="#92001A"
                      fontSize="24px"
                    >
                      <BsImage />
                    </Flex>
                  )}
                </Flex>
                <div>
                  <b>
                    {user?.firstName} {user?.lastName}
                  </b>
                  <Text mt="9px">{user?.email}</Text>
                </div>
              </Flex>
              <Link to="/auth/change-email" className="custom-link">
                {t("portal_profile_change_email_link")}
              </Link>
            </Flex>

            <Flex
              justifyContent={"space-between"}
              flexDirection={{ lg: "row", sm: "column" }}
            >
              <div>
                <Text fontSize={15} color={"#3D2541"} mb={"20px"}>
                  <b>{t("portal_profile_company_title")}</b>&nbsp;{" "}
                  {user?.companyName || "-"}
                </Text>
                <Text fontSize={15} color={"#3D2541"} mb={"20px"}>
                  <b>{t("portal_profile_address_title")}</b>&nbsp;{" "}
                  {user?.address || "-"}
                </Text>
                <Text fontSize={15} color={"#3D2541"} mb={"20px"}>
                  <b>{t("portal_profile_city_title")}</b>&nbsp;
                  {user?.city || "-"}
                </Text>
                <Text fontSize={15} color={"#3D2541"} mb={"20px"}>
                  <b>{t("portal_profile_country_title")}</b>&nbsp;{" "}
                  {countries.find((i) => i.code === user?.country?.code)?.name ||
                    user?.country ||
                    "-"}
                </Text>
                <Text fontSize={15} color={"#3D2541"} mb={"20px"}>
                  <b>{t("portal_profile_postcode_title")}</b>&nbsp;{" "}
                  {user?.postcode || "-"}
                </Text>
                <Text fontSize={15} color={"#3D2541"} mb={"20px"}>
                  <b>{t("portal_profile_iban_title")}</b>&nbsp;{" "}
                  {user?.iban || "-"}
                </Text>
              </div>
              <div style={{ width: "440px" }}>
                <Text fontSize={15} color={"#3D2541"} mb={"20px"}>
                  <b>{t("portal_profile_contact_first_name_title")}</b>&nbsp;{" "}
                  {user?.contactFirstName || "-"}
                </Text>
                <Text fontSize={15} color={"#3D2541"} mb={"20px"}>
                  <b>{t("portal_profile_contact_last_name_title")}</b>&nbsp;{" "}
                  {user?.contactLastName || "-"}
                </Text>
                <Text fontSize={15} color={"#3D2541"} mb={"20px"}>
                  <b>{t("portal_profile_phone_title")}</b>&nbsp;{" "}
                  {user?.contactPhone || "-"}
                </Text>
                <Text fontSize={15} color={"#3D2541"} mb={"20px"}>
                  <b>{t("portal_profile_bank_title")}</b>&nbsp;{" "}
                  {user?.bankAccountNumber || "-"}
                </Text>
                <Text fontSize={15} color={"#3D2541"} mb={"20px"}>
                  <b>{t("portal_profile_sortcode_title")}</b>&nbsp;{" "}
                  {user?.sortcode || "-"}
                </Text>
              </div>
            </Flex>

            <hr className="hr" style={{ margin: "30px 0" }} />

            <Flex
              justifyContent="space-between"
              flexDirection={{ lg: "row", sm: "column" }}
              gap={{ lg: "0", sm: "20px" }}
            >
              <div>
                <h3
                  className="title"
                  style={{
                    fontSize: "26px",
                    color: "#3D2541",
                    marginBottom: "20px",
                  }}
                >
                  {t("portal_profile_af_doc_title")}
                </h3>
                <div className="download-item">
                  {moment().format("DD/MM/YYYY")}
                  <a
                    href="https://firebasestorage.googleapis.com/v0/b/production-lnd.appspot.com/o/SKIN%20ASSESSMENT%20(1).pdf?alt=media&token=acc8e1d0-3c57-49f0-9798-362338bc776c"
                    target="_blank"
                  >
                    <span>Donwload PDF</span>
                  </a>
                </div>
                {/* {afs?.length > 0 ?
                  afs.map(i => (
                    <div
                      key={i.id}
                      className="download-item"
                    >
                      {moment(i?.time?.toDate()).format("DD/MM/YYYY")}
                      <span>Donwload PDF</span>
                    </div>
                  )) :
                  <p className='error' style={{margin: 0}}>Nothing to display</p>
                } */}
              </div>
              {user?.pdf && (
                <div>
                  <h3
                    className="title"
                    style={{
                      fontSize: "26px",
                      color: "#3D2541",
                      marginBottom: "20px",
                    }}
                  >
                    {t("portal_profile_agreement_doc_title")}
                  </h3>
                  <div className="download-item">
                    {user?.created?.toDate
                      ? moment(user?.created?.toDate()).format("DD/MM/YYYY")
                      : "Partnership Agreement"}
                    <a href={user?.pdf}>
                      <span>
                        {t("portal_profile_agreement_doc_download_link")}
                      </span>
                    </a>
                  </div>
                </div>
              )}
            </Flex>

            <Flex
              alignItems={"center"}
              justifyContent={"flex-end"}
              mt="16px"
              gap="32px"
            >
              <Link to="/admin/dashboard" className="custom-link">
                {t("portal_profile_back_link")}
              </Link>
              <Link to="/auth/profile/edit" className="custom-btn">
                {t("portal_profile_edit_button")}
              </Link>
            </Flex>
          </>
        ) : null}
      </Box>
    </Box>
  );
}

export default Profile;
