import React, { useEffect } from "react";
import {
  Flex,
  Text,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { getPayments } from "helpers";
import { CRM_URL } from "../../firebase";

const Success = () => {
  const { t } = useTranslation();
  const user = useSelector(state => state.user)

  const sendEmail = async () => {
    try {
      // const results = await axios.get(`${CRM_URL}/s/crmadd/31000024068/${user.id}`)
      console.log("Payment successfull email api results", results);
    } catch (err) {
      console.log("Payment successfull email api results", err);
    }
    
  }

  useEffect(() => {
    // sendEmail()
  }, [])

  useEffect(() => {
    if(user.id) {
      getPayments(user.id)
    }
  }, [user.id])

  return (
    <Flex
      h="50vh"
      direction="column"
      alignItems={"center"}
      justifyContent={'center'}
    >
      <h2 className="title">{t('portal_payment_completed_top_title')}</h2>
      <Text 
        style={{
          fontSize: '15px',
          fontWeight: '500',
          color: '#3D2541',
        }}
        my="6px"
      >{t('portal_payment_completed_top_description')}</Text>
      <Link
        to="/admin/payments"
        className="custom-btn" 
      >
        {t('portal_payment_completed_link_title')}
      </Link>
    </Flex>
  );
};

export default Success;
